import React, { useState, useEffect } from 'react';
import { TextField, Slider } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import "./SliderInput.css";
import RowInput from '../RowInput';
import { ContactSupportOutlined } from '@material-ui/icons';

const SliderInput=({
    label,  
    value,
    minVal = 100000,
    maxVal = 450000,
    valueDisplay= "off",
    onTextChange = function(){return false},
    onSliderChange = function(){return false},
    id,
    showTextInput = true,
    error=false,
    helperText,
    classes="",
    numberFormat = false,
    disabled=false,
    marks=false,
    sliderChange
})=>{

    const [valor, setValor] = useState(value);
    // const [jump, setJump] = useState(100 - (parseFloat(value) % 100))

    // useEffect(() => {
    //     const resto = (value % 100);
    //     console.log(resto)

    //     if((value % 100) === 0) {
    //         setValor(10);
    //         return;
    //     } else {
    //         const newValue = value + (100 - (value % 100));
    //         setValor(newValue);
    //     }
    // }, [value])
/**
    useEffect(() => {

        if(parseFloat(value) === parseFloat(minVal)) {
            setValor(value)
        }

        if(parseFloat(value) !== parseFloat(minVal)) {
            console.log("minimo");
            console.log(value);
            const resto = value % 100;
            console.log(resto);
            if(resto !== 0) {
                console.log("Controlando Salto")
                const salto = 100 - parseFloat(resto);
                console.log(salto)
                setJump(salto)
                const newValue = parseFloat(value) + parseFloat(salto);
                console.log(newValue);
                setValor(newValue);
            } else {
                console.log("Saltos de 100")
                console.log(value + 100)
                setJump(100)
                setValor(parseFloat(value) + 100)
            }
        }

    }, [value])

 */

    useEffect(() => {
        if(!sliderChange) return;

        if(parseFloat(value) === parseFloat(minVal)) {
            setValor(value);
            return;
        }

        if(parseFloat(value) !== parseFloat(minVal)) {
            const resto = value % 100;
            if(resto !== 0) {
                const newValue = parseFloat(value) - resto;
                setValor(newValue);
            }
        }

    }, [value])

    return (
        <>
            <div className={showTextInput ? 'rowGroup': 'rowInput'}>
                <RowInput
                    label={label}
                    content={
                            showTextInput ? 
                            <div className='rowArea'>
                                <div className='rowGroup sliderText-width'>
                                    <NumberFormat
                                        name={id} 
                                        id={id}
                                        // value={value}
                                        // value={valor}
                                        value={sliderChange ? valor : value}
                                        // value={parseFloat(value) === parseFloat(minVal) ? value : value - (value % 100)}
                                        customInput={TextField}
                                        className={"cotizador-input input-money"}
                                        onChange={onTextChange}
                                        thousandSeparator={true}
                                        prefix={'$ '}
                                        disabled={disabled}
                                        // error={error} helperText={helperText}
                                    />
                                </div>
                                <div className='rowGroup sliderBar-width'>
                                    <Slider
                                        value={value < minVal ? Number(minVal) : Number(value)}
                                        defaultValue={value < minVal ? Number(minVal) : Number(value)}
                                        onChange={onSliderChange}
                                        aria-label={id}
                                        valueLabelDisplay={valueDisplay}
                                        // step={(value % 100) !== 0 ? 100 - (value % 100) : 100}
                                        // step={jump}
                                        step={100}
                                        min={minVal}
                                        max={maxVal}
                                        className={"sliderInputForm"}
                                        marks={marks}
                                        // marks={
                                        //   [
                                        //     {
                                        //       value: 12,
                                        //       label: "12",
                                        //     },
                                        //     {
                                        //       value: 96,
                                        //       label: "96",
                                        //     },
                                        //   ]
                                        // }
                                    />
                                </div>
                            </div>
                        :
                        <Slider
                            value={value < minVal ? minVal : value}
                            defaultValue={value}
                            onChange={onSliderChange}
                            aria-label={id}
                            valueLabelDisplay="on"
                            step={1}
                            min={minVal}
                            max={maxVal}
                            className={"sliderInputForm"}
                            // marks={
                            //   [
                            //     {
                            //       value: 12,
                            //       label: "12",
                            //     },
                            //     {
                            //       value: 96,
                            //       label: "96",
                            //     },
                            //   ]
                            // }
                        />
                    }
                />
                {
                    error ? 
                    <div className='slider-format-errors'>
                    <p>{helperText}</p>
                    </div>
                    :
                    <></>
                }
            </div>
        </>
    )
}

export default SliderInput