import React, { useState, useEffect} from 'react';
import { SliderData } from './SliderData';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import './Slider.css'

const Slider = ({ slides }) => {

  const [current, setCurrent] = useState(0);
  const length = slides.length;

  useEffect(() => {

    let sindex = 0;

    const startSlide = () => {
      let i;
      let myVar = 0;
      let slides = document.getElementsByClassName("slide");
      for (i = 0; i < slides.length; i++) {
        if(slides){
          slides[i].classList.remove("active");
        }
      }
      sindex++;
      if (sindex > slides.length) {sindex = 1}    
      if(slides[sindex-1]){
        slides[sindex-1].classList.add("active");
          myVar = setTimeout(startSlide, 6000); //
      }else{
        clearTimeout(myVar);
      }
    }

    startSlide();
    }, [])

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }


  return (
    <section className='slider'>
      <FaArrowLeft className='left-arrow' onClick={prevSlide} />
      <FaArrowRight className='right-arrow' onClick={nextSlide} />
      {SliderData.map((slide, index) => {
        if(!slide.vp){ slide.vp = "isp_bottom"}
        if(!slide.hp){ slide.hp = "isp_right"}
        if(!slide.df){ slide.hp = "isf_s"}
        var divStyle = {
          backgroundImage: 'url(images/home/hero/' + slide.up_image + ')'
        };
        return (
          <div
            className={index === current ? 'slide active' : 'slide'}
            key={index} style={{backgroundImage: `url(${slide.image})`}}
          >
              <div className={'is '+ slide.vp + ' ' + slide.hp + ' ' + slide.df} style={divStyle}>
                {
                  slide.button ? 
                    <a target="_blank" rel="noopener noreferrer" href={slide.button.href} className={slide.button.horizontalPosition+" "+slide.button.bottom}>
                      <button className={"btn btn--primary btn--wide "+slide.button.color}>{slide.button.label}</button>
                    </a>
                    :
                    <></>
                }
              </div>
          </div>
        );
      })}
    </section>
  );
};

export default Slider;