import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {FaAngleLeft} from 'react-icons/fa'
import axios from 'axios';
import {bcAlert} from '../../boclAlert';
import FormDatosFiscales from './FormDatosFiscales/FormDatosFiscales';
import DatosFiscalesActuales from './DatosFiscalesActuales/DatosFiscalesActuales';
// import Telefono from './Telefono/Telefono';
// import './Contacto.css';
import ReactGA from 'react-ga';

export default function DatosFiscales({
    contrato_metacid
}) {
    /*--Declaration of INPUTS VARIABLES
    ---------correo (string)
    ---------password (string)
    ---------user (object)
    -------Brandon Cantu
    ------------*/
    const [datosFiscalesUsuario, setDatosFiscalesUsuario] = useState({
        calle: "",
        cfdi_descripcion: "",
        cfdi_id: "",
        ciudad: "",
        codigo_postal: "",
        colonia: "",
        estado: "",
        nombre_cliente: "",
        numero_exterior: "",
        regimen_fiscal_descripcion: "",
        regimen_fiscal_id: "",
        rfc: ""
    });

    const [datosFiscalesUsuarioActuales, setDatosFiscalesUsuarioActuales] = useState({
        calle: "",
        cfdi_descripcion: "",
        cfdi_id: "",
        ciudad: "",
        codigo_postal: "",
        colonia: "",
        estado: "",
        nombre_cliente: "",
        numero_exterior: "",
        regimen_fiscal_descripcion: "",
        regimen_fiscal_id: "",
        rfc: ""
    });

    const {nombre_cliente="", rfc="", calle="", colonia="", numero_exterior="", codigo_postal="", ciudad="", estado="", regimen_fiscal_id="", cfdi_id="", constancia_fiscal=""} = datosFiscalesUsuario;

    const [datosRegimenFiscal, setRegimenFiscal] = useState([]);

    const [datosCFDI, setCFDI] = useState([]);
    

    //POST DATOS FISCALES USUARIO
    useEffect(() => {
        getDatosFiscalesUsuario(contrato_metacid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_metacid])

    //POST DATOS REGIMEN FISCAL
    useEffect(() => {
        let cleanUp = true;

        const getDatosRegimenFiscales = () => {
            if(contrato_metacid !== 0 && contrato_metacid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_metacid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/cargar_datos_regimen_fiscal",{"json":json}, {
                    headers: {
                    'Authorization' : token
                    }
                })
                .then(response => {
                    if(cleanUp){
                        // console.log(response);return;
                        let status = response.data.status;
                        let data = response.data;
                        // setDatosFiscalesUsuario(data.datos_usuario);

                        // return(console.log(data));
                        
                        if(status === "success" ){
        
                            let datos_regimen_fiscal = data.datos_regimen_fiscal;

                            if(datos_regimen_fiscal.length > 0){
                                let newData = datosRegimenFiscal;
                                newData = datos_regimen_fiscal;
                                setRegimenFiscal(newData);
                            }else{
                                bcAlert({
                                    msg : {
                                        "title":"Opps!", 
                                        "desc":"No se encontraron datos"
                                    },
                                    type :"error", 
                                    styles : ``, 
                                    options : false
                                });
                            }
                        }else{
                            bcAlert({
                                msg : {
                                    "title":"Opps!", 
                                    "desc":"No hay datos disponibles"
                                },
                                type :"error", 
                                styles : ``, 
                                options : false
                            });
                        }
                    }
                })
                .catch(function (error) {
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }

        getDatosRegimenFiscales(contrato_metacid);

        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_metacid])

    //POST DATOS CFDI
    useEffect(() => {
        let cleanUp = true;

        const getDatosRegimenFiscales = () => {
            if(contrato_metacid !== 0 && contrato_metacid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_metacid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/cargar_datos_cfdi",{"json":json}, {
                    headers: {
                    'Authorization' : token
                    }
                })
                .then(response => {
                    if(cleanUp){
                        // console.log(response);return;
                        let status = response.data.status;
                        let data = response.data;
                        // setDatosFiscalesUsuario(data.datos_usuario);

                        // return(console.log(data));
                        
                        if(status === "success" ){
        
                            let datos_cfdi = data.datos_cfdi;

                            if(datos_cfdi.length > 0){
                                let newData = datosCFDI;
                                newData = datos_cfdi;
                                setCFDI(newData);
                            }else{
                                bcAlert({
                                    msg : {
                                        "title":"Opps!", 
                                        "desc":"No se encontraron datos"
                                    },
                                    type :"error", 
                                    styles : ``, 
                                    options : false
                                });
                            }
                        }else{
                            bcAlert({
                                msg : {
                                    "title":"Opps!", 
                                    "desc":"No hay datos disponibles"
                                },
                                type :"error", 
                                styles : ``, 
                                options : false
                            });
                        }
                    }
                })
                .catch(function (error) {
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }

        getDatosRegimenFiscales(contrato_metacid);

        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_metacid])

    const onInputChange = e => {
        if (e.target.id == "calle" || e.target.id == "numero_exterior") {
            e.preventDefault()
            e.target.value = e.target.value.replaceAll(/[.*+\-',!¡|°"#%&/=¨´;:_<>¿??^${}()|[\]\\°ÑñáÁéÉíÍóÓúÚ]/g, "")
        }else if (e.target.id == "colonia" || e.target.id == "ciudad" || e.target.id == "estado") {
            e.preventDefault()
            e.target.value = e.target.value.replaceAll(/[.*+\-',!¡|°"#%&/=¨´;:_<>¿??^${}()|[\]\\°ÑñáÁéÉíÍóÓúÚ0123456789]/g, "")
        }else if (e.target.id == "codigo_postal") {
            e.preventDefault()
            e.target.value = e.target.value.replaceAll(/[.*+\-',!¡|°"#%&/=¨´;:_<>¿??^${}()|[\]\\°QqWwEeRrTtYyUuIiOoPpAaSsDdFfGgHhJjKkLlZzXxCcVvBbNnMmÑñáÁéÉíÍóÓúÚ]/g, "")
        }

        setDatosFiscalesUsuario({ ...datosFiscalesUsuario, [e.target.id]: e.target.value });
    };

    const updateDatos = (e) =>{
        e.preventDefault();
        let bandera_actualizar_insertar = 1,
        tipo_dispositivo = "web";
        if(nombre_cliente === ''){
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El Nombre o Razón Social no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(rfc === ''){
            // alert('Debes ingresar un email')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El RFC no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(codigo_postal === ''){
            // alert('Debes ingresar un asunto')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El Código postal no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(colonia === ''){
            // alert('Debes ingresar un departamento')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"La Colonia no debe estar vacía"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(ciudad === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El Municipio no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(calle === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El calle no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(numero_exterior === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El num_ext no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(estado === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El estado no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(regimen_fiscal_id === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El regimen_fiscal no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(cfdi_id === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El cfdi no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(contrato_metacid === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El contrato metacid no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        if (!/[0-9]/.test(numero_exterior)) {
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El numero exterior/interior debe contener por lo menos un número"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }

        ReactGA.event({
            category: 'Boton Portal',
            action: 'Datos Fiscales'
        });
        //DESABILITAMOS EL BOTON
        let btn = document.getElementById("actualizarDatos");
        btn.disabled = true;
        btn.classList.add("btn-blue-disabled");
        //SET LOADER
        //disapper content
        let ogInner = btn.innerHTML;
        let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
        btn.innerHTML = loaderHtml;
        let json = JSON.stringify({
            "nombre_razon_social":nombre_cliente, 
            "rfc":rfc, 
            "contrato_meta_cid":contrato_metacid, 
            "tipo_dispositivo":tipo_dispositivo, 
            "bandera_actualizar_insertar":bandera_actualizar_insertar,
        });
        let myForm = document.getElementById('formularo_prueba');
        let formData = new FormData(myForm);
        formData.append("json", json);
        let data = formData;  
        // return(console.log(formData));
        const token = localStorage.getItem('token');
        axios.post(process.env.REACT_APP_API_DM_URL+"/api/guardar_datos_fiscales",data, {
            headers: {
            'Authorization' : token,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
            }
        })
        .then(response => {
            let result = response.data;
            // return console.log(result);
            if (result.status === 'success') {
                if (result.code === 200) {
                    bcAlert({
                        msg : {
                            "title":"Tu información se actualizó correctamente!",
                            "desc":""
                        },
                        type :"success", 
                        styles : ``, 
                        options : false
                    });

                    getDatosFiscalesUsuario(contrato_metacid);
    
                }else if (result.code === 205) {
                    bcAlert({
                        msg : {
                            "title":result.message,
                            "desc":""
                        },
                        type :"success", 
                        styles : ``, 
                        options : false
                    });
                }

                // setDatosFiscalesUsuario({calle: "", cfdi_descripcion: "", cfdi_id: "", ciudad: "", codigo_postal: "", colonia: "",
                // estado: "", nombre_cliente: "", numero_exterior: "", regimen_fiscal_descripcion: "", regimen_fiscal_id: "", rfc: ""});

                btn.disabled = false;
                btn.classList.remove("btn-blue-disabled")
                btn.innerHTML = ogInner;
        
            }else if(result.status === 'error_validate'){
                let strMsg = "";
                for(let i=0; i<result.message.length; i++){
                    strMsg= strMsg+" "+result.message[i];
                }
                bcAlert({
                    msg : {
                        "title":"Opps!", 
                        "desc":strMsg
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
                btn.innerHTML = ogInner;
            }else{
                bcAlert({
                    msg : {
                        "title":"Opps!", 
                        "desc":"Hubo un problema al guardar su información"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
                btn.innerHTML = ogInner;
            }
        })
        .catch(function (error) {

            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"Hubo un problema al actualizar su información"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            btn.innerHTML = ogInner;

            if (error.response) {
            // Request made and server responded
            console.log("Error-Response: "+error.response.data);
            console.log("Error-Status: "+error.response.status);
            console.log("Error-Headers: "+error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            console.log('Error-Request: '+error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error-Message: '+ error.message);
            }
        });
    }

    const getDatosFiscalesUsuario = (contrato_metacid) => {
        if(contrato_metacid !== 0 && contrato_metacid !== "0"){
            const token = localStorage.getItem('token');
            let json = JSON.stringify({"contrato_meta_cid":contrato_metacid});
            axios.post(process.env.REACT_APP_API_DM_URL+"/api/datos_fiscales_usuario",{"json":json}, {
                headers: {
                'Authorization' : token
                }
            })
            .then(response => {
                // console.log(response);return;
                let status = response.data.status;
                let data = response.data;
                // setDatosFiscalesUsuario(data.datos_usuario);

                // return(console.log(data))
                
                if(status === "success" ){

                    let datos_usuario = data.datos_usuario;
                    setDatosFiscalesUsuario(datos_usuario);
                    setDatosFiscalesUsuarioActuales(datos_usuario);
                }else{
                    bcAlert({
                        msg : {
                            "title":"Opps!", 
                            "desc":"No hay datos disponibles"
                        },
                        type :"error", 
                        styles : ``, 
                        options : false
                    });
                }
            })
            .catch(function (error) {

                if (error.response) {
                // Request made and server responded
                console.log("Error-Response: "+error.response.data);
                console.log("Error-Status: "+error.response.status);
                console.log("Error-Headers: "+error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                console.log('Error-Request: '+error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error-Message: '+ error.message);
                }
            });
        }
    }

    return (
        <React.Fragment>
            <div className={'option-section'} style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                <div className="option-container">
                    <div className="option-title">
                        <Link to="/portal" className="opt-goBack" >
                            <FaAngleLeft className="go-back-icon"/>
                        </Link>
                        <h1>Datos Fiscales</h1>
                    </div>
                    <div className="option-content contact-content">
                        <DatosFiscalesActuales
                            datos={datosFiscalesUsuarioActuales}
                        /> 
                        <FormDatosFiscales
                            datos={datosFiscalesUsuario}
                            datos_regimen_fiscal={datosRegimenFiscal}
                            datos_cfdi={datosCFDI}
                            onInputChange = {(e) => onInputChange(e)}
                            updateDatos={updateDatos}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}