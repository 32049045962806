import React from 'react';
import './AvisoCard.css';

const AvisoCard=(props)=>{

    return (
        <div className={`album-item av-${props.color}`}>
            <span className="album-details">
                <span className="title">{props.title}</span>
                <span className="subtext" style={{whiteSpace: 'pre-wrap'}}>{props.desc}</span>
                {
                    props.nota ?
                        <span className="subtext"><strong>Nota:</strong>{props.nota}</span>
                    :
                    <></>
                }
                
            </span>
        </div>
    )
}

export default AvisoCard