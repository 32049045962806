import React from 'react';
import ConfCard from '../../Sidebar/Opciones/Cuenta/ConfCard.js/ConfCard';
// import axios from 'axios';
import {FiPhone} from 'react-icons/fi'
import './Telefono.css';
import ReactGA from 'react-ga';

const Telefono=(props)=>{

    function Celulares(telefono){
        window.location.href ="tel:"+telefono;
        //ENVIAMOS TRACKING INFO
        ReactGA.event({
            category: 'Boton Portal',
            action: 'Telefono Unidad Servicio'
        });
    }

    return (
        <ConfCard 
            titulo={"LLámanos"}
            icon = {<FiPhone className="opcion-conf-icon"/>} 
            content={
                <>
                    <div className="section-content tel__" id="menu_telefonos">
                    {
                        props.unidades_servicio ?
                        props.unidades_servicio.map((unidad, i) => {     
                                // Return the element. Also pass key    
                                return (
                                    <div className="tel_unidad" key={i}>
                                        {
                                            
                                            unidad.status_horario === "Abierto" ?
                                            <div className="tel__cont tel__abierto">
                                                <h2 id="">{unidad.servicio_nombre}</h2>
                                                <p>{unidad.status_horario}</p>
                                            </div>
                                            :
                                                unidad.status_horario === "Cerrado" ?
                                                <div className="tel__cont tel__cerrado">
                                                    <h2 id="">{unidad.servicio_nombre}</h2>
                                                    <p>{unidad.status_horario} <span>{unidad.status_horario_descripcion}</span></p>
                                                </div>
                                                :
                                                <div className="tel__cont tel__por">
                                                    <h2 id="">{unidad.servicio_nombre}</h2>
                                                    <p>{unidad.status_horario}</p>
                                                </div>
                                        }
                                        <div className="tel__icon" id="tel_datasystems" onClick={() => {Celulares(props.telefono)}}>
                                            <img alt="tel_svg" className="dm-icon tel__tel-icon" src="https://dmgo.arrendadoradatamovil.com:2000/storage/dm-icons/dm-phone.svg"/>
                                        </div>
                                    </div>
                                ) 
                        })
                        :
                        <div className="tel_unidad">
                            <div className="tel__cont tel__abierto">
                                <h2 id="">No se encontraron datos</h2>
                                <p></p>
                            </div>
                            <div className="tel__icon" id="tel_datasystems">
                            </div>
                        </div>
                    }
                    </div>
                </>
            }
        />
    )
}

export default Telefono