import React from 'react';
// import axios from 'axios';
// import './Mail.css';
import {GoBook} from 'react-icons/go'
import ConfCard from '../../Sidebar/Opciones/Cuenta/ConfCard.js/ConfCard';

const FormDatosFiscales=(props)=>{

    return (
        <ConfCard 
                titulo={"¿Quieres modificar tus datos fiscales?"}
                icon = {<GoBook className="opcion-conf-icon"/>} 
                content={
                    <>
                    <form action="" method="post" id="formularo_prueba">
                        <div className="groupCuenta_prueba">
                            <label>Nombre:</label>
                            <div className="form-group">
                                <label id="nombre_cliente" name="nombre_cliente">{props.datos.nombre_cliente}</label>
                            </div>
                        </div>
                        <div className="groupCuenta_prueba">
                            <label>RFC:</label>
                            <div className="form-group">
                            <label id="rfc" name="rfc">{props.datos.rfc}</label>
                            </div>
                        </div>
                        <div className="groupCuenta_prueba">
                            <label>Calle</label>
                            <div className="form-group">
                                <input type="text" id="calle" name="calle" maxLength={100} placeholder={"Calle"} onChange={props.onInputChange} value={props.datos.calle} required></input>
                            </div>
                        </div>
                        <div className="groupCuenta_prueba">
                            <label>Colonia</label>
                            <div className="form-group">
                                <input type="text" id="colonia" name="colonia" maxLength={50} placeholder={"Colonia"} onChange={props.onInputChange} value={props.datos.colonia} required></input>
                            </div>
                        </div>
                        <div className="groupCuenta_prueba">
                            <label>Número Interior/Exterior</label>
                            <div className="form-group">
                                <input id="numero_exterior" name="num_ext" type="text" maxLength={50} placeholder={"Número"} onChange={props.onInputChange} value={props.datos.numero_exterior}  required></input>
                            </div>
                        </div>
                        <div className="groupCuenta_prueba">
                            <label>C.P:</label>
                            <div className="form-group">
                                <input id="codigo_postal" name="domicilio_fiscal" type="text" maxLength={5} placeholder={"Código Postal (Domicilio Fiscal)"} onChange={props.onInputChange} value={props.datos.codigo_postal} required></input>
                            </div>
                        </div>
                        <div className="groupCuenta_prueba">
                            <label>Municipio</label>
                            <div className="form-group">
                                <input id="ciudad" name="municipio" type="text" maxLength={50} placeholder={"Municipio"} onChange={props.onInputChange} value={props.datos.ciudad} required></input>
                            </div>
                        </div>
                        <div className="groupCuenta_prueba">
                            <label>Estado</label>
                            <div className="form-group">
                                <input id="estado" name="estado" type="text" maxLength={50} placeholder={"Estado"} onChange={props.onInputChange} value={props.datos.estado} required></input>
                            </div>
                        </div>
                        <div className="groupCuenta_prueba">
                            <label>Régimen Fiscal</label>
                            <div className="selectContacto">
                                <div className="form-group">
                                    {/* <label>Día</label> */}
                                    <div className="selectMail">
                                        <select id="regimen_fiscal_id" name="regimen_fiscal" onChange={props.onInputChange} required>
                                            <option value="" style={{color: "gray"}}>Selecciona una Opción...</option>
                                            {props.datos_regimen_fiscal.map((drf, i) => {
                                                return (
                                                    drf.descripcion === props.datos.regimen_fiscal_descripcion ? 
                                                    <option key={drf.id} value={drf.id} selected>
                                                        {drf.descripcion}
                                                    </option>
                                                    :
                                                    <option key={drf.id} value={drf.id}>
                                                        {drf.descripcion}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="groupCuenta_prueba">
                            <label>Uso CFDI</label>
                            <div className="selectContacto">
                                <div className="form-group">
                                    {/* <label>Día</label> */}
                                    <div className="selectMail">
                                        <select id="cfdi_id" name="cfdi" onChange={props.onInputChange} required>
                                            <option value="" style={{color: "gray"}}>Selecciona una Opción...</option>
                                            {props.datos_cfdi.map((dcfd) => {
                                                return (
                                                    dcfd.descripcion === props.datos.cfdi_descripcion ?
                                                    <option key={dcfd.id} value={dcfd.id} selected>
                                                        {dcfd.descripcion}
                                                    </option>
                                                    :
                                                    <option key={dcfd.id} value={dcfd.id}>
                                                        {dcfd.descripcion}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="groupCuenta_prueba">
                            <label>Constancia Fiscal</label>
                            <div className="form-group">
                                <input id="constancia_fiscal" name="constancia_fiscal" type="file" accept="application/pdf" placeholder={"Constancia Fiscal"} onChange={props.onInputChange} required></input>
                            </div>
                        </div>
                        <button className="btn-blue" id="actualizarDatos" onClick={(e) => {props.updateDatos(e)}}>
                            ACTUALIZAR
                        </button>
                    </form>
                    </>
                }
        />
    )
}

export default FormDatosFiscales