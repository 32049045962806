import React, { useState } from 'react';
// import axios from 'axios';
import './ConfCard.css';

const ConfCard=(props)=>{

    const [isOpen, setIsOpen] = useState(false);

    const style = {
        overflow: "hidden",
        padding: isOpen ? "0.3rem 0.5rem 1.5rem 0.5rem" : 0,
        height: isOpen ? "auto" : 0,
        transition: "all .25s ease"
    };
    return (
        <div className="button-opciones-cuenta" id="option-nombre">
            <div className="opciones-cuenta-header" onClick={() => setIsOpen(prev => !prev)}>
                <div className="opciones-cuenta-img">
                    <div className="opciones-cuenta-circle">
                        {/* <h1>undefined</h1> */}
                        {props.icon}
                    </div>  
                </div>
                <p className="opciones-cuenta-links">
                    {props.titulo}
                </p>
            </div>
            <div className="opciones-cuenta-content" style={style}>
                {props.content}
            </div>
        </div>
    )
}

export default ConfCard