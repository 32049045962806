import React, { useState, useEffect } from 'react';
import InstruccionEfectivo from '../InstruccionesPago/InstruccionEfectivo';
import InstruccionTransfer from '../InstruccionesPago/InstruccionTransfer';
import './SelectFormaPago.css';
import {instruccionAhorro, instruccionEfectivo, instruccionBanorteWeb, instruccionSpei} from '../DataFormasPago'

export default function SelectFormaPago({
    listaLugares,  
    fLugarActual,
    changeFormaPago,
    tipoActual,
    contrato_metacid,
    datosPago
}) 
    {

    const [instrucciones, setInstrucciones] = useState([]);
    const [tipo, setTipo] = useState("");

    useEffect(() => {
        let newInstructions = instrucciones;
        if(tipoActual === 1 || tipoActual === "1"){//EFECVTIVO
            if(fLugarActual === "ahorro"){
                newInstructions = instruccionAhorro;
            }else{
                newInstructions = instruccionEfectivo;
            }
        }else
        if(tipoActual === 2 || tipoActual === "2"){//TRANSFERENCIA
            if(fLugarActual === "spei"){
                newInstructions = instruccionSpei;
            }else if(fLugarActual === "banorte_web"){
                newInstructions = instruccionBanorteWeb;
            }
        }
        setInstrucciones(newInstructions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fLugarActual])

    useEffect(() => {
        let newTipo = tipo 
        newTipo = tipoActual;
        setTipo(newTipo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoActual])

    return (
        <React.Fragment>
            <div className="fpago_options">
                <div className="selectFLugar__">
                    <label className="selectFLugar__label">Selecciona una opción:</label>
                    <div className="selectFLugar__combo" key={fLugarActual}>
                        <select name="select_lugarPago" id="select_lugarPago" onChange={e => changeFormaPago(e)} defaultValue={fLugarActual}>
                            {
                                listaLugares.lugares.map((answer, i) => {     
                                //Return the element. Also pass key 
                                return (<option id={"fpago_"+answer.code} data-val={answer.id} value={answer.code} key={answer.id}>{answer.nombre}</option>) 
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            {
                tipo === "1" ?
                    <InstruccionEfectivo
                        key={tipo} 
                        lugarPago={fLugarActual}
                        instrucciones={instrucciones}
                        contrato_metacid={contrato_metacid}
                        datosPago = {datosPago}
                    />
                :
                    <InstruccionTransfer
                        key={tipo} 
                        lugarPago={fLugarActual}
                        instrucciones={instrucciones}
                        datosPago = {datosPago}
                    />
            }
            
        </React.Fragment>
    )
}