export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Datamovil Go',
    headline: "Descarga nuestra app",
    description: '',
    imgStart: '',
    bgImg: "images/mobile-app/bg_app1.jpg",
    img: 'images/mobile-app/img_app1.png',
    imgAnimation: "transitionIn_bounceRightLeft",
    alt: 'app1',
    moreButtons: [
        {label:"Android", href:'https://play.google.com/store/apps/details?id=com.datamovil.datamovil&hl=es_MX&gl=US', link:'', id:"btnHeroAndroid"},
        {label:"iOS", href:"https://apps.apple.com/mx/app/datamovil-go/id1441590066", link:'', id:"btnHeroIos"},
        {label:"Huawei", href:"https://appgallery.huawei.com/#/app/C103010887", link:'', id:"btnHeroHuawei"},
    ],
    buttonsJustify: "left"
}

export const card = {
    home: {
        titulo: '',
        subtitulo: '',
        parrafo: 'Con Datamovil Go, administra tus contratos y consulta tu información',
        img: 'images/mobile-app/dmgo1.png',
        active: true
    },
    sideBar: {
        titulo: '',
        subtitulo: '',
        parrafo: 'Podrás agregar y acceder a tus contratos desde un mismo lugar',
        img: 'images/mobile-app/dmgo2.png',
        active: true
    },
    contrato: {
        titulo: '',
        subtitulo: '',
        parrafo: 'Consulta claves, depósitos, seguros y estado de cuenta',
        img: 'images/mobile-app/dmgo3.png',
        active: true
    },
    depositos: {
        titulo: '',
        subtitulo: '',
        parrafo: 'Verifica tus depósitos y facturas',
        img: 'images/mobile-app/dmgo4.png',
        active: true
    },
    estadoCuenta: {
        titulo: '',
        subtitulo: '',
        parrafo: 'Todo lo que necesitas desde un solo lugar, Datamovil GO!',
        img: 'images/mobile-app/dmgo5.png',
        active: true
    },
}