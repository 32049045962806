import React from 'react'
import {Button} from '../Button'
import {Link} from 'react-router-dom';
import './HeroSection.css';

export default function HeroSection({
    id,
    lightBg,
    topLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    buttonId = "",
    img = false,
    imgObject = false,
    alt,
    btnLink="/login",
    imgStart,
    list = false,
    bgImg = false,
    moreButtons = [],
    textFloat = "auto",
    buttonColor = "blue",
    bgSize = "cover",
    divHeight = "",
    bgPosition = "center center",
    imgSize = "100%",
    imgAnimation = "",
    buttonsJustify= "center"
}) {

    let textFloatCss = "";
    if(textFloat === "center"){
        textFloatCss="textCenter"
    }else if(textFloat === "right"){
        textFloatCss="textRight"
    }else{
        textFloatCss="textAuto"
    }

    return (
        <React.Fragment>
            <div id={id} className={lightBg ? 'home__hero-section '+textFloatCss : 'home__hero-section darkBg '+textFloatCss} style={{backgroundImage: (bgImg  ? 'url('+bgImg+')': ''), backgroundSize: bgSize, backgroundRepeat: 'no-repeat', backgroundPosition: bgPosition, height: divHeight}}>
                <div className="home__hero-container">
                    <div className="row home__hero-row"
                    style={{display: 'flex', flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'}}>
                        <div className="col">
                            <div className="home__Hero-text-wraper">
                                <div className="top-line">{topLine}</div>
                                <h1 className={lightText ? 'heading' : 'heading dark'}>{headline}</h1>
                                {list ?
                                    (
                                        <ul className={textFloatCss}>
                                            {description.map((answer, i) => {     
                                                // Return the element. Also pass key     
                                                return (<li className={lightTextDesc ? 'home__hero-list' : 'home__hero-list dark'} key={i}>{answer}</li>) 
                                            })}
                                        </ul>
                                    )
                                    :
                                    (
                                        <p className={lightTextDesc ? 'home__hero-paragraph' : 'home__hero-paragraph dark'}>{description}</p>
                                    )
                                }
                                {buttonLabel ?
                                    (<Link to={btnLink}>
                                        <Button buttonSize='btn--wide' buttonColor={buttonColor} id={buttonId}>
                                            {buttonLabel}
                                        </Button>
                                    </Link>)
                                    :
                                    ''
                                }
                                {moreButtons.length > 0 ?
                                    <div className="heroMoreButtons" style={{justifyContent:buttonsJustify}}>
                                    {moreButtons.map((button, i) => {     
                                        // Return the element. Also pass key 
                                        return (
                                            button.link ? 
                                            <Link to={button.link} key={i}>
                                                <Button buttonSize='btn--wide' buttonColor={buttonColor} id={button.id ? button.id : ""}>
                                                    {button.label}
                                                </Button>
                                            </Link>
                                            :
                                                button.href ?    
                                                <a target="_blank" rel="noopener noreferrer" href={button.href} key={i} id={button.id ? button.id : ""}>
                                                    <Button buttonSize='btn--wide' buttonColor={buttonColor} >
                                                        {button.label}
                                                    </Button>
                                                </a>
                                                :
                                                    button.id ? 
                                                    <Button id={button.id} buttonSize='btn--wide' buttonColor={buttonColor} key={i}>
                                                        {button.label}
                                                    </Button>
                                                    :
                                                    <Button buttonSize='btn--wide' buttonColor={buttonColor} key={i}>
                                                        {button.label}
                                                    </Button>
                                            
                                        ) 
                                    })}
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div> 
                            {img ?
                                (
                                    <div className="col">
                                        <div className="home__hero-img-wrapper">
                                            {
                                                imgObject ? 
                                                <div id={alt} className={`home__hero-img `} style={{width: imgSize}} dangerouslySetInnerHTML={{__html: img}}></div>
                                                :
                                                <img src={img} alt={alt} className={`home__hero-img `+imgAnimation} style={{width: imgSize}}/>
                                            }
                                        </div>
                                    </div> 
                                )
                                :
                                ''
                            }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

