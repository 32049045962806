import React, {useEffect} from 'react';
import Requisitos from './Requisitos/Requisitos';
import HeroSection from '../../generales/HeroSection/HeroSection'
import {homeObjProductos} from './Data_nosotros'
import {homeObjBeneficios} from './Data_nosotros'
import {homeObjCaracteristicas} from './Data_nosotros'
import Filosofía from './Filosofia/Filosofia';

function MobileApp() {

    useEffect(() => { 
        document.querySelector("#nos3 svg #layer2").classList.add("animation_verticalsqueesh");
        document.querySelector("#nos2 svg #layer2").classList.add("animation_slowhorizontalflow");
    }, []);

    return (
        <React.Fragment>
         <Filosofía />
         <HeroSection {...homeObjProductos} />
         <HeroSection {...homeObjBeneficios} />
         <HeroSection {...homeObjCaracteristicas} />
         <Requisitos />  
        </React.Fragment>
    )
}

export default MobileApp