import React from 'react'
import './SelectTipoPago.css';
// import {FaMapMarkerAlt} from 'react-icons/fa'

export default function SelectTipoPago({
    fPagoTipo_actual,
    changeTipoPago  
}) 
{
    return (
        <React.Fragment>
        <label className="selecTipoPago_label">Seleccione una forma de pago:</label>
        <div className="container-forma-pago">
            <div className="option" >
                <label className="label-pago" aria-label="Efectivo (Fichas De Déposito)">
                <input type="radio" name="f-pago" id="efectivo" value="1" onChange={changeTipoPago} checked={fPagoTipo_actual === "1"}/>
                    <span></span>   
                    Efectivo        
                </label>
            </div>
            <div className="option">
                <label className="label-pago" aria-label="Transferencia">
                <input type="radio" name="f-pago" id="transfer" value="2" onChange={changeTipoPago}  checked={fPagoTipo_actual === "2"}/>
                    <span></span>  
                    Transferencia
                </label>
            </div>
        </div>
        </React.Fragment>
    )
}