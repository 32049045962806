import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Contratos.css';
import ContratoCard from './ContratoCard.js/ContratoCard';
import {bcAlert} from '../../../../boclAlert';
import ReactGA from 'react-ga';

const AgregarContartos=(props)=>{
    /*--Declaration of INPUTS VARIABLES
    ---------correo (string)
    ---------password (string)
    ---------user (object)
    -------Brandon Cantu
    ------------*/
    const [newContrato, setnewContrato] = useState({
        referencia: "",
        dia:"",
        mes:"",
        year: ""
    });

    const {referencia="", dia="", mes="", year=""} = newContrato;

    const [contratos, setContratos] = useState([]);

    //SE LLENAN CAMPOS DE SELECT
    useEffect(() => { 
        //SE LLENAN CAMPOS DE SELECT
        let unix_timestamp = new Date();
        let year_actual = unix_timestamp.getFullYear();
        year_actual = parseInt(year_actual);
        for(let i = year_actual; i >= 1921; i--){
            document.getElementById("selectAno").append(new Option(i,i));
        }
    
        for(let x = 1 ; x <= 31; x++){   
            document.getElementById("selectDia").append(new Option(x, x));
        }
    }, []);

    useEffect(() => { 
        let cleanUp = true;

        const showContratos = () => {
            const token = localStorage.getItem('token');
            let json = JSON.stringify({
                "token": token,
              });
    
            axios.post(process.env.REACT_APP_API_DM_URL+"/api/listar_contratos",{"json":json}, {
                headers: {
                'Authorization' : token,
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if(cleanUp){
                    let status = response.data.status;
                    let data = response.data.data;
                    
                    if(status === "success" ){
        
                        let lista_contratos = data.lista_contratos;
        
                        setContratos(lista_contratos);
        
                    }else{
                        bcAlert({
                            msg : {
                                "title":"Sin contratos", 
                                "desc":"No se encontraron contratos"
                            },
                            type :"warning", 
                            styles : ``, 
                            options : false
                        });
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                // Request made and server responded
                console.log("Error-Response: "+error.response.data);
                console.log("Error-Status: "+error.response.status);
                console.log("Error-Headers: "+error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                console.log('Error-Request: '+error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error-Message: '+ error.message);
                }
            });
        }

        showContratos();
        
        return () => {
            // cancel the subscription
            cleanUp = false;
        };
    }, []);
 
    async function removeContrato(contrato){
        // // debugger;
        let card = document.getElementById("card_lista_contratos_"+contrato);
        card.classList.add("card_Contrato_hide");
    }

    const onInputChange = e => {
        // console.log(e.target.name)
        setnewContrato({ ...newContrato, [e.target.name]: e.target.value });
    };

    const GetContratos = () => {
        const token = localStorage.getItem('token');
        let json = JSON.stringify({
            "token": token,
          });

        axios.post(process.env.REACT_APP_API_DM_URL+"/api/listar_contratos",{"json":json}, {
            headers: {
            'Authorization' : token,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log(response)
            let status = response.data.status;
            let data = response.data.data;
            
            if(status === "success" ){
                let lista_contratos = data.lista_contratos;
                setContratos(lista_contratos);

            }else{
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"No se encontraron contratos"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }
        })
        .catch(function (error) {
            if (error.response) {
            // Request made and server responded
            console.log("Error-Response: "+error.response.data);
            console.log("Error-Status: "+error.response.status);
            console.log("Error-Headers: "+error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            console.log('Error-Request: '+error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error-Message: '+ error.message);
            }
        });
    }

    const eliminarContrato = (id_contrato) => {
        let deleteBtns = document.getElementsByClassName("deleteC");
        let ogHtml = ""
        
        bcAlert({
            msg : {
                "title":"Espera", 
                "desc":"Seguro que deseas eliminar este contrato?"
            },
            type :"warning", 
            styles : ``, 
            options : true,
        }).then(confirm => {
            if(confirm){
                //ENVIAMOS TRACKING INFO
                ReactGA.event({
                    category: 'Boton Portal',
                    action: 'Borrar contrato'
                });
                //DESACTIVAR BOTON
                for(let i = 0; i<=deleteBtns.length-1; i++){
                    ogHtml = deleteBtns[i].innerHTML;
                    deleteBtns[i].innerHTML="";
                    deleteBtns[i].disabled = true;
                }

                const token = localStorage.getItem('token');
                var json = JSON.stringify({"id_contrato":id_contrato});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/eliminar_contrato",{"json":json}, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    let result = response.data;
                    if (result.status === 'success') {
                        removeContrato(id_contrato)

                        setTimeout(() => {
                            GetContratos();
                        }, 3000);
                        // alert("Eliminado correctamente!");
                        bcAlert({
                            msg : {
                                "title":"Listo!", 
                                "desc":"Se eliminó el contrato"
                            },
                            type :"success", 
                            styles : ``, 
                            options : false
                        });
                        // GetContratos();
                        props.contratosGlobales();
                    }else{
                        // alert("Error");
                        bcAlert({
                            msg : {
                                "title":"Oops!", 
                                "desc":"Hubo un problema al intentar eliminar el contrato"
                            },
                            type :"error", 
                            styles : ``, 
                            options : false
                        });
                    }

                    //ABLE BTN
                    for(let i = 0; i<=deleteBtns.length-1; i++){
                        deleteBtns[i].innerHTML=ogHtml;
                        deleteBtns[i].disabled = false;
                    }

                })
                .catch(function (error) {

                    //ABLE BTN
                    for(let i = 0; i<=deleteBtns.length-1; i++){
                        deleteBtns[i].innerHTML=ogHtml;
                        deleteBtns[i].disabled = false;
                    }

                    // alert("Hubo un problema al consultar la información");
                    bcAlert({
                        msg : {
                            "title":"Oops!", 
                            "desc":"Hubo un problema al consultar la información"
                        },
                        type :"error", 
                        styles : ``, 
                        options : false
                    });
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }else{
                return;
            }
        });
        
    }

    const agregarContrato = () =>{
        if(referencia === '' || referencia.length!==6){
            // alert('Debes ingresar una referencia de 6 dígitos')
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Debes ingresar una referencia de 6 dígitos"
                },
                type :"warning", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(dia === ''){
            // alert('Debes ingresar el día')
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Debes ingresar el día"
                },
                type :"warning", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(mes === ''){
            // alert('Debes ingresar el mes')
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Debes ingresar el mes"
                },
                type :"warning", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(year === ''){
            // alert('Debes ingresar el año')
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Debes ingresar el año"
                },
                type :"warning", 
                styles : ``, 
                options : false
            });
            return false;
        }

        //ENVIAMOS TRACKING INFO
        ReactGA.event({
            category: 'Boton Portal',
            action: 'Agregar contrato'
        });
        //DESABILITAMOS EL BOTON
        let btn = document.getElementById("addC_btn");
        btn.disabled = true;
        btn.classList.add("btn-blue-disabled")
        //disapper content
        let ogInner = btn.innerHTML;
        let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
        btn.innerHTML = loaderHtml;

        let day = dia, month = mes;
        if (dia <= 10) {
            day = '0'+ day;
        }
        if (mes <= 10) {
            month = '0'+ mes;
        }
    
        var fecha_nacimiento = year + '-' + month + '-' + day;
        var json = JSON.stringify({"referencia":referencia, "fecha_nacimiento":fecha_nacimiento});
        
        // console.log(json)
        const token = localStorage.getItem('token');
        axios.post(process.env.REACT_APP_API_DM_URL+"/api/agregar_contrato",{"json":json}, {
            headers: {
            'Authorization' : token,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
            }
        })
        .then(response => {

            btn.disabled = false;
            btn.classList.remove("btn-blue-disabled")
            btn.innerHTML = ogInner;
            
            let result = response.data;
            // console.log(result)
            if (result.status === 'success') {
                // alert("Contrato agregado correctamente!")
                bcAlert({
                    msg : {
                        "title":"Listo!", 
                        "desc":"Contrato agregado correctamente!"
                    },
                    type :"success", 
                    styles : ``, 
                    options : false
                });

                setnewContrato({ ...newContrato, referencia:"", dia:"", mes:"", year:"" });
                document.getElementById("input_referencia").value = "";
                document.getElementById("selectDia").value = "";
                document.getElementById("selectMes").value = "";
                document.getElementById("selectAno").value = "";

                // props.contratosGlobales();
                GetContratos();
                props.contratosGlobales();

            }else if(result.status === 'error_duplicado'){
                // alert("Ya cuentas con este contrato")
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"Ya cuentas con este contrato"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }else if(result.status === 'error_fecha_nacimiento'){
                // alert("La fecha de nacimiento no coincide")
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"La fecha de nacimiento no coincide"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }else if(result.status === 'error_exceso_contratos'){
                // alert("Ya tienes muchos contratos")
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"Has llegado al límite de contratos"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }else{
                // alert("Hubo un error al agregar el contrato!")
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"Hubo un error al agregar el contrato!"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }
        })
        .catch(function (error) {

            btn.disabled = false;
            btn.classList.remove("btn-blue-disabled")
            btn.innerHTML = ogInner;

            // alert("Hubo un problema al consultar la información");
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Hubo un problema al consultar la información"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            if (error.response) {
            // Request made and server responded
            console.log("Error-Response: "+error.response.data);
            console.log("Error-Status: "+error.response.status);
            console.log("Error-Headers: "+error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            console.log('Error-Request: '+error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error-Message: '+ error.message);
            }
        });
    }

    return (
        <div className="emtpy_Container">
            <div className="contratosC_ agregarC_">
                <h1>Agrega Contratos</h1>
                <div className="form">
                    <div className="groupC">
                        <label>Referencia de contrato</label>
                        <div className="form-group">
                            <input id="input_referencia" className="referencia" type="tel" name="referencia" maxLength={6} placeholder={"6 Dígitos"} onChange={(evt) => onInputChange(evt)}></input>
                        </div>
                    </div>
                    <div className="groupC">
                        <label>Fecha de nacimiento del titular</label>
                        <div className="fechaC">
                            <div className="form-group">
                                {/* <label>Día</label> */}
                                <div className="selectDia">
                                    <select id="selectDia" onChange={(e) => onInputChange(e)} name="dia">
                                        <option value="">Día...</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group"> 
                                {/* <label>Mes</label> */}
                                <div className="selectMes">
                                    <select id="selectMes" onChange={(e) => onInputChange(e)} name="mes">
                                        <option value="">Mes...</option>
                                        <option value="1">Enero</option>
                                        <option value="2">Febrero</option>
                                        <option value="3">Marzo</option>
                                        <option value="4">Abril</option>
                                        <option value="5">Mayo</option>
                                        <option value="6">Junio</option>
                                        <option value="7">Julio</option>
                                        <option value="8">Agosto</option>
                                        <option value="9">Septiembre</option>
                                        <option value="10">Octubre</option>
                                        <option value="11">Noviembre</option>
                                        <option value="12">Diciembre</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                {/* <label>Año</label> */}
                                <div className="selectAño">
                                    <select id="selectAno" onChange={(e) => onInputChange(e)} name="year">
                                        <option value="">Año...</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                        <button className="addC_btn" id="addC_btn" onClick={() => agregarContrato()}>
                            AGREGAR
                        </button>
                </div>
            </div>
            <div className="contratosC_ float-left-cont">
                <h1>Elimina Contratos</h1>
                {
                    contratos.map((ct, i) => { 
                        // Return the element. Also pass key 
                        let marca = ct.Mo ? ct.Mo : "";
                        let nombre = ct.Nombre_Auto ? ct.Nombre_Auto : ""; 
                        let modelo = ct.Modelo ? ct.Modelo : "";
                        let color = ct.Color ? ct.Color : "";
                        let placas = ct.Placas ? ct.Placas : ""; 
                        let auto = marca+" "+nombre+" "+modelo+" "+color+" "+placas;
                        return (
                            <ContratoCard
                                key={i}
                                cliente={ct.Nombre_Cliente}
                                referencia={ct.referencia_corta.substr(2, ct.referencia_corta.lenght)}
                                auto={auto}
                                contrato_metacid={ct.Contrato_Metacid}
                                eliminarContrato = {eliminarContrato}
                            />
                        ) 
                    })
                }
            </div>
        </div>
    )
}

export default AgregarContartos