import React from 'react';
import { Grid,Paper, Avatar, TextField, Button, Typography } from '@material-ui/core'
import { NavLink } from "react-router-dom";
import {useState} from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import "./LogSign.css"
import { InputAdornment, IconButton,InputLabel} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {bcAlert} from '../../boclAlert'

const Signup=()=>{
 
    const paperStyle={padding :30,height:'auto',width:480, maxWidth:"90%", margin:"0 auto", paddingTop: '50px'}
    const avatarStyle={backgroundColor:'#3370bd', width: 60, height: 60}
    const btnstyle={margin:'2rem 0'}
 
    const[errors,setErrors] = useState('');
    const[msgColor,setMsgColor] = useState('');
    const [user, setUser] = useState({
        nombre: "",
        correo: "",
        password:""
      });
      
    const {nombre, correo,password} = user;
    const onInputChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
    };

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    let history = useHistory(); 

//    async function  signup()
//        {
        
//         let user_send = JSON.stringify(user);
//         if(user.password.length < 8){
//             setErrors("Contraseña inválida");
//             setMsgColor("darkorange")
//             return false;
//         }
//         let send = {"json": user_send};

//         //SET LOADER
//         let btnLogin = document.querySelector("#btnRegister .MuiButton-label");
//         //disapper content
//         let ogInner = btnLogin.innerHTML;
//         let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
//         btnLogin.innerHTML = loaderHtml;

//         let result = await axios.post(process.env.REACT_APP_API_DM_URL+"/api/registrar",send);
//         if(result){
//             btnLogin.innerHTML = ogInner;
//             if(result.data.status === "error"){
//                 setErrors(result.data.message)
//                 setMsgColor("red")
//             }else{
//                 setErrors("Registro Exitoso!")
//                 setMsgColor("green")
//                 setUser({nombre:"",correo:"",password:""}) // To Clear all fields
//                 bcAlert({
//                     msg : {
//                         "title":"Listo!", 
//                         "desc":"Inicia sesión con los datos que registraste."
//                     },
//                     type :"success", 
//                     styles : ``, 
//                     options : false
//                   });
//                 setTimeout(() => {
//                     history.push("/login");
//                 }, 1750);
//             }
//         }
//         else{
//             btnLogin.innerHTML = ogInner;
//             setErrors(result.data.message)
//             setMsgColor("red")
//         }
//     }
    
    const signup = () =>
    {
        if(user.nombre === ''){
            setErrors("El nombre esta vacío");
            setMsgColor("red")
            return false;
        }else if(
        user.correo === '' || 
        !user.correo.includes("@") || 
        !user.correo.includes(".") || 
        user.correo.includes("@.") || 
        user.correo.includes(".@") || 
        user.correo.includes("..") ||
        user.correo.slice(-1) === "."){
            setErrors("El correo es requerido");
            setMsgColor("red")
            return false;
        }else if(user.password === '' && user.password.length <8){
            setErrors("Una contraseña de 8 carácteres es requerida");
            setMsgColor("red")
            return false;
        }

        let user_send = JSON.stringify(user);
        if(user.password.length < 8){
            setErrors("Contraseña inválida");
            setMsgColor("darkorange")
            return false;
        }

        //SET LOADER
        let btnLogin = document.querySelector("#btnRegister .MuiButton-label");
        //disapper content
        let ogInner = btnLogin.innerHTML;
        let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
        btnLogin.innerHTML = loaderHtml;

        let send = {"json": user_send};

        axios.post(process.env.REACT_APP_API_DM_URL+"/api/registrar",send)
        .then(response => {

            btnLogin.innerHTML = ogInner;
            if(response.data.status === "success"){
                setErrors("Registro Exitoso!")
                setMsgColor("green")
                setUser({nombre:"",correo:"",password:""}) // To Clear all fields
                bcAlert({
                    msg : {
                        "title":"Listo!", 
                        "desc":"Inicia sesión con los datos que registraste."
                    },
                    type :"success", 
                    styles : ``, 
                    options : false
                  });
                setTimeout(() => {
                    history.push("/login");
                }, 1750);
            }else{
                //Correo tomado
                if(response.data.message.correo){
                    setErrors(response.data.message.correo)
                }else{
                    setErrors("No se pudo registrar tu información.")
                }
                setMsgColor("red")
            }
        })
        .catch(function (error) {
            btnLogin.innerHTML = ogInner;
            setErrors("Ocurrió un error, inténtelo de nuevo más tarde.")
            setMsgColor("red")
            if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            }
        });
    }

    const submitHandle = e => {
        e.preventDefault()
        signup();
    }
     
    return(
        <Grid className="section-logsign">
            <form id={"loginForm"} onSubmit={e => submitHandle(e)}>
                <Paper elevation={10} style={paperStyle}>
                    <Grid align='center'>
                        <Avatar style={avatarStyle}><img src={`images/isotipo_black.svg`} alt={"iso"} style={{width: "40px", filter: "invert(1)"}}/></Avatar>
                        <h2 style={{marginTop: "1rem"}}>Registro</h2>
                        <h3 style={{color:msgColor, margin: ".8rem 0 1rem 0"}}>{errors}</h3>
                    </Grid>
                    
                    <TextField label='Nombre' className="login-input" name="nombre" value={nombre} onChange={e => onInputChange(e)} placeholder='Ingresa tu nombre' type='text' fullWidth required/>
                    <TextField label='Email'  className="login-input" name="correo" value={correo}  onChange={e => onInputChange(e)} placeholder='Ingresa tu correo' type='email' fullWidth required/>
                    {/* <TextField label='Contraseña'  name="password" value={password}  onChange={e => onInputChange(e)} placeholder='Ingresa tu contraseña' type='password' fullWidth required/> */}
                    <TextField
                        label='Contraseña'
                        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                        onChange={e => onInputChange(e)}
                        inputProps={{
                            label: 'Contraseña'
                        }}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {showPassword ? <Visibility style={{width: "1.4rem", marginRight:"-.5rem"}}/> : <VisibilityOff style={{width: "1.4rem", marginRight:"-.5rem"}}/>}
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                        className="login-input" name="password" value={password} fullWidth required
                    />
                    <InputLabel style={{fontSize: ".8rem",fontFamily: 'Open Sans',margin: ".2rem",color: "darkorange"}}>
                        Mínimo 8 caracteres
                    </InputLabel>
                
                    <Button type='submit' id="btnRegister" color='primary' variant="contained" style={btnstyle} fullWidth>Registrarte</Button>
                
                    <Typography>Da click aquí para
                    <NavLink to="/login">
                        <span style={{marginLeft:"4px"}}>Iniciar Sesión</span>
                    </NavLink>
                    </Typography>
                </Paper>
            </form>
        </Grid>
    )
}
 
export default Signup