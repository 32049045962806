import React, { useState, useEffect } from 'react';
import './InstruccionPago.css';
import {MdContentCopy} from 'react-icons/md'
import { bcAlert } from '../../../boclAlert';

const InstruccionTransfer=(props)=>{

    const [lugar, setLugar] = useState("seven");

    useEffect(() => {
        let newLugar = lugar 
        newLugar = props.lugarPago;
        setLugar(newLugar);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.lugarPago])

    function copyTo(elementID){
        var range, selection;
        let element = document.getElementById(elementID);
        if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();        
            range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }
        
        try {
            document.execCommand('copy');
            bcAlert({
                msg : {
                    "title":"Texto copiado!", 
                    "desc":""
                },
                type :"success", 
                styles : ``, 
                options : false
            });
        }
        catch (err) {
            bcAlert({
                msg : {
                    "title":"Error!", 
                    "desc":"No se pudo copiar"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
        }
    }

    // console.log(props.instrucciones.instruccion)
    return (
        <React.Fragment>
            <div id="transferencia" className="tabcontent" style={{display: "block"}}>
                <div className="info-box">
                    <div className="info-cont">
                        <h2><span style={{fontSize: "1rem"}}>° Recuerda:</span></h2>
                        <ul>
                            <li>Si realizas un SPEI o Traspaso después de las 10pm, tu pago se verá reflejado al siguiente día hábil bancario.</li>
                            <li>Si aún no recibes la notificación de tu pago, revisa que la tranferencia no haya sido devuelta.</li>
                            <li>Tu pago puede demorar en reflejarse entre 30-60 minutos.</li>
                        </ul>
                    </div>
                </div>
                <div className={`logotransfer sh-${lugar}`}><img src={`${process.env.PUBLIC_URL}/images/portal/formas_pago/logo-${lugar}.jpg`} alt="imagen" width="100" height="40" className="imagen_logo"/></div>
                <ol className="step-list">
                    {
                        props.instrucciones.instruccion ? 
                            props.instrucciones.instruccion.map((inst, i) => {     
                                // Return the element. Also pass key     
                                return (
                                    inst.input === "1" ?
                                        inst.code === "referencia" ?
                                            
                                                <li key={i}>{inst.descripcion}<br/>
                                                    <span> <p id="ref">{props.datosPago.referencia}</p><button className="copyClip" onClick={() => copyTo('ref')}><MdContentCopy/></button></span>
                                                </li>
                                            
                                        :
                                        inst.code === "clabe" ?
                                            
                                                <li key={i}>{inst.descripcion}<br/>
                                                    <span> <p id="clabe">{props.datosPago.clabe}</p><button className="copyClip" onClick={() => copyTo('clabe')}><MdContentCopy/></button></span>
                                                </li>
                                            
                                        :
                                        inst.code === "monto" ?
                                            
                                                <li key={i}>{inst.descripcion}<br/>
                                                    <span> <p id="monto">{props.datosPago.monto}</p><button className="copyClip" onClick={() => copyTo('monto')}><MdContentCopy/></button></span>
                                                </li>
                                            
                                        :
                                        inst.code === "emisora" ?
                                            
                                                <li key={i}>{inst.descripcion}<br/>
                                                    <span> <p id="emisora">{props.datosPago.emisora}</p><button className="copyClip" onClick={() => copyTo('emisora')}><MdContentCopy/></button></span>
                                                </li>
                                            
                                        :
                                        <li key={i} style={{display: "none"}}></li>
                                    :
                                    
                                        <li key={i}>{inst.descripcion}</li>
                                ) 
                            })
                            :
                            <React.Fragment></React.Fragment>
                    }
                </ol>
            </div>
        </React.Fragment>
    )
}

export default InstruccionTransfer