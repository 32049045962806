// const BloquearNumerosyEsp = (event) => {
//     return !/[~`!@#$%^&*()1234567890+=\-[\]\\';,/{}|\\":<>?]/g.test(String.fromCharCode(event.charCode)) ? event.preventDefault() : false;
// }

function fadeOut(seconds = 2000){
    setTimeout(function(){ 
        
        if(!document.getElementById("boclAlertWrapper")){
            return;
        }else{
            document.getElementById("boclAlertWrapper").remove();
        }

    }, seconds);
    return;
}


export const bcAlert = ({
    msg = {"title": "Titulo", "desc": "Descripcion"},
    type = 'success',
    styles = `background: white; border: 1px solid black;`,
    options = false,
    question = ""
}={}) => { 
    // debugger;
    
    //checa que no exista una alerta ya
    if(document.getElementById("boclAlertWrapper")){
        document.getElementById("boclAlertWrapper").remove();
    }

    let divWrapper = document.createElement("div");
    divWrapper.classList.add("boclAlertWrapper");
    divWrapper.style.opacity = 0;
    divWrapper.setAttribute('id','boclAlertWrapper');

    let divBox = document.createElement("div");
    divBox.classList.add("boclAlertBox");
    divBox.setAttribute('id','boclAlertBox');

    if(type === "success"){
        divBox.classList.add("boclAlertSuccess");
    }
    if(type === "info"){
        divBox.classList.add("boclAlertInfo");
    }
    if(type === "warning"){
        divBox.classList.add("boclAlertWarning");
    }
    if(type === "error"){
        divBox.classList.add("boclAlertError");
    }

    divBox.style.cssText = styles;
    let alert_box = `
        <div class="boclAlertContent">
            <div class="boclAlertSimpleContent">
                <h1>${msg["title"]}</h1>
                <p>${msg["desc"]}</p>
            </div>
        </div>`;
    divBox.innerHTML = alert_box;

    //SE INCLUYE FORMULARIO SI ES QUE HAY 'OPTIONS'
    if(options){
        let divForm = document.createElement("div");
        divForm.classList.add("boclAlertFormContent");
        divForm.setAttribute('id','boclAlertFormContent');
        let div_form = `
            <h3>${question}</h3>
            `;
        divForm.innerHTML = div_form;

        let divButtons = document.createElement("div");
        divButtons.classList.add("boclAlertButtonsDiv");
        divForm.append(divButtons);

        let confirmButton = document.createElement("button");
        confirmButton.setAttribute('id','boclConfirmButton');
        // confirmButton.setAttribute('onclick', () => resolve(true));
        confirmButton.innerHTML = "YES";
        let cancelButton = document.createElement("button");
        cancelButton.setAttribute('id','boclCancelButton');
        // cancelButton.setAttribute('onclick', () => resolve(false));
        cancelButton.innerHTML = "NO";

        divButtons.append(confirmButton);
        divButtons.append(cancelButton);

        divBox.append(divForm);   
    }

    divWrapper.append(divBox);

    if(type === "success"){
        divBox.classList.add("boclAlertSuccess");
    }
    if(type === "info"){
        divBox.classList.add("boclAlertInfo");
    }
    if(type === "warning"){
        divBox.classList.add("boclAlertWarning");
    }
    if(type === "error"){
        divBox.classList.add("boclAlertError");
    }

    if(document.getElementById("boclAlertWrapper")){
        return;
    }else{
        document.getElementById("root").append(divWrapper);
    }

    //ANIMATION IN
    let opacity=0;
    let inttervalIn = setInterval(function(){
        // console.log("subo")
        let body=document.getElementById("boclAlertWrapper");
        if(body){
            opacity = Number(window.getComputedStyle(body).getPropertyValue("opacity"))

            if(opacity<1){
                opacity=opacity+0.1;
                        body.style.opacity=opacity
            }
            else{
                clearInterval(inttervalIn); 
            }
        }
    }, 10);

    if(options){
        return new Promise((resolve, reject) => {
            let btnConfirm = document.getElementById("boclConfirmButton");
            btnConfirm.addEventListener("click", function(){
                btnConfirm.disabled = true;
                resolve(true)
                fadeOut(100);
            })
            let btnCancel = document.getElementById("boclCancelButton");
            btnCancel.addEventListener("click", function(){
                btnCancel.disabled = true;
                resolve(false);
                fadeOut(200);
            })
        });
    }else{
        //ANIMATION OUT
        fadeOut();
        return new Promise((resolve, reject) => {
            resolve(false)
        });
    }   
}