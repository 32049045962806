import React, {Component} from 'react';
import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';

const mapStyle = [
    {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [
            { visibility: 'off' }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
            { visibility: 'off' }
        ]
    }
   ];

export class MapContainer extends Component {

    _mapLoaded(mapProps, map) {
      map.setOptions({
        styles: mapStyle
      });
    }

    constructor(props) {
        super(props);
    
        this.state = {
          stores: [
                  {latitude: 25.6645701, longitude: -100.3836163}]
        }
      }
      
    
      displayMarkers = () => {
        return this.state.stores.map((store, index) => {
          return <Marker key={index} 
                        id={index} 
                        position={{
                            lat: store.latitude,
                            lng: store.longitude
                        }}
                        onClick={() => window.open('https://www.google.com/maps/place/Arrendadora+Datamovil,+G1+Y+G2,+Humberto+Lobo+%23520+Locales+F18,+66220+San+Pedro+Garza+Garc%C3%ADa,+N.L./@25.6645653,-100.3836163,17z/data=!4m2!3m1!1s0x8662bd88ce2052bf:0x25a4efee0d033a4', '_blank')}
                        icon={{
                            url: "images/home/maps.png"
                            // anchor: new google.maps.Point(32,32),
                            // scaledSize: new google.maps.Size(64,64)
                          }} 
                        options={{"aria-label": "dm-marker"}}
                    />
        })
      }
    
      render() {
        const mapStyles = {
            width: '100%',
            height: '100%',
          };
        const wrapperStyles = {
            position: 'relative',
            display: 'block',
            height: '25rem'
        };
        
        return (
            <div className="mapSection" style={wrapperStyles}>
                <Map
                google={this.props.google}
                zoom={15}
                style={mapStyles}
                initialCenter={{ lat: 25.6645701, lng: -100.3836163}}
                onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                mapTypeControl= {false}
                >
                {this.displayMarkers()}
                </Map>
            </div>
        );
      }
}
export default GoogleApiWrapper({
    // Key: 'AIzaSyCMH5uS_3w2CRl0UGdIn3KEqDGcOndP53E'
    Key: 'AIzaSyBAPt7_WgJVezz8w2f73-FIvZ8-CDEh-N4'
    // Key: 'AIzaSyBD2JdYEetaOtmYJFm60N-ROGoJnrWmSno'
  })(MapContainer);

