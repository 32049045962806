import React from 'react';
import ContactForm from '../../../generales/ContactForm';
import "./ContactSection.css"
import Oficinas from './Oficinas';

function ContactSection() {
    
    return (
        <div className="contact-section">
            <div className="c-section mensaje-section">
                <h3>Ponte en contacto con nosotros</h3>
                <ContactForm />
            </div>
            <div className="c-section contact-info-section">
                <h3>Nuestras oficinas</h3>
                <Oficinas />
            </div>
        </div>
    )
}

export default ContactSection