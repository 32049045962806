import React from 'react'
import Calculadora from '../Cotizador/Componentes/Calculadora/Calculadora';

const CotizadorAutosdatamovil = () => {

    // SE INSERTA POR MEDIO DE UN IFRAME DE LA SIGUIENTE MANERA
    // <iframe src="http://arrendadoradatamovil.com/cotizadorautosdatamovil" height="650" width="567" title="Iframe Example"></iframe>

  return (
    <div className="cotizador_autosdatamovil" style={{margin: "1rem", maxWidth: "480px"}}>
        <Calculadora
          origen_mail={"autosdatamovil"}>
        </Calculadora>
    </div>
  )
}

export default CotizadorAutosdatamovil