import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { FaBars, FaTimes} from 'react-icons/fa'
import { Button } from '../generales/Button';
import './Navbar.css';
import {IconContext} from 'react-icons/lib'

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false)
        }else{
            setButton(true)
        }
    }

    useEffect(() => {
        showButton();

        window.addEventListener('resize', showButton)

        let myNav = document.getElementById("navbar");

        if(document.getElementById("homePage")){
            myNav.classList.add("navbar-fixed");
        }

        window.onscroll = function() {

                if (document.body.scrollTop >= 580 || document.documentElement.scrollTop >= 580) {
                    myNav.classList.add("navbar-scroll");
                } else {
                    myNav.classList.remove("navbar-scroll");
                }
        };
    }, [])

    useEffect(() => {
        return () => {
            setClick(false);
            setButton(true);
        };
    }, [])

    return (
        <React.Fragment>
        <IconContext.Provider value={{ color: '#fff'}} >
            <div className="navbar" id="navbar">
                <div className="navbar-container container">
                    <Link to='/' className="navbar-logo">
                        <img src="./images/logo.svg" alt="logo" className="logo-datamovil-bar"/>
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                                Inicio
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/mobile-app" className="nav-links" onClick={closeMobileMenu}>
                                App
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contacto" className="nav-links" onClick={closeMobileMenu}>
                                Contacto
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/nosotros" className="nav-links" onClick={closeMobileMenu}>
                                Nosotros
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/cotizador" className="nav-links" onClick={closeMobileMenu}>
                                Cotizador
                            </Link>
                        </li>
                        <li className="nav-btn">
                            {button ? (
                                <Link to="/login" className="btn-link">
                                    <Button buttonStyle='btn--outline'>SERVICIOS EN LÍNEA</Button>
                                </Link>
                            ): (
                                <Link to="/login" className="btn-link" onClick={closeMobileMenu}>
                                    <Button buttonStyle="btn--outline"
                                            buttonSize="btn--mobile">SERVICIOS EN LÍNEA</Button>
                                </Link>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
            </IconContext.Provider>
        </React.Fragment>
    )
}

export default Navbar
