import React, { useState, useEffect } from 'react'
import './HomePortal.css';
//icons
import {IoDocumentsOutline} from 'react-icons/io5';
import {IoCalendarOutline} from 'react-icons/io5';
import {GiPayMoney} from 'react-icons/gi';
import {HiOutlineShieldCheck} from 'react-icons/hi';
import {MdContactPhone} from 'react-icons/md'
import {RiKey2Fill} from 'react-icons/ri'
import Avisos from './Avisos/Avisos';
import BtnLink from './BotonLink/BtnLink';
import ModalRegistroFiscal from '../DatosFiscales/ModalRegitroFiscal/ModalRegistroFiscal';
//SET BANNNER
 
const HomePortal=(props)=>{

    const [estadoModal1, cambiarEstadoModal1] = useState(true);
    const [modal1, cambiarModal1] = useState(1);

    useEffect(() => {
        cambiarEstadoModal1(true);
        cambiarModal1(1); 
    }, [props.contrato_metacid])

    return(
        <React.Fragment>
            {
                props.contrato_metacid === 0 ?
                <div className='selectC__cards'>
                    <div className='empty_selectcard_Contrato'>
                        <h3 style={{margin:".1rem 10%", fontWeight:"500"}}>Tu cuenta no tiene contratos asociados, por favor agrégalos desde el menú lateral.</h3>
                    </div>
                </div>
                :
                <>
                    {/* BANNER *******************/}
                    {/* <div className="banner">
                        <img src={`${process.env.PUBLIC_URL}/images/portal/bannerWeb.jpg`} alt="banner"/>
                    </div> */}
                    {/* AVISOS *******************/}
                    <Avisos 
                        contrato_meta_cid={props.contrato_metacid}
                    />

                    {/* OPCIONES */}
                    <BtnLink
                        metacid={props.contrato_metacid}
                        enlace="/portal/clave"
                        icon={<RiKey2Fill className="opcion-icon"/>}
                        label="Clave Datamovil"
                    />

                    {
                        props.datosExtras.showFactura === 1 ?
                        <BtnLink
                            metacid={props.contrato_metacid}
                            enlace="/portal/dep_factura"
                            icon={<IoDocumentsOutline className="opcion-icon"/>}
                            label="Depósitos y Facturación"
                        />
                        :
                        <BtnLink
                            metacid={props.contrato_metacid}
                            enlace="/portal/dep_factura"
                            icon={<IoDocumentsOutline className="opcion-icon"/>}
                            label="Depósitos"
                        />
                    }
                    

                    <BtnLink
                        metacid={props.contrato_metacid}
                        enlace="/portal/estado_cuenta"
                        icon={<IoCalendarOutline className="opcion-icon"/>}
                        label="Estado De Cuenta"
                    />

                    <BtnLink
                        metacid={props.contrato_metacid}
                        enlace="/portal/formas_pago"
                        icon={<GiPayMoney className="opcion-icon"/>}
                        label="Formas De Pago"
                    />

                    <BtnLink
                        metacid={props.contrato_metacid}
                        enlace="/portal/seguro"
                        icon={<HiOutlineShieldCheck className="opcion-icon"/>}
                        label="Seguro Automotriz"
                    />

                    <BtnLink
                        metacid={props.contrato_metacid}
                        enlace="/portal/contacto"
                        icon={<MdContactPhone className="opcion-icon"/>}
                        label="Contáctanos"
                    />
                    <BtnLink
                        metacid={props.contrato_metacid}
                        enlace="/portal/datos_fiscales"
                        icon={<IoDocumentsOutline className="opcion-icon"/>}
                        label="Datos Fiscales"
                    />
                </>
            }
            <ModalRegistroFiscal 
                estado_modal={estadoModal1}
                cambiarEstadoModal1={cambiarEstadoModal1}
                contrato_meta_cid={props.contrato_metacid}
                modal1={modal1}
                cambiarModal1={cambiarModal1}
            />
        </React.Fragment>     
    )
}
 
export default HomePortal