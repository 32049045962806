import React from 'react';
import './AvisoPrivacidad.css';

function AvisoPrivacidad() {

    return (
        <div id="content_aviso_privacidad" className="col-md-12">
            <h1>Aviso de Privacidad</h1>
            <div id="post-914" className="post-914 page type-page status-publish hentry">
                <p>De acuerdo a lo Previsto en la “Ley Federal de Protección de Datos Personales”, declara ARRENDADORA DATAMOVIL S.A. DE C.V , ser una empresa legalmente constituida de conformidad con las leyes mexicanas, con domicilio en Plaza San Pedro Locales F18, G1 y G2 , Av. Humberto Lobo , Número 520, en la Colonia del Valle, en el Municipio de San Pedro Garza García, Nuevo León y como responsable del tratamiento de sus datos personales, hace de su conocimiento que la información de nuestros clientes es tratada de forma estrictamente confidencial por lo que al proporcionar sus datos personales, tales como:</p>
                <ol>
                    <li>&nbsp;Nombre Completo.</li>
                    <li>Dirección.</li>
                    <li>Registro Federal de Contribuyentes.</li>
                    <li>Teléfonos de Hogar, Oficina y móviles</li>
                    <li>Correo Electrónico.</li>
                </ol>
                <p><strong>Estos serán utilizados única y exclusivamente para los siguientes fines:</strong></p>
                <ol>
                    <li>Información y Prestación de Servicios.</li>
                    <li>Actualización de la Base de Datos.</li>
                    <li>Cualquier finalidad análoga o compatible con las anteriores.</li>
                </ol>
                    <p><strong>En el caso de Datos sensibles, tales como:</strong></p>
                <ol>
                    <li>Datos Financieros (Ingresos, Estados de Cuenta, y demás relacionados).</li>
                    <li>Datos Patrimoniales (Bienes Materiales, Inmuebles, y demás relacionados).</li>
                    <li>Datos Personales (Cónyuge, Estado Civil, Nacionalidad, Educación, Hijos, y demás relacionados).</li>
                    <li>&nbsp;Referencias familiares y no familiares (Nombre, Dirección, Teléfono, relación, etc.).</li>
                </ol>
                <p><strong>Estos serán utilizados única y exclusivamente para los siguientes fines:</strong></p>
                <ol>
                    <li>Investigación y/u Obtención de Créditos ante las Instituciones Financieras.</li>
                    <li>Cualquier finalidad análoga o compatible con la anterior.</li>
                    <li>Información y Prestación de Servicios.</li>
                </ol>
                <div className="paragraphs">
                    <p>Para prevenir el acceso no autorizado a sus datos personales y con el fin de asegurar que la información sea utilizada para los fines establecidos en este aviso de privacidad, hemos establecido diversos procedimientos con la finalidad de evitar el uso o divulgación no autorizados de sus datos, permitiéndonos tratarlos debidamente. Así mismo, le informamos que sus datos personales pueden ser Transmitidos para ser tratados por personas distintas a esta empresa.</p>
                    <p>Todos sus datos personales son tratados de acuerdo a la legislación aplicable y vigente en el país, por ello le informamos que usted tiene en todo momento los derechos (ARCO) de acceder, rectificar, cancelar u oponerse al tratamiento que le damos a sus datos personales; derecho que podrá hacer valer a través del Área de Privacidad encargada de la seguridad de datos personales en el Teléfono (81) 8675-4333, o por medio de su correo electrónico: atencionaclientesmty@arrendadoradatamovil.com</p>
                    <p>Este aviso de privacidad podrá ser modificado por ARRENDADORA DATAMOVIL S.A. DE C.V., dichas modificaciones serán oportunamente informadas a través de correo electrónico, teléfono, o cualquier otro medio de comunicación que ARRENDADORA DATAMOVIL S.A. DE C.V. determine para tal efecto.</p>
                    <p>A través de estos canales usted podrá actualizar sus datos y especificar el medio por el cual desea recibir información, ya que, en caso de no contar con esta especificación de su parte, ARRENDADORA DATAMOVIL S.A. DE C.V. establecerá libremente el canal que considere pertinente para enviarle información.</p>
                </div>
                <div className="firma">
                    <p style={{textAlign:"center"}}>
                        <strong>ATENTAMENTE</strong>
                        ARRENDADORA DATAMOVIL S.A. DE C.V.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AvisoPrivacidad