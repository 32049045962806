import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Avisos.css';
import AvisoCard from './AvisoCard/AvisoCard';
import { bcAlert } from '../../../boclAlert';
import {RiArrowRightSFill,RiArrowLeftSFill} from 'react-icons/ri'

const Avisos=(props)=>{

    const [avisos, setAvisos] = useState([]);
    const [aviso, setAviso] = useState({
        code: "",
        color: "blue",
        title: "",
        descripcion: "",
        nota: ""
    });

    useEffect(() => { 
    
        let cleanUp = true;

        const getAvisos = () =>{
            // console.log(json)
            const token = localStorage.getItem('token');
            if(props.contrato_meta_cid!==0 && props.contrato_meta_cid!=="0"){
                let json = JSON.stringify({"contrato_meta_cid":props.contrato_meta_cid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/avisos",{"json":json}, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(cleanUp){
                        let result = response.data;
                        if (result.status === 'success') {
            
                            let aclaracion_pago_adicionales = result.data.aviso_aclaracion_pago_adicionales,
                            aclaracion_pago = result.data.aviso_aclaracion_pago,
                            otros_cargos = result.data.aviso_otros_cargos,
                            pago_refrendo = result.data.aviso_pago_refrendo,
                            warning_claves = result.data.aviso_warning_claves,
                            genericos = result.data.avisos_genericos,
                            juridico = result.data.aviso_juridico;
            
                            let array_avisos = [];
            
                            // aclaracion_pago_adicionales = "SI ESTA ES UNA PRREUBA";
                            if(aclaracion_pago_adicionales){
            
                                let r_aviso = aclaracion_pago_adicionales;
                                let newStateAviso = {...aviso};
                                newStateAviso = {
                                    code: "aclaraciones", 
                                    color: "blue", 
                                    title: "Pagos Adicionales", 
                                    descripcion: r_aviso
                                };
                                setAviso(newStateAviso);
                                
                                array_avisos.push(newStateAviso);
                            }
                            if (pago_refrendo != null) {
            
                                let r_aviso = pago_refrendo;
                                let newStateAviso = {...aviso};
                                newStateAviso = {
                                    code: "refrendo", 
                                    color: "yellow", 
                                    title: "Pago de Refrendo", 
                                    descripcion: <p dangerouslySetInnerHTML={{__html: r_aviso}}></p>
                                };
                                setAviso(newStateAviso);
                                
                                array_avisos.push(newStateAviso);
                            }
                            // juridico = "Se tiene un pendiente con el departamento de cobranza, favor comuniquense con los compañeros de arrendadora";
                            if (juridico != null) {
            
                                let r_aviso = juridico;
                                let newStateAviso = {...aviso};
                                newStateAviso = {
                                    code: "juridico", 
                                    color: "red", 
                                    title: "Tiene un pendiente", 
                                    descripcion: r_aviso
                                };
                                setAviso(newStateAviso);
                                
                                array_avisos.push(newStateAviso);                   
                            }
                            // aclaracion_pago = "Debido a un pendiente su contrato no genera claves en automatico."
                            if (aclaracion_pago != null) {
            
                                let r_aviso = aclaracion_pago;
                                let newStateAviso = {...aviso};
                                newStateAviso = {
                                    code: "condicion_clave", 
                                    color: "sky", 
                                    title: "Aclaración De Pago", 
                                    descripcion: r_aviso
                                };
                                setAviso(newStateAviso);
                                
                                array_avisos.push(newStateAviso);              
                            }
                            if (otros_cargos != null) {
                                for (let i = 0; i < otros_cargos.length; i++) {
                                    if(otros_cargos[i].descripcion.includes("Ano")){
                                        otros_cargos[i].descripcion = otros_cargos[i].descripcion.replace("Ano", "Año");
                                    }
                                    if(otros_cargos[i].nota.includes("Ano")){
                                        otros_cargos[i].nota = otros_cargos[i].nota.replace("Ano", "Año");
                                    }
                                    if(otros_cargos[i].nota){
                                        let newStateAviso = {...aviso};
                                        newStateAviso = {
                                            code: "otro_cargo", 
                                            color: "purple", 
                                            title: "Otros Cargos", 
                                            descripcion: otros_cargos[i].descripcion+" $"+otros_cargos[i].total+" "+otros_cargos[i].fecha_vencimiento,
                                            nota: otros_cargos[i].nota
                                        };
                                        setAviso(newStateAviso);
                                        array_avisos.push(newStateAviso);
                                    }else{
                                        let newStateAviso = {...aviso};
                                        newStateAviso = {
                                            code: "otro_cargo", 
                                            color: "purple", 
                                            title: "Otros Cargos", 
                                            descripcion: otros_cargos[i].descripcion+" "+otros_cargos[i].total+" "+otros_cargos[i].fecha_vencimiento
                                        };
                                        setAviso(newStateAviso);
                                        array_avisos.push(newStateAviso);
                                    }
                                }       
                            }
                            // warning_claves = "Aguas su clave esta siendo wachada, debe de presentarse a las oficinas con su comprobante para solucionar los pendientes";
                            if (warning_claves != null) {
            
                                let r_aviso = warning_claves;
                                let newStateAviso = {...aviso};
                                newStateAviso = {
                                    code: "condicion_clave", 
                                    color: "red", 
                                    title: "Clave Condicionada", 
                                    descripcion: r_aviso
                                };
                                setAviso(newStateAviso);
                                
                                array_avisos.push(newStateAviso);       
                                
                            }
                            if (genericos != null) {
            
                                let r_aviso = warning_claves;
                                let newStateAviso = {...aviso};
                                newStateAviso = {
                                    code: "aviso", 
                                    color: "green", 
                                    title: "Aviso", 
                                    descripcion: r_aviso
                                };
                                setAviso(newStateAviso);
                                
                                array_avisos.push(newStateAviso);     
                                
                            }
            
                            //SE AGREGA EL ARRAY AL STATE PARA RENDERIZARLO
                            let newData = [...avisos];
                            newData = array_avisos;
                            setAvisos(newData);
                            // console.log(newData)
            
                        }else{
                            //msg error
                        }
                    }
                })
                .catch(function (error) {
        
                    // alert("Hubo un problema al consultar la información");
                    bcAlert({
                        msg : {
                            "title":"Oops!", 
                            "desc":"Hubo un problema al consultar la información"
                        },
                        type :"error", 
                        styles : ``, 
                        options : false
                    });
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }

        getAvisos();

        return () => {
            // cancel the subscription
            cleanUp = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contrato_meta_cid]);

    const goRight = () => {
        let widthItem = document.querySelector(".album-item").offsetWidth;
        let album = document.getElementById("album-rotator");
        var pos = album.scrollLeft + widthItem + 23;
        album.scroll({
            top: 0, 
            left: pos, 
            behavior: 'smooth'
          });
    }

    const goLeft = () => {
        let widthItem = document.querySelector(".album-item").offsetWidth;
        let album = document.getElementById("album-rotator");
        var pos = album.scrollLeft - widthItem -23;
        if(pos <0){
            pos = 0;
        }
        album.scroll({
            top: 0, 
            left: pos, 
            behavior: 'smooth'
          });
    }

    return (
        <div id="div_avisos" className="corusel-anuncios-avisos title-header">
            {
                avisos.length !== 0 ?
                <>
                    <h5>Avisos</h5>
                    {
                        avisos.length > 1 ?
                        <>
                            {/* <div className="arrow-left" onClick={() => {goLeft()}}>&lt;</div> */}
                            <div className="arrow-left" onClick={() => {goLeft()}}><RiArrowLeftSFill/></div>
                            <div className="arrow-right" onClick={() => {goRight()}}><RiArrowRightSFill/></div>
                        </>
                        :
                        <></>
                    }
                    <div id="album-rotator">
                        <div id="album-rotator-holder"> 
                                {
                                    avisos.map((print_aviso, i) => {     
                                        // console.log(avisos.length);
                                            // Return the element. Also pass key     
                                            return (
                                                <AvisoCard
                                                    key={i}
                                                    color={print_aviso.color}
                                                    title={print_aviso.title}
                                                    code={print_aviso.code}
                                                    desc={print_aviso.descripcion}
                                                />
                                            ) 
                                    })
                                }
                        </div>
                    </div>
                </>
                :
                <></>
            }
        </div>
    )
}

export default Avisos