import React from 'react';
import ImageReveal from '../../../generales/ImageReveal/ImageReveal';
import './Filosofia.css'

function Filosofía() {
    return (
        <div className="filosofia__section"> 
        <ImageReveal 
            twoSlides={false} 
            bgImgTopBool={false}
            bgImgBottomBool={false}
            // bgImgBottom={"url('images/nosotros/banner2.jpg')"}
            bgColorBottom={"#01468b"}
            div_height={"15rem"}
            div_width={"100%"}
            mobile={1}
            text={true}
            text_title={"Arrendadora Datamovil"}
            text_cont={"Empresa exitosamente dedicada al Financiamiento Automotriz con más de 20 años de experiencia, habiendo financiado a la fecha más de 17,000 vehículos en Monterrey y su área metropolitana."}
         />
        {/* second-line *************************************************/}
        <ImageReveal 
            twoSlides={false} 
            bgImgTopBool={false}
            bgImgBottomBool={false}
            bgColorBottom={"#1A2D42"}
            div_height={"16rem"}
            div_width={"33.33%"}
            mobile={1}
            text={true}
            text_title={"Visión"}
            text_cont={"Ser la empresa líder en México en la venta de autos seminuevos, teniendo como plataforma la apertura de sucursales propias o franquicias en toda la república."}
         />
         {/* <ImageReveal
            twoSlides={false} 
            bgImgTopBool={false}
            bgImgBottomBool={false}
            bgColorBottom={"#1A2D42"}
            // bgImgTop={"url('images/nosotros/banner2bw.jpg')"}
            // bgImgBottom={"url('images/nosotros/banner2.jpg')"}
            div_height={"16rem"}
            mobile={0}
            div_width={"33.33%"}
            text={false}
         /> */}
         <ImageReveal 
            twoSlides={false} 
            bgImgTopBool={false}
            bgImgBottomBool={false}
            bgColorBottom={"#048ec2"}
            div_height={"16rem"}
            div_width={"33.33%"}
            mobile={1}
            text={true}
            text_title={"Misión"}
            text_cont={"Ofrecemos los autos seminuevos con garantía más confiables del mercado, a través de los esquemas de financiamiento más accesibles, respaldados por nuestro servicio, experiencia y profesionalismo. Manteniendo sana la economía de nuestros clientes."}
         />
         {/* <ImageReveal
            twoSlides={false} 
            bgImgTopBool={false}
            bgImgBottomBool={false}
            // bgImgTop={"url('images/nosotros/banner2bw.jpg')"}
            // bgImgBottom={"url('images/nosotros/banner2.jpg')"}
            bgColorBottom={"#1A2D42"}
            div_height={"16rem"}
            div_width={"33.33%"}
            mobile={0}
            text={false}
         /> */}
         <ImageReveal 
            twoSlides={false} 
            bgImgTopBool={false}
            bgImgBottomBool={false}
            bgColorBottom={"#1A2D42"}
            div_height={"16rem"}
            div_width={"33.33%"}
            mobile={1}
            text={true}
            text_title={"Valores"}
            text_cont={"Confianza, Honestidad, Servicio de Calidad,  Actitud Positiva, Colaboración, Comunicación"}
         />
          {/* third-line *************************************************/}
         {/* <ImageReveal 
            twoSlides={false} 
            bgImgTopBool={false}
            bgImgBottomBool={false}
            bgColorBottom={"#01468b"}
            div_height={"16rem"}
            div_width={"33.33%"}
            mobile={1}
            text={true}
            text_title={"Visión"}
            text_cont={"Ser la empresa líder en México en la venta de autos seminuevos, teniendo como plataforma la apertura de sucursales propias o franquicias en toda la república."}
         />
         <ImageReveal
            twoSlides={false} 
            bgImgTopBool={false}
            bgImgBottomBool={false}
            // bgImgTop={"url('images/nosotros/banner2bw.jpg')"}
            // bgImgBottom={"url('images/nosotros/banner2.jpg')"}
            bgColorBottom={"#1A2D42"}
            div_height={"16rem"}
            div_width={"33.33%"}
            mobile={0}
            text={false}
         />
         <ImageReveal 
            twoSlides={false} 
            bgImgTopBool={false}
            bgImgBottomBool={false}
            bgColorBottom={"#01468b"}
            div_height={"16rem"}
            div_width={"33.33%"}
            mobile={1}
            text={true}
            text_title={"Valores"}
            text_cont={"Confianza, Honestidad, Servicio de Calidad,  Actitud Positiva, Colaboración, Comunicación"}
         /> */}
        </div>
    )
}

export default Filosofía