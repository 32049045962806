import React from 'react';
// import axios from 'axios';
// import './ContratoCard.css';

const ClaveCard=(props)=>{
    return (
        <div className="resumenC__datos resumenC__ultimo-deposito">
            <h2 style={{color: "rgb(53, 124, 206)", textShadow: "1px 1px 4px rgba(0,0,0,.05)"}}>Último Depósito</h2>
            <p>Fecha: <span>{props.ultimoDep.fecha}</span></p>
            <p>Monto: <span>$ {props.ultimoDep.monto}</span></p>
            <div className="resumenC__clave">
                <ul>
                {props.claveStatus === "Nueva" ?
                    (
                        <React.Fragment>
                        <div className="vencida resumenC__clave-num">
                            <li id="entregar_clave" onClick={() => props.entregarClave()}>Ver clave nueva</li>
                        </div>
                        </React.Fragment>
                    )
                    :
                    props.claveStatus==="Vencida" ?
                        (
                            <React.Fragment>
                            <div className="vencida resumenC__clave-num">
                                <li>Vencida</li>
                            </div>
                            </React.Fragment>
                        )
                        :
                        props.claveStatus === "NoDisponible" || props.claveStatus === "NoHay" ?
                            (
                                <React.Fragment>
                                <div className="sinClave resumenC__clave-num">
                                    <li>Clave No Disponible</li>
                                </div>
                                </React.Fragment>
                            )
                            :
                            props.claveStatus === "Cargando" ?
                                (
                                    <React.Fragment>
                                    <div className="sinClave resumenC__clave-num">
                                        <li>Cargando...</li>
                                    </div>                                       
                                    </React.Fragment>
                                )
                                :
                                (
                                    <React.Fragment>
                                    <li className="clave-big">Inicio</li>
                                    <div className="resumenC__clave-num">
                                        {props.clave.numero.map((answer, i) => {     
                                                // Return the element. Also pass key     
                                                return (<li key={i}>{answer}</li>) 
                                            })
                                        }
                                    </div>      
                                    <li className="clave-big">Datamovil</li>                                        
                                    </React.Fragment>
                                )
                }
                </ul>
            </div>
            <p>Vence el: <span>{props.clave.fecha}</span></p>
            <p>Dias vigentes: <span>{props.clave.dias_vigentes}</span></p>
        </div>
    )
}

export default ClaveCard