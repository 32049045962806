import React from 'react'
// import "./Cotizador.css"
// import ReactGA from 'react-ga';
import Calculadora from './Componentes/Calculadora/Calculadora';

const Cotizador = () => {

  const bgImage = "images/car_bg.png"

  return (
    <div className="section-cotizador" style={{ backgroundImage: 'url(' + bgImage + ')' }}>
      <div className='cotizador_page_container'>
        <Calculadora></Calculadora>
      </div>
    </div>
  )
}

export default Cotizador