import React, { useState } from 'react';
// import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
// import { FaBars, FaTimes} from 'react-icons/fa'
// import { Button } from '../../generales/Button';
import './Navbar_portal.css';
import {IconContext} from 'react-icons/lib'
import {TiHome} from 'react-icons/ti'

function NavbarPortal(props) {
    
    const [click, setClick] = useState(false);

    const closeMobileMenu = () => setClick(false);
    // let name = props;

    return (
        <React.Fragment>
        <IconContext.Provider value={{ color: '#fff'}} >
            <div className="navbar_portal__">
                <div className="navbar_portal__container">
                    <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="logo" className="logo-datamovil-bar"/>
                    <ul className={click ? 'navbar_portal__nav-menu active' : 'navbar_portal__nav-menu'}>
                        <li>
                            <p id="navBar_nombre_usuario"style={{color:"white", marginRight:".75rem"}}>{props.user}</p>
                        </li>
                        <li className="navbar_portal__nav-item">
                            <Link to="/portal/" className="navbar_portal__nav-icons" onClick={closeMobileMenu}>
                                <TiHome className="navbar_portal__nav-icon"/>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            </IconContext.Provider>
        </React.Fragment>
    )
}

export default NavbarPortal