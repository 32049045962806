import React, { useState, useEffect } from 'react';
// import {Button} from '../Button'
import {Link} from 'react-router-dom';
import {FaAngleLeft} from 'react-icons/fa'
import axios from 'axios';
import './SeguroAuto.css';
import {BiCheckShield, BiShieldX} from 'react-icons/bi';
import {HiOutlineShieldExclamation} from 'react-icons/hi';
import {AiFillPhone} from 'react-icons/ai'

export default function SeguroAuto({
    contrato_metacid
}) {
    const [datos, setDatos] = useState([]);
    const [datosFlag, setDatosFlag] = useState({
        status: 0,
        descripcion: "Cargando información"
    });
    const [days, setDays] = useState(0);

    // console.log(contrato_metacid);

    useEffect(() => { 

        let cleanUp = true;

        const getSeguroAuto = () => {
            if(contrato_metacid !== 0 && contrato_metacid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_metacid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/seguro_automotriz",{"json":json}, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(cleanUp){
                        // console.log(response);
                        let status = response.data.status;
                        // return(console.log(data[0]))
                        
                        if(status === "success" ){
                            let newFlag = {...datosFlag};
                            newFlag = {status: 1, descripcion: "Consulta exitosa"};
                            setDatosFlag(newFlag);
        
                            let data = response.data.datos_seguro;
        
                            
                            let r_datos = data[0];
        
                            let dias_disp = parseInt(r_datos.Dias_Disponibles);
                            // dias_disp = 21
                            if(dias_disp <= 3){
                                //VENCIDO
                                let newDay = {...days};
                                newDay = 0;
                                setDays(newDay);
                            }else if(dias_disp <= 15){
                                //POR VENCER
                                let newDay = {...days};
                                newDay = 2;
                                setDays(newDay);
                            }else{
                                //ACTIVO
                                let newDay = {...days};
                                newDay = 1;
                                setDays(newDay);
                            }
        
                            let newData = {...datos};
                            newData = r_datos;
                            setDatos(newData);
                            // console.log(r_datos)
        
                        }else{
                            let newFlag = {...datosFlag};
                            if(response.data.message && response.data.message.includes("pendiente")){
                                newFlag = {status: 2, descripcion: response.data.message};
                                setDays(0);
                            }else{
                                newFlag = {status: 2, descripcion: "No cuentas con un seguro vigente"};
                                setDays(1);
                            }
                            setDatosFlag(newFlag);

                        }
                    }
                })
                .catch(function (error) {
    
                    let newData = {...datosFlag};
                    newData = {status: 2, descripcion: 'No se pudo consultar la información'};
                    setDatosFlag(newData);
                    // console.log(datosFlag)
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }
        
        getSeguroAuto(contrato_metacid);

        return () => {
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_metacid]);
    

    return (
        <React.Fragment>
            <div className={'option-section'} style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                <div className="option-container">
                    <div className="option-title">
                        <Link to="/portal" className="opt-goBack" >
                            <FaAngleLeft className="go-back-icon"/>
                        </Link>
                        <h1>Seguro Automotriz</h1>
                    </div>
                    <div className="option-content">
                        <div className="seguro-container">
                            {
                                datosFlag.status === 1 ?
                                    <React.Fragment>
                                    <div className="seg-part">
                                    {
                                        days === 1 ?
                                        <div className="seg-card seg-status seg-green">
                                            <div className="seg-shield">
                                                <BiCheckShield className="shield-icon"/>
                                            </div>
                                            <div className="seg-status-content">
                                                <p>Quedan</p>
                                                <p className="seg-dias">{datos.Dias_Disponibles} dias</p>
                                            </div>
                                        </div>
                                        :
                                            days === 2 ?
                                            <div className="seg-card seg-status seg-yellow">
                                                <div className="seg-shield">
                                                    <HiOutlineShieldExclamation className="shield-icon"/>
                                                </div>
                                                <div className="seg-status-content">
                                                    <p>Quedan</p>
                                                    <p className="seg-dias">{datos.Dias_Disponibles} dias</p>
                                                </div>
                                            </div>
                                            :
                                            <div className="seg-card seg-status seg-red">
                                                <div className="seg-shield">
                                                    <BiShieldX className="shield-icon"/>
                                                </div>
                                                <div className="seg-status-content">
                                                    <p>Quedan</p>
                                                    <p className="seg-dias">{datos.Dias_Disponibles} dias</p>
                                                </div>
                                            </div>
                                                

                                    }
                                    <div className="seg-card seg-data">
                                        <div className="seguro-company">
                                            <h1>{datos.categoria_valor}</h1>
                                        </div>
                                        <div className="seg-data-content">
                                            <div className="form-group">
                                                <p>Póliza</p>
                                                <span>{datos.seguro_poliza}</span>
                                            </div>
                                            <div className="form-group">
                                                <p>Inicio</p>
                                                <span>{datos.fecha_inicio}</span>
                                            </div>
                                            <div className="form-group">
                                                <p>Fin</p>
                                                <span>{datos.fecha_fin}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="seg-part">
                                    <div className="seg-card seg-contact">
                                        <div className="seg-tel">
                                            <p>Teléfono</p>
                                            <span>
                                                {
                                                    datos.telefono ?
                                                    datos.telefono
                                                    :
                                                    "No Disponible"
                                                }
                                            </span>
                                        </div>
                                        {
                                            datos.telefono ?
                                            <div className="seg-btn sh-skyblue">
                                                <AiFillPhone className="seg-tel-icon"/>
                                            </div>
                                            :
                                            <div className="seg-btn sh-offblue">
                                                <AiFillPhone className="seg-tel-icon-off"/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                </React.Fragment>
                                :
                                days === 1 ?
                                    <React.Fragment>
                                        <div className="seg-card seg-status seg-red">
                                            <div className="seg-shield">
                                                <BiShieldX className="shield-icon"/>
                                            </div>
                                            <div className="seg-status-content">
                                                <p></p>
                                                <p className="seg-dias">{"Sin seguro"}</p>
                                            </div>
                                        </div>
                                        <div className="dep__card dep__card-last10">
                                            <div className="seguro_card-empty">
                                                <p>{datosFlag.descripcion}</p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="dep__card dep__card-last10">
                                            <div className="seguro_card-empty">
                                                <p>{datosFlag.descripcion}</p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}