import React, { useState } from 'react';
import { Button } from './Button';
import axios from 'axios';
import './ContactForm.css'
import ReCAPTCHA from 'react-google-recaptcha'
import { bcAlert } from '../boclAlert';
import ReactGA from 'react-ga';

const ContactForm=()=>{

  /*--Declaration of INPUTS VARIABLES
    ---------correo (string)
    ---------password (string)
    ---------user (object)
    -------Brandon Cantu
    ------------*/
    const [newMensaje, setNewMensaje] = useState({
      tel: "",
      email:"",
      asunto:"",
      nombre: "",
      mensaje:""
    });
    const [token, setToken] = useState("");
    const {tel="", email="", asunto="", nombre="", mensaje=""} = newMensaje;

    const setTokenCaptcha = (value) => {
      setToken(value);
    }
    
    const sendMensaje = () =>{
      if(tel === '' || tel.length<10){
          //alert('Debes ingresar un teléfono')
          bcAlert({
              msg : {
                  "title":"Opps!", 
                  "desc":"Debes ingresar un teléfono de 10 dígitos"
              },
              type :"warning", 
              styles : `margin-left: -1rem;`, 
              options : false
          });
          return false;
      }
      else if(email === '' || email.length<=0){
          // alert('Debes ingresar un email')
          bcAlert({
              msg : {
                  "title":"Opps!", 
                  "desc":"Debes ingresar un email"
              },
              type :"warning", 
              styles : ``, 
              options : false
          });
          return false;
      }
      else if(asunto === '0' || asunto === '' || asunto.length<=0){
          // alert('Debes ingresar un asunto')
          bcAlert({
              msg : {
                  "title":"Opps!", 
                  "desc":"Debes ingresar un asunto"
              },
              type :"warning", 
              styles : ``, 
              options : false
          });
          return false;
      }
      else if(nombre === '0' || nombre === '' || nombre.length<=0){
          bcAlert({
              msg : {
                  "title":"Opps!", 
                  "desc":"Debes ingresar un nombre"
              },
              type :"warning", 
              styles : ``, 
              options : false
          });
          return false;
      }
      else if(mensaje === '' || mensaje.length<=0){
          //alert('Debes ingresar un mensaje')
          bcAlert({
              msg : {
                  "title":"Opps!", 
                  "desc":"Debes ingresar un mensaje"
              },
              type :"warning", 
              styles : ``, 
              options : false
          });
          return false;
      }else if(token === "" || token === null){
        //alert('Debes ingresar un mensaje')
        bcAlert({
            msg : {
                "title":"Opps!", 
                "desc":"El captcha es necesario"
            },
            type :"warning", 
            styles : ``, 
            options : false
        });
        return false;
    }

      //ENVIAMOS TRACKING INFO
      ReactGA.event({
        category: 'Boton Landing',
        action: 'Mensaje Contacto'
      });
      //DESABILITAMOS EL BOTON
      let btn = document.getElementById("btnContactoLanding");
      btn.disabled = true;
      btn.classList.add("btn-blue-disabled")
      //SET LOADER
      //disapper content
      let ogInner = btn.innerHTML;
      let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg" style="margin-top: -1.6rem;"></img>`;
      btn.innerHTML = loaderHtml;

      let json = JSON.stringify({
        "telefono": tel,
        "email": email,
        "asunto": asunto,
        "nombre": nombre,
        "mensaje": mensaje,
        "token_captcha": token,
      });
      //console.log(json)
      axios.post(process.env.REACT_APP_API_DM_URL+"/api/enviar_correo_contacto_landing",{"json":json}, {
          headers: {
          // 'Authorization' : token,
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
          }
      })
      .then(response => {

          btn.disabled = false;
          btn.classList.remove("btn-blue-disabled")
          btn.innerHTML = ogInner;
          let result = response.data;
          // console.log(response); return;
          if (result.status === 'success') {

              bcAlert({
                  msg : {
                      "title":"Mensaje enviado!", 
                      "desc":"Uno de nuestros operadores se comunicará contigo"
                  },
                  type :"success", 
                  styles : ``, 
                  options : false
              });
              resetForm();
      
              }else if(result.status === 'error'){
                  bcAlert({
                      msg : {
                          "title":"Opps!", 
                          "desc":"Hubo un problema al enviar tu correo, inténtalo más tarde."
                      },
                      type :"error", 
                      styles : ``, 
                      options : false
                  });
              }else{
                  bcAlert({
                      msg : {
                          "title":"Opps!", 
                          "desc":"Inténtalo más tarde"
                      },
                      type :"error", 
                      styles : ``, 
                      options : false
                  });
              }
      })
      .catch(function (error) {

          bcAlert({
              msg : {
                  "title":"Opps!", 
                  "desc":"Hubo un problema al enviar la información"
              },
              type :"error", 
              styles : ``, 
              options : false
          });
          btn.innerHTML = ogInner;

          if (error.response) {
          // Request made and server responded
          console.log("Error-Response: "+error.response.data);
          console.log("Error-Status: "+error.response.status);
          console.log("Error-Headers: "+error.response.headers);
          } else if (error.request) {
          // The request was made but no response was received
          console.log('Error-Request: '+error.request);
          } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error-Message: '+ error.message);
          }
      });
  }

  const resetForm = () =>{
    document.getElementById("nombre").value = "";
    document.getElementById("asunto").value = "";
    document.getElementById("tel").value = "";
    document.getElementById("email").value = "";
    document.getElementById("mensaje").value = "";
  }

  const onInputChange = e => {
    setNewMensaje({ ...newMensaje, [e.target.name]: e.target.value });
  };

    return(
      <div className="ContactForm">
        <div id="contact-form">
          <div className="form-group">
              <label>Nombre</label>
              <input type="text" className="form-control" id="nombre" name="nombre" value={nombre} onChange={(e) => {onInputChange(e)}} />
          </div>
          <div className="form-group">
              <label>Telefono</label>
              <input type="tel" className="form-control" id="tel" name="tel" maxLength={"10"} value={tel} onChange={(e) => {onInputChange(e)}} />
          </div>
          <div className="form-group">
              <label>Email address</label>
              <input type="email" className="form-control" id="email" name="email" aria-describedby="emailHelp" value={email} onChange={(e) => {onInputChange(e)}} />
          </div>
          <div className="form-group">
              <label>Asunto</label>
              <input type="text" className="form-control" id="asunto" name="asunto" value={asunto} onChange={(e) => {onInputChange(e)}} />
          </div>
          <div className="form-group">
              <label>Message</label>
              <textarea className="form-control" rows="5" id="mensaje" name="mensaje" value={mensaje} onChange={(e) => {onInputChange(e)}} />
          </div>
          <br></br>
          <div className="captcha-div">
            <ReCAPTCHA
              sitekey="6LdZZo4dAAAAAOGKobh6KiSjF3TCTtrw5oWHSNpu"
              onChange={setTokenCaptcha}
            />            
          </div>
          <br></br>
          <Button buttonSize='btn--wide' id={"btnContactoLanding"} buttonColor='orange' onClick={() => {sendMensaje()}}>
            {'Enviar'}
          </Button>
        </div>
      </div>
    );
  }

export default ContactForm;