import React, { useState, useEffect } from 'react'
// import styled from 'styled-components';
import styled from 'styled-components';
// import axios from 'axios';
import './ModalRegistroFiscal.css';
import axios from 'axios';
import {bcAlert} from '../../../boclAlert';
import ReactGA from 'react-ga';
// import {GoBook} from 'react-icons/go'
// import ConfCard from '../../Sidebar/Opciones/Cuenta/ConfCard.js/ConfCard';


const ModalRegistroFiscal = ({estado_modal, cambiarEstadoModal1, props, modal1, cambiarModal1, contrato_meta_cid}) => {
    const [datosFiscalesUsuario, setDatosFiscalesUsuario] = useState({
        calle: "",
        cfdi_descripcion: "",
        cfdi_id: "",
        ciudad: "",
        codigo_postal: "",
        colonia: "",
        estado: "",
        nombre_cliente: "",
        numero_exterior: "",
        regimen_fiscal_descripcion: "",
        regimen_fiscal_id: "",
        rfc: "",
    });
    
    const {nombre_cliente="", rfc="", calle="", colonia="", numero_exterior="", codigo_postal="", ciudad="", estado="", regimen_fiscal_id="", cfdi_id="", constancia_fiscal=""} = datosFiscalesUsuario;
    
    const [datosRegimenFiscal, setRegimenFiscal] = useState([]);
    
    const [datosCFDI, setCFDI] = useState([]);
    
    //POST DATOS FISCALES USUARIO
    useEffect(() => {
        let cleanUp = true;
    
        const getDatosFiscalesUsuario = () => {
            if(contrato_meta_cid !== 0 && contrato_meta_cid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_meta_cid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/datos_fiscales_usuario",{"json":json}, {
                    headers: {
                    'Authorization' : token
                    }
                })
                .then(response => {
                    if(cleanUp){
                        // console.log(response);return;
                        let status = response.data.status;
                        let data = response.data;
                        // setDatosFiscalesUsuario(data.datos_usuario);
    
                        // return(console.log(data))
                        
                        if(status === "success" ){
        
                            let datos_usuario = data.datos_usuario;
                            setDatosFiscalesUsuario(datos_usuario);
                        }else{
                            bcAlert({
                                msg : {
                                    "title":"Opps!", 
                                    "desc":"No hay datos disponibles"
                                },
                                type :"error", 
                                styles : ``, 
                                options : false
                            });
                        }
                    }
                })
                .catch(function (error) {
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }
    
        getDatosFiscalesUsuario(contrato_meta_cid);
    
        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_meta_cid])


    //MOSTRAR MODAL
    useEffect(() => {
        let cleanUp = true;
        const mostrar_modal = () => {
            if(contrato_meta_cid !== 0 && contrato_meta_cid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_meta_cid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/generar_modal",{"json":json}, {
                    headers: {
                    'Authorization' : token
                    }
                })
                .then(response => {

                    // console.log(response.data);return;
                    let status = response.data.status;
                    let data = response.data.message;
                    let code = response.data.code;
                    
                    if(code === 400){
                        document.getElementById("modal_fiscal").style.display = "flex";
                    }else if(code === 200){
                        document.getElementById("modal_fiscal").style.display = "none";
                    }
                })
                .catch(function (error) {
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }

        mostrar_modal(contrato_meta_cid);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_meta_cid])
    
    //POST DATOS REGIMEN FISCAL
    useEffect(() => {
        let cleanUp = true;
    
        const getDatosRegimenFiscales = () => {
            if(contrato_meta_cid !== 0 && contrato_meta_cid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_meta_cid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/cargar_datos_regimen_fiscal",{"json":json}, {
                    headers: {
                    'Authorization' : token
                    }
                })
                .then(response => {
                    if(cleanUp){
                        // console.log(response);return;
                        let status = response.data.status;
                        let data = response.data;
                        // setDatosFiscalesUsuario(data.datos_usuario);
    
                        // return(console.log(data));
                        
                        if(status === "success" ){
        
                            let datos_regimen_fiscal = data.datos_regimen_fiscal;
    
                            if(datos_regimen_fiscal.length > 0){
                                let newData = datosRegimenFiscal;
                                newData = datos_regimen_fiscal;
                                setRegimenFiscal(newData);
                            }else{
                                bcAlert({
                                    msg : {
                                        "title":"Opps!", 
                                        "desc":"No se encontraron datos"
                                    },
                                    type :"error", 
                                    styles : ``, 
                                    options : false
                                });
                            }
                        }else{
                            bcAlert({
                                msg : {
                                    "title":"Opps!", 
                                    "desc":"No hay datos disponibles"
                                },
                                type :"error", 
                                styles : ``, 
                                options : false
                            });
                        }
                    }
                })
                .catch(function (error) {
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }
    
        getDatosRegimenFiscales(contrato_meta_cid);
    
        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_meta_cid])
    
    //POST DATOS CFDI
    useEffect(() => {
        let cleanUp = true;
    
        const getDatosRegimenFiscales = () => {
            if(contrato_meta_cid !== 0 && contrato_meta_cid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_meta_cid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/cargar_datos_cfdi",{"json":json}, {
                    headers: {
                    'Authorization' : token
                    }
                })
                .then(response => {
                    if(cleanUp){
                        // console.log(response);return;
                        let status = response.data.status;
                        let data = response.data;
                        // setDatosFiscalesUsuario(data.datos_usuario);
    
                        // return(console.log(data));
                        
                        if(status === "success" ){
        
                            let datos_cfdi = data.datos_cfdi;
    
                            if(datos_cfdi.length > 0){
                                let newData = datosCFDI;
                                newData = datos_cfdi;
                                setCFDI(newData);
                            }else{
                                bcAlert({
                                    msg : {
                                        "title":"Opps!", 
                                        "desc":"No se encontraron datos"
                                    },
                                    type :"error", 
                                    styles : ``, 
                                    options : false
                                });
                            }
                        }else{
                            bcAlert({
                                msg : {
                                    "title":"Opps!", 
                                    "desc":"No hay datos disponibles"
                                },
                                type :"error", 
                                styles : ``, 
                                options : false
                            });
                        }
                    }
                })
                .catch(function (error) {
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }
    
        getDatosRegimenFiscales(contrato_meta_cid);
    
        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_meta_cid])
    
    const onInputChange = e => {

        if (e.target.id == "calle" || e.target.id == "numero_exterior") {
            e.preventDefault()
            e.target.value = e.target.value.replaceAll(/[.*+\-',!¡|°"#%&/=¨´;:_<>¿??^${}()|[\]\\°ÑñáÁéÉíÍóÓúÚ]/g, "")
        }else if (e.target.id == "colonia" || e.target.id == "ciudad" || e.target.id == "estado") {
            e.preventDefault()
            e.target.value = e.target.value.replaceAll(/[.*+\-',!¡|°"#%&/=¨´;:_<>¿??^${}()|[\]\\°ÑñáÁéÉíÍóÓúÚ0123456789]/g, "")
        }else if (e.target.id == "codigo_postal") {
            e.preventDefault()
            e.target.value = e.target.value.replaceAll(/[.*+\-',!¡|°"#%&/=¨´;:_<>¿??^${}()|[\]\\°QqWwEeeRrTtYyUuIiOoPpAaSsDdFfGgHhJjKkLlZzXxCcVvBbNnMmÑñáÁéÉíÍóÓúÚ]/g, "")
        }

        setDatosFiscalesUsuario({ ...datosFiscalesUsuario, [e.target.id]: e.target.value });
    };
    
    const registerDatos = (e) =>{
        debugger;
        e.preventDefault();
        let bandera_actualizar_insertar = 0,
        tipo_dispositivo = "web";
        if(nombre_cliente === ''){
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El Nombre o Razón Social no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(rfc === ''){
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El RFC no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(codigo_postal === ''){
            // alert('Debes ingresar un asunto')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El Código postal no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(colonia === ''){
            // alert('Debes ingresar un departamento')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"La Colonia no debe estar vacía"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(ciudad === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El Municipio no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(calle === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El calle no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(numero_exterior === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El num_ext no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(estado === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El estado no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(datosFiscalesUsuario.regimen_fiscal_id === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El regimen_fiscal no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(datosFiscalesUsuario.cfdi_id === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El cfdi no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(constancia_fiscal === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"La constancia_fiscal no debe estar vacía"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(contrato_meta_cid === ''){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"El contrato metacid no debe estar vacío"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
    
        ReactGA.event({
            category: 'Boton Portal',
            action: 'Guardar Fiscales Inicial'
        });
        //DESABILITAMOS EL BOTON
        let btn = document.getElementById("registrarDatos");
        btn.disabled = true;
        btn.classList.add("btn-blue-disabled");
        //SET LOADER
        //disapper content
        let ogInner = btn.innerHTML;
        let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
        btn.innerHTML = loaderHtml;

        // console.log(e.target);

        let json = JSON.stringify({
            "nombre_razon_social":nombre_cliente, 
            "rfc":rfc, 
            "contrato_meta_cid":contrato_meta_cid, 
            "tipo_dispositivo":tipo_dispositivo, 
            "bandera_actualizar_insertar":bandera_actualizar_insertar
        });
        
        let myForm = document.getElementById('formularo_registrar_datos');
        console.log(myForm);
        let formData = new FormData(myForm);
        console.log(formData);
        formData.append("json", json);
        let data = formData;  
        // return(console.log(formData));
        const token = localStorage.getItem('token');
        axios.post(process.env.REACT_APP_API_DM_URL+"/api/guardar_datos_fiscales",data, {
            headers: {
            'Authorization' : token,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
            }
        })
        .then(response => {
            let result = response.data;
            console.log(result);
            if (result.status === 'success') {
                if (result.code === 200) {
                    bcAlert({
                        msg : {
                            "title":"Tu información se actualizó correctamente!",
                            "desc":""
                        },
                        type :"success", 
                        styles : ``, 
                        options : false
                    });
                    cambiarEstadoModal1(false);
    
                }else if (result.code === 205) {
                    bcAlert({
                        msg : {
                            "title":result.message,
                            "desc":""
                        },
                        type :"success", 
                        styles : ``, 
                        options : false
                    });
                }
    
                // setDatosFiscalesUsuario({calle: "", cfdi_descripcion: "", cfdi_id: "", ciudad: "", codigo_postal: "", colonia: "",
                // estado: "", nombre_cliente: "", numero_exterior: "", regimen_fiscal_descripcion: "", regimen_fiscal_id: "", rfc: ""});
    
                btn.disabled = false;
                btn.classList.remove("btn-blue-disabled")
                btn.innerHTML = ogInner;
        
            }else if(result.status === 'error_validate'){
                let strMsg = "";
                for(let i=0; i<result.message.length; i++){
                    strMsg= strMsg+" "+result.message[i];
                }
                bcAlert({
                    msg : {
                        "title":"Opps!", 
                        "desc":strMsg
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
                btn.disabled = false;
                btn.classList.remove("btn-blue-disabled")
                btn.innerHTML = ogInner;
            }else{
                bcAlert({
                    msg : {
                        "title":"Opps!", 
                        "desc":"Hubo un problema al guardar su información"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
                btn.disabled = false;
                btn.classList.remove("btn-blue-disabled")
                btn.innerHTML = ogInner;
            }
        })
        .catch(function (error) {
    
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"Hubo un problema al actualizar su información"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            btn.disabled = false;
            btn.classList.remove("btn-blue-disabled")
            btn.innerHTML = ogInner;
    
            if (error.response) {
            // Request made and server responded
            console.log("Error-Response: "+error.response.data);
            console.log("Error-Status: "+error.response.status);
            console.log("Error-Headers: "+error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            console.log('Error-Request: '+error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error-Message: '+ error.message);
            }
        });
    }

    return (
        <>
            {estado_modal && 
                <div className="Overlay" id="modal_fiscal" style={{display:"none"}}>
                    <div className="ContenedorModal">
                        <div className="EncabezadoModal">
                            <h3>CONSTANCIA DE SITUACION FISCAL</h3>
                            <div className="BotonCerrar" onClick={() => cambiarEstadoModal1(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                            </svg>
                        </div>
                        </div>
                        <div className="Modal">
                        {modal1 == 1 ?
                            <>
                                <div className="modal-body">
                                    <div className="groupCuenta">
                                        <div className="col-12">
                                            <h4>Estimado Cliente:</h4>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5>Derivado de las reformas fiscales aplicables a la facturación 4.0, se requiere actualizar sus datos de acuerdo a su CONSTANCIA DE SITUACION FISCAL e indicar el uso del CFDI.</h5>
                                            </div>
                                        </div><br/>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5>Su información deberá ser registrada y recibida antes del 15 Junio 2022 para garantizar que su facturación sea emitida con datos actualizados.</h5>
                                            </div>
                                        </div><br/>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5>Su CONSTANCIA DE SITUACION FISCAL, deberá ser enviada completa a través de nuestra aplicación DATAMOVIL GO o correo electrónico: </h5>
                                            </div>
                                        </div><br/>
                                        <div className="row">
                                            <div className='col-12'>
                                                <h5 id="correo_informativo"><b>cedulaidentificacionfiscal@arrendadoradatamovil.com</b></h5>
                                            </div>
                                        </div><br/>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5>En caso de que no se tenga la información solicitada o los datos registrados no coincidan con su CONSTANCIA DE SITUACION FISCAL, se generará factura como público en general.</h5>
                                            </div>
                                        </div><br/>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5>Si su Nombre, RFC o Código Postal no coinciden con su CONSTANCIA DE SITUACION FISCAL, favor de comunicarse a nuestras oficinas Tel 81 8675 4333.</h5>
                                            </div>
                                        </div><br/>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5>Así mismo, en caso de que tribute en más de un Régimen Fiscal de acuerdo a su Constancia de Situación Fiscal favor de confirmar la clave a la cual se le debe facturar.</h5>
                                            </div>
                                        </div><br/>
                                    </div>
                                    <div className="mb-1 h-center" style={{height:"8rem", position:"relative"}}>
                                        <button id="btn_si_acepto" style={{width:"8rem", position:"absolute", bottom:0, right:0}} className="btn-blue " onClick={() => cambiarModal1(2)}>Continuar</button>
                                    </div>
                                </div>
                            </>
                            :
                                <form  id="formularo_registrar_datos">
                                    <div className="groupCuenta">
                                        <label>Nombre:</label>
                                        <div className="form-group">
                                            <label id="nombre_cliente" name="nombre_cliente">{datosFiscalesUsuario.nombre_cliente}</label>
                                        </div>
                                    </div>
                                    <div className="groupCuenta">
                                        <label>RFC:</label>
                                        <div className="form-group">
                                        <label id="rfc" name="rfc">{datosFiscalesUsuario.rfc}</label>
                                        </div>
                                    </div>
                                    <div className="groupCuenta">
                                        <label>Calle</label>
                                        <div className="form-group">
                                            <input type="text" id="calle" name="calle" maxLength={50} placeholder={"Calle"} onChange={onInputChange} value={datosFiscalesUsuario.calle} required></input>
                                        </div>
                                    </div>
                                    <div className="groupCuenta">
                                        <label>Colonia</label>
                                        <div className="form-group">
                                            <input type="text" id="colonia" name="colonia" maxLength={50} placeholder={"Colonia"} onChange={onInputChange} value={datosFiscalesUsuario.colonia} required></input>
                                        </div>
                                    </div>
                                    <div className="groupCuenta">
                                        <label>Número Interior/Exterior</label>
                                        <div className="form-group">
                                            <input id="numero_exterior" name="num_ext" type="text" maxLength={100} placeholder={"Número"} onChange={onInputChange} value={datosFiscalesUsuario.numero_exterior} required></input>
                                        </div>
                                    </div>
                                    <div className="groupCuenta">
                                        <label>C.P:</label>
                                        <div className="form-group">
                                            <input id="codigo_postal" name="domicilio_fiscal" type="text" maxLength={5} placeholder={"Código Postal (Domicilio Fiscal)"} onChange={onInputChange} value={datosFiscalesUsuario.codigo_postal} required></input>
                                        </div>
                                    </div>
                                    <div className="groupCuenta">
                                        <label>Municipio</label>
                                        <div className="form-group">
                                            <input id="ciudad" name="municipio" type="text" maxLength={50} placeholder={"Municipio"} onChange={onInputChange} value={datosFiscalesUsuario.ciudad} required></input>
                                        </div>
                                    </div>
                                    <div className="groupCuenta">
                                        <label>Estado</label>
                                        <div className="form-group">
                                            <input id="estado" name="estado" type="text" maxLength={50} placeholder={"Estado"} onChange={onInputChange} value={datosFiscalesUsuario.estado} required></input>
                                        </div>
                                    </div>
                                    <div className="groupCuenta">
                                        <label>Régimen Fiscal</label>
                                        <div className="selectContacto">
                                            <div className="form-group">
                                                <div className="selectMail">
                                                    <select id="regimen_fiscal_id" name="regimen_fiscal" onChange={onInputChange} value={datosFiscalesUsuario.regimen_fiscal_id} required>
                                                        <option value="" style={{color: "gray"}}>Selecciona una Opción...</option>
                                                        {datosRegimenFiscal.map((drf, i) => {
                                                            return (
                                                                drf.descripcion === datosFiscalesUsuario.regimen_fiscal_descripcion ? 
                                                                <option key={drf.id} value={drf.id}>
                                                                    {drf.descripcion}
                                                                </option>
                                                                :
                                                                <option key={drf.id} value={drf.id}>
                                                                    {drf.descripcion}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="groupCuenta">
                                        <label>Uso CFDI</label>
                                        <div className="selectContacto">
                                            <div className="form-group">
                                                <div className="selectMail">
                                                    <select id="cfdi_id" name="cfdi" onChange={onInputChange} value={datosFiscalesUsuario.cfdi_id} required>
                                                        <option value="" style={{color: "gray"}}>Selecciona una Opción...</option>
                                                        {datosCFDI.map((dcfd, i) => {
                                                            return (
                                                                dcfd.descripcion === datosFiscalesUsuario.cfdi_descripcion ?
                                                                <option key={dcfd.id} value={dcfd.id} selected>
                                                                    {dcfd.descripcion}
                                                                </option>
                                                                :
                                                                <option key={dcfd.id} value={dcfd.id}>
                                                                    {dcfd.descripcion}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="groupCuenta">
                                        <label>Constancia Fiscal</label>
                                        <div className="form-group">
                                            <input id="constancia_fiscal" name="constancia_fiscal" type="file" accept="application/pdf" placeholder={"Constancia Fiscal"} onChange={onInputChange} required></input>
                                        </div>
                                    </div>
                                    <button className="btn-blue" id="registrarDatos" onClick={(e) => {registerDatos(e)}}>
                                        REGISTRAR
                                    </button>
                                </form>
                        }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ModalRegistroFiscal;