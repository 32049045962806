import React from 'react'
import { Link } from 'react-router-dom'

const BtnLink=(props)=>{

    return(
        <Link to={(props.metacid !== 0 && props.metacid !== "0") ? props.enlace : "/portal"} className="button-opciones">
            <div className="opciones_img">
                <div className="opciones_circle sh-dark_blue">
                    {props.icon}
                </div>  
            </div>
            <p className="opciones-links">{props.label}</p>
        </Link>   
    )
}
 
export default BtnLink