import React, { useState, useEffect}from 'react'
import { Grid,Paper, Avatar, TextField, Button, Typography } from '@material-ui/core'
import { NavLink, useHistory } from "react-router-dom";
import axios from 'axios';
import { InputAdornment, IconButton} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import '../../../generales/HeroSection/HeroSection.css';
import '../../../portal/Login/LogSign.css'
import ReactGA from 'react-ga';

export default function LoginHero() {

    /*--STYLES
    -------Uses @material-ui
    -------Brandon Cantu
    ------------*/
    const paperStyle={padding :30,height:'auto',width:480, maxWidth:"90%",margin:"0 auto", paddingTop: '50px'}
    const avatarStyle={backgroundColor:'#3370bd', width: 60, height: 60}
    const btnstyle={margin:'2rem 0'}

    /*VARIABLES AND CONST__________________
    _______________________________________
    _____________________________________*/
    
    /*--Declaration of INPUTS VARIABLES
    ---------correo (string)
    ---------password (string)
    ---------user (object)
    -------Brandon Cantu
    ------------*/
    const [user, setUser] = useState({
      correo: "",
      password:""
    });

    const {correo = "",password = ""} = user;
    
    /*--Declaration Variables
    ---------message (strimg) 
    ---------token (string)
    -------Brandon Cantu
    ------------*/
    const[errors,setErrors] = useState('');
    const[msgColor,setMsgColor] = useState('');


    /*--Declaration of USER
    ---------id cint)
    ---------name (string)
    ---------surname (string)
    -------Brandon Cantu
    ------------*/
    const [userData, setUserData] = useState({
      id: "",
      name: "",
      surname: "",
      verify: ""
    });
 
    /*FUNCIONES__________________
    _____________________________
    ___________________________*/
    let history = useHistory(); 

    //Pintar mensaje de contraseña cambiada
    useEffect(() => {
      
      let url_string = window.location.href;
      let url = new URL(url_string);
      let c = url.searchParams.get("passwordChange");
      if(c){
        setErrors("Contraseña restablecida exitosamente!");
        setMsgColor("green")
      }
    }, []);

    //cleaunup
    useEffect(() => { 
      return () => {
        setUser({}); 
        setErrors(''); 
        setMsgColor(''); 
        setUserData({}); 
      };
    }, []);
 
    const onInputChange = e => {
      setUser({ ...user, [e.target.name]: e.target.value });
    };

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
 
    function redirectToPortal(){
      history.push("/portal");
    }

    const asyncLocalStorage = {
      setItem: function (key, value) {
          return Promise.resolve().then(function () {
              localStorage.setItem(key, value);
          });
      },
      getItem: function (key) {
          return Promise.resolve().then(function () {
              return localStorage.getItem(key);
          });
      }
    };

    function GetUserAccount(user_token) {
      //VAR TO JSON
      let tokenObject = { 
        gettoken: user_token
      }
      let token = JSON.stringify(tokenObject);
      let token_to_send = {"json": token};
      axios.post(process.env.REACT_APP_API_DM_URL+"/api/getUserData",token_to_send, {
        headers: {
        'Authorization' : user_token,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
        }
      })
      .then(response => {

        // console.log(response)
        let status = response.data.status;
        let data = response.data.data;
        
        if(status === "1" || status === 1){

          let identity = data.identity;
          let userIdentity = {
            name : identity.name,
            surname : identity.surname,
            id : identity.id,
            verify: identity.email_verified_at
          }

          setUserData({...userData, id: userIdentity.id, name: userIdentity.name, surname: userIdentity.surname, verify: userIdentity.verify});
          // localStorage.setItem("userIdentity", JSON.stringify(userIdentity));
          asyncLocalStorage.setItem('userIdentity', JSON.stringify(userIdentity)).then(function () {
              return asyncLocalStorage.getItem('userIdentity');
          }).then(function (value) {
              // console.log('Value has been set to:', value);
              redirectToPortal();
          });
          //localStorage.setItem("users",msg); //LocalStorage
        }else{
          //alert(msg);
        }

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Error-Response: "+error.response.data);
          console.log("Error-Status: "+error.response.status);
          console.log("Error-Headers: "+error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error-Request: '+error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error-Message: '+ error.message);
        }
      });
    }
        
    const signIn = () =>
    {
      // const users = { username };  // To Store Email in Localstore and send to Home Page 
 
      if(user.correo === ''){
         console.log(user)
         alert('Email Field is empty')
         setErrors("El correo esta vacío");
         setMsgColor("red")
         return false;
      }else if(user.password === '' && user.password.length <8){
        setErrors("Una contraseña de 8 carácteres es requerida");
        setMsgColor("red")
        return false;
      }

      //ENVIAMOS TRACKING INFO
      ReactGA.event({
        category: 'Boton Landing',
        action: 'Inicio Sesion Home'
      });
      //SET LOADER
      let btnLogin = document.querySelector("#btnLogin .MuiButton-label");
      //disapper content
      let ogInner = btnLogin.innerHTML;
      let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
      btnLogin.innerHTML = loaderHtml;
 
       let user_send = JSON.stringify(user);
       let send = {"json": user_send};

       axios.post(process.env.REACT_APP_API_DM_URL+"/api/login",send)
       .then(response => {

        let status = response.data.status;
        let data = response.data.data;

        let msg = data.message;
        
         if(status === "1" || status === 1){
 
          setErrors(msg);
          setMsgColor("green")

          let tkn = data.token;
          // setTkn(tkn);
          localStorage.setItem("token", tkn);
          //localStorage.setItem("users",msg); //LocalStorage
          GetUserAccount(tkn);
          
          // history.push("/portal");

         }else{

          setErrors(msg);
          setMsgColor("darkorange")
          btnLogin.innerHTML = ogInner;

         }
      })
      .catch(function (error) {
        btnLogin.innerHTML = ogInner;
        setErrors("Hubo un error al iniciar sesión");
        setMsgColor("darkorange")
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
    }
 
    const submitHandle = e => {
        e.preventDefault()
        signIn();
    }

    return (
        <React.Fragment>
            <div id={"loginHero"} className={'home__hero-section darkBg textLeft'}>
                <div className="home__hero-container">
                    <div className="row home__hero-row"
                    style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <div className="col">
                            <div className="home__Hero-text-wraper">
                                <div className="top-line"></div>
                                <h1 className={'heading'} >Servicios en Línea</h1>
                                <p className={'home__hero-paragraph'}>Accede a nuestro portal web y a la aplicación Datamovil Go con las mismas credenciales. Consulta tu Estado De Cuenta, Depósitos, Seguro, etc...</p>
                            </div>
                        </div> 

                        <div className="col textLeft">
                            {/* <Grid className="section-logsign"> */}
                                <form id={"loginForm"} onSubmit={e => submitHandle(e)}>
                                    <Paper elevation={10} style={paperStyle}>
                                        <Grid align='center'>
                                        <Avatar style={avatarStyle}><img src={`images/isotipo_black.svg`} alt={"iso"} style={{width: "40px", height: "40px", filter: "invert(1)"}}/></Avatar>
                                            <h2 style={{marginTop: "1rem"}}>Iniciar Sesión</h2>
                                            <h3 style={{color:msgColor}}>{errors}</h3>
                                        </Grid>
                                        <TextField label='Email'  name="correo" value={correo}  className="login-input" onChange={e => onInputChange(e)} placeholder='Ingrese su correo' type='text' fullWidth required/>
                                        {/* <TextField label='Contraseña'  name="password" value={password}  onChange={e => onInputChange(e)} placeholder='Ingrese su contraseña' type='password' fullWidth required/> */}
                                        <label style={{display: "none"}}></label>
                                        <TextField
                                            id='password'
                                            label='Contraseña'
                                            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                                            onChange={e => onInputChange(e)}
                                            inputProps={{
                                              label: "Contraseña"
                                            }}
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    >
                                                    {showPassword ? <Visibility style={{width: "1.4rem", marginRight:"-.5rem"}}/> : <VisibilityOff style={{width: "1.4rem", marginRight:"-.5rem"}}/>}
                                                    </IconButton>
                                                </InputAdornment>
                                                )
                                            }}
                                            name="password" value={password} fullWidth required
                                            className="login-input"
                                        />
                                    
                                        <a className="forgot_password" href={process.env.REACT_APP_API_DM_URL+"/password/reset"}>
                                          <span>Olvidé mi contraseña</span>
                                        </a>
                    
                                        <Button type='submit' id="btnLogin" color='primary' variant="contained" style={btnstyle} fullWidth>Iniciar Sesión</Button>
                                    
                                        <Typography > No tienes cuenta?
                                        <NavLink to="/signup">
                                        <span style={{marginLeft:"4px"}}>Regístrate</span>
                                        </NavLink>
                                        </Typography>
                                    </Paper>
                                </form>
                            {/* </Grid> */}
                        </div> 

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
