import React from 'react';
// import axios from 'axios';
import './Mail.css';
import {FiMail} from 'react-icons/fi'
import ConfCard from '../../Sidebar/Opciones/Cuenta/ConfCard.js/ConfCard';

const Mail=(props)=>{

    return (
        <ConfCard 
                titulo={"Escríbenos"}
                icon = {<FiMail className="opcion-conf-icon"/>} 
                content={
                    <>
                    <div className="groupCuenta">
                        <label>Teléfono:</label>
                        <div className="form-group">
                            <input type="tel" name="telefono" id="telefono" onChange={(evt) => props.onInputChange(evt)} maxLength={10} placeholder={"Tu numero celular"}></input>
                        </div>
                    </div>
                    <div className="groupCuenta">
                        <label>Email:</label>
                        <div className="form-group">
                            <input type="email" name="email" id="email" onChange={(evt) => props.onInputChange(evt)} maxLength={85} placeholder={"Tu correo electrónico"}></input>
                        </div>
                    </div>
                    <div className="groupCuenta">
                        <label>Asunto</label>
                        <div className="selectContacto">
                            <div className="form-group">
                                {/* <label>Día</label> */}
                                <div className="selectMail">
                                    <select id="selectAsunto" onChange={(evt) => props.onInputChange(evt)} name="asunto">
                                        <option value="" style={{color: "gray"}}>Selecciona un asunto...</option>
                                        <option value="Duda">Duda</option>
                                        <option value="Sugerencia">Sugerencia</option>
                                        <option value="Comentario">Comentario</option>
                                        <option value="Queja">Queja</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="groupCuenta">
                        <label>Departamento</label>
                        <div className="selectContacto">
                            <div className="form-group">
                                {/* <label>Día</label> */}
                                <div className="selectMail">
                                    <select id="selectDpto" onChange={(evt) => props.onInputChange(evt)} name="dpto">
                                        <option value="">Selecciona un departamento...</option>
                                        {
                                            props.unidades_servicio ?
                                            props.unidades_servicio.map((unidad, i) => {     
                                                    // Return the element. Also pass key    
                                                    return (
                                                        <option key={i} value={unidad.tipo_indice}>{unidad.servicio_nombre}</option>
                                                    ) 
                                            })
                                            :
                                            <option value={0}>{"Cargando datos..."}</option>
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="groupCuenta">
                        <label>Mensaje:</label>
                        <div className="form-group">
                            <textarea name="mensaje" id="mensaje" onChange={(evt) => props.onInputChange(evt)}></textarea>
                        </div>
                    </div>
                    <button className="btn-blue" id="enviarCorreoContacto" onClick={() => {props.sendMensaje()}}>
                        ENVIAR
                    </button>
                    </>
                }
            />
    )
}

export default Mail