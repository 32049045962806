import React from 'react';
import "./HeaderCotizador.css"

const HeaderCotizador=({
    resultado,
    message,
    plazoText
})=>{

    return (
        resultado ? 
        <div className='formHeaderCotizador'>
            <h2 className='headerTopTitle'>Calculadora de crédito</h2>
            <div className='headerTitle' id='headerTitle'>
              <h3 id="montoPlazo"><p>$</p>{resultado}</h3><span>{plazoText}</span>
            </div>
        </div>
        :
        <div className='formHeaderCotizador'>
            <h2 className='headerTopTitle'>Calculadora de crédito</h2>
            <div className='headerTitle' id='headerTitle'>
              <h3 id="montoPlazo" className="errorCalculo-heading">{message}</h3>
            </div>
        </div>
    )
}

export default HeaderCotizador