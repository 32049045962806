import React, {useEffect} from 'react';
import HeroSection from '../../generales/HeroSection/HeroSection'
// import {homeObjOne, homeSolicitudCredito} from './Data_home'
import {homeSolicitudCredito} from './Data_home'
import Slider from './Carousel/Slider'
import {SliderData } from './Carousel/SliderData'
import GallerySlider from './SucursalSlider/GallerySlider';
import GoogleApiWrapper from '../../generales/MapSection/MapSection';
import {bcModal} from '../../boclModal';
import LoginHero from './LoginHero/LoginHero';
import ReactGA from 'react-ga';
import CotizadorHero from './CotizadorHero/CotizadorHero';

function Home() {

    useEffect(()=> {

        let btn = document.getElementById("modal_Solicitud");
        btn.addEventListener("click", displayModal);

        //FUNCION DE MODAL PARA DOCUMENTOS EXTRAS
        function displayModal(){
            // bcModal({
            //     msg : {
            //         "title":"", 
            //         "desc":"Buró de Crédito"
            //     },
            //     type :"warning", 
            //     styles : ``, 
            //     content : `
            //         <div class="boclModalButtonsDiv">
            //             <a target="_blank" rel="noopener noreferrer" href="/docs/credito_persona_moral_total_Buro.pdf">
            //                 <button class="blue2 btn--primary btn--medium" id="buroMoral">Persona Moral</button>
            //             </a>
            //             <a target="_blank" rel="noopener noreferrer" href="/docs/credito_persona_fisica_total_Buro.pdf">
            //                 <button class="blue2 btn--primary btn--medium" id="buroFisica">Persona Física</button>
            //             </a>
            //         </div>`
            // });

            // ReactGA.event({
            //     category: 'Button Landing',
            //     action: 'Documentos Adicionales'
            // });
        }

        let btn2 = document.getElementById("btn_PersonaMoral");
        btn2.addEventListener("click", sendTrackingInfo);
        btn2.myParam = 'Doc Moral';

        let btn3 = document.getElementById("btn_PersonaFisica");
        btn3.addEventListener("click", sendTrackingInfo);
        btn3.myParam = 'Doc Fisica';

        function sendTrackingInfo(actionName){
            // alert(actionName.currentTarget.myParam);
            ReactGA.event({
                category: 'Button Landing',
                action: actionName.currentTarget.myParam
            });
        }

    }, []);

    useEffect(() => { 
        // document.querySelector("#cotizador_img svg #layer4").classList.add("animation_jelly");
        // document.querySelector("#cotizador_img svg #layer4").classList.add("animation_verticalsqueesh");
        // document.querySelector("#cotizador_img svg #layer1").classList.add("animation_shorttempo");
        // document.querySelector("#cotizador_img svg #layer3").classList.add("animation_verticalsqueesh");
        // document.querySelector("#cotizador_img svg #layer2").classList.add("animation_slowhorizontalflow");
    }, []);

    return (
        <React.Fragment>
            <div id="homePage" className="referenceDivTag"></div>
            <Slider slides={SliderData} />
            {/* <HeroSection {...homeObjOne} /> */}
            <LoginHero />
            <GallerySlider />
            <HeroSection {...homeSolicitudCredito} />
            {/* <HeroSection {...homeCotizador} /> */}
            <CotizadorHero />
            <GoogleApiWrapper />
        </React.Fragment>
    )
}
export default Home
