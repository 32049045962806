import React from 'react'
import './Card.css';
import {FaMapMarkerAlt} from 'react-icons/fa'
import ReactGA from 'react-ga';

export default function HeroSection({
    titulo,
    subtitulo,
    parrafo,
    img,
    link,
    active = false,
    track = false
    
}) {

    const trackAction = (actionName) => {
        // alert(actionName)
        ReactGA.event({
            category: 'Link Landing',
            action: actionName
        });
    }

    return (
            <div className="card-wrapper">
                <div className={ active ? "card card_active" : "card"}>
                    <div className="card-image">
                        {
                            link ?
                            <a href={link} target="blank" className="maps-link" aria-label={"map-" + titulo}>
                                {track ?
                                    <button className="buttonTrackingSucursal" id={"mapSuc-" + img} onClick={() => trackAction('Maps Sucursales')}></button>
                                    :
                                    <></>
                                }
                                <FaMapMarkerAlt />
                            </a>
                            :
                            <></>
                        }
                        <img src={img} alt={img}/>
                    </div>
                    {
                        titulo ?
                        <div className="card-title">
                            <h1>{titulo}</h1>
                        </div>
                        :
                        <></>
                    }
                    <div className="details">
                        <h2>{subtitulo}
                            <span className="job-title">
                                {parrafo}
                            </span>
                        </h2>
                    </div>
                </div>
            </div>
    )
}

