import React, {useState, useEffect, useLayoutEffect} from 'react';
import Card from '../../generales/Card'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeroSection from '../../generales/HeroSection/HeroSection';
import {homeObjOne, card} from './Data'
import Slider from 'react-slick';
import ReactGA from 'react-ga';

function MobileApp() {

    function useWindowSize() {
        const [size, setSize] = useState(0);
        useLayoutEffect(() => {
          function updateSize() {
            setSize(window.innerWidth);
          }
          window.addEventListener('resize', updateSize);
          updateSize();
          return () => window.removeEventListener('resize', updateSize);
        }, []);
        if(size <=540){
            return 2
        }
        if(size <= 760){
            return 3
        }
        if(size <= 1120){
            return 4
        }
        if(size > 1120){
            return 5
        }
        // return size;
    }

    useEffect(() => {
        
        let android = document.getElementById("btnHeroAndroid");
        android.innerHTML = `<img src="images/down_playstore.png" alt="down_playstore" class="hero-app-btn"/>`;
        android.addEventListener("click", sendTrackingInfo);
        // android.myParam = 'Link Celulares';

        let ios = document.getElementById("btnHeroIos");
        ios.innerHTML = `<img src="images/down_appstore.png" alt="down_appstore" class="hero-app-btn"/>`;
        ios.addEventListener("click", sendTrackingInfo);
        // ios.myParam = 'Link Celulares';

        let huawei = document.getElementById("btnHeroHuawei");
        huawei.innerHTML = `<img src="images/down_appgallery.png" alt="appgallery" class="hero-app-btn"/>`;
        huawei.addEventListener("click", sendTrackingInfo);
        // huawei.myParam = 'Link Celulares';

        function sendTrackingInfo(){
            // alert(actionName.currentTarget.myParam);
            ReactGA.event({
                category: 'Link Landing',
                action: 'Link Download Stores (App Page)'
            });
        }
        
    }, []);
    
    let settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: useWindowSize(),
        slidesToScroll: 1,
        cssEase: "linear",
        autoplay: false,
        autoplaySpeed: 2000
    }

    return (
        <React.Fragment>
            <HeroSection {...homeObjOne} />
            <div className="slider-section">
                <h3>Conoce los beneficios</h3>
                <Slider {...settings}>
                    <Card {...card['home']}/>
                    <Card {...card['sideBar']}/>
                    <Card {...card['contrato']}/>
                    <Card {...card['depositos']}/>
                    <Card {...card['estadoCuenta']}/>
                </Slider>
             </div>
        </React.Fragment>
    )
}

export default MobileApp