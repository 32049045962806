import React from 'react';
// import axios from 'axios';
// import './Mail.css';
import {FaRegListAlt} from 'react-icons/fa'
import ConfCard from '../../Sidebar/Opciones/Cuenta/ConfCard.js/ConfCard';

const DatosFiscalesActuales=(props)=>{

    return (
        <ConfCard 
                titulo={"Datos Fiscales Actuales"}
                icon = {<FaRegListAlt className="opcion-conf-icon"/>} 
                content={
                    <>
                    <div className="groupCuenta_prueba">
                        <label>Nombre</label>
                        <hr />
                        <div className="form-group">
                            <label id="label_nombre_razon_social" name="label_nombre_razon_social">{props.datos.nombre_cliente}</label>
                        </div>
                    </div>
                    <div className="groupCuenta_prueba">
                        <label>RFC</label>
                        <hr />
                        <div className="form-group">
                            <label id="label_rfc" name="label_rfc">{props.datos.rfc}</label>
                        </div>
                    </div>
                    <div className="groupCuenta_prueba">
                        <label>Calle</label>
                        <hr />
                        <div className="form-group">
                            <label id="label_calle" name="label_calle">{props.datos.calle}</label>
                        </div>
                    </div>
                    <div className="groupCuenta_prueba">
                        <label>Colonia</label>
                        <hr />
                        <div className="form-group">
                            <label id="label_colonia" name="label_colonia">{props.datos.colonia}</label>
                        </div>
                    </div>
                    <div className="groupCuenta_prueba">
                        <label>Número Interior/Exterior</label>
                        <hr />
                        <div className="form-group">
                            <label id="label_num_ext" name="label_num_ext">{props.datos.numero_exterior}</label>
                        </div>
                    </div>
                    <div className="groupCuenta_prueba">
                        <label>C.P</label>
                        <hr />
                        <div className="form-group">
                            <label id="label_domicilio_fiscal" name="label_domicilio_fiscal">{props.datos.codigo_postal}</label>
                        </div>
                    </div>
                    <div className="groupCuenta_prueba">
                        <label>Municipio</label>
                        <hr />
                        <div className="form-group">
                            <label id="label_municipio" name="label_municipio">{props.datos.ciudad}</label>
                        </div>
                    </div>
                    <div className="groupCuenta_prueba">
                        <label>Estado</label>
                        <hr />
                        <div className="form-group">
                            <label id="label_estado" name="label_estado">{props.datos.estado}</label>
                        </div>
                    </div>
                    <div className="groupCuenta_prueba">
                        <label>Régimen Fiscal</label>
                        <hr />
                        <div className="form-group">
                            <label id="label_regimen_fiscal" name="label_regimen_fiscal">{props.datos.regimen_fiscal_descripcion}</label>
                        </div>
                    </div>
                    <div className="groupCuenta_prueba">
                        <label>Uso CFDI</label>
                        <hr />
                        <div className="form-group">
                            <label id="label_cfdi" name="label_cfdi">{props.datos.cfdi_descripcion}</label>
                        </div>
                    </div>
                    </>
                }
            />
    )
}

export default DatosFiscalesActuales