import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core'
import NumberFormat from 'react-number-format';
import "./RowInput.css"

const RowInput=({
    type,
    groupWidth,
    label,  
    value,
    onChange = function(){return false},
    onBlur = function(){return false},
    onKeyPress=function(){return false},
    placeholder,
    id,
    adornment ="",
    error=false,
    helperText,
    required=false,
    content=false,
    classes="",
    numberFormat = false,
    disabled=false,
})=>{

    return (
        <div className={groupWidth ? 'rowGroup': 'rowInput'} style={{width: groupWidth}}>
            <h3 className='cotizadorInputLabel'>{label}</h3>
            {
              content ? 
              (
                content
              )
              :
              numberFormat ?
              <>
              <NumberFormat
                name={id} id={id}
                value={value}
                customInput={TextField}
                className={"cotizador-input "+classes}
                // displayType={'text'}
                onChange={e => onChange(e)}
                thousandSeparator={true}
                prefix={'$  '}
                disabled={disabled}
                // renderText={(value, props) => <div {...props}>{value}</div>}
              />
              {
                error ? 
                <div className='number-format-errors'>
                  <p>{helperText}</p>
                </div>
                :
                <></>
              }
              </>
              :
              <TextField
                name={id} id={id}
                value={value}
                className={"cotizador-input "+classes}
                onChange={e => onChange(e)}
                onBlur= {e => onBlur(e)}
                onKeyPress= {e => onKeyPress(e)}
                placeholder={placeholder}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
                }}
                error={error}
                helperText={helperText}
                type={type} step="any"
                fullWidth autoComplete='off' required={required} disabled={disabled}
              />
            }
        </div>
    )
}

export default RowInput