import React, { useLayoutEffect, useState } from 'react';import Slider from "react-slick";
import Card from '../../../generales/Card'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./GallerySlider.css"
import {card} from './Data'

function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    if(size <=540){
        return 1
    }
    if(size <= 760){
        return 2
    }
    if(size > 760){
        return 3
    }
    // return size;
  }

function GallerySlider() {
    let settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: useWindowSize(),
        slidesToScroll: 1,
        cssEase: "linear",
        autoplay: false,
        autoplaySpeed: 2000
    }

    // let size = useWindowSize();
    return (
        <div className="slider-section">
            <h3>Nuestras 6 Sucursales</h3>
            <Slider {...settings}>
                <Card {...card['CEDIS']}/>
                <Card {...card['Angeles']}/>
                <Card {...card['SantoDomingo']}/>
                <Card {...card['Venustiano']}/>
                <Card {...card['Guadalupe']}/>
                <Card {...card['Gonzalitos']}/>
                <Card {...card['Aguascalientes']} />
            </Slider>
        </div>
    )
}

export default GallerySlider
