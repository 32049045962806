import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InstruccionPago.css';
import ReactGA from 'react-ga';

const InstruccionEfectivo=(props)=>{
    const [num_fichas, setNumFichas] = useState(1);
    const [lugar, setLugar] = useState("seven");
    const [periodos, setPeriodos] = useState([]);

    // console.log(props.datosPago);

    useEffect(() => {
        let newLugar = lugar 
        newLugar = props.lugarPago;
        setLugar(newLugar);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.lugarPago])

    useEffect(() => { 
        
        let cleanUp = true;
        
        const getEdoCuenta = () => {
            if(props.contrato_metacid !== 0 || props.contrato_metacid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":props.contrato_metacid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/estado_cuenta",{"json":json}, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(cleanUp){
                        // console.log(response);
                        let status = response.data.status;
                        let data = response.data.result;

                        //return(console.log(data.datos_cliente))
                        
                        if(status === "success" ){

                            let r_periodos = data.estado_cuenta;
                            let newData = {...periodos};
                            newData = r_periodos;
                            setPeriodos(newData);
                            // console.log(r_periodos)

                        }else{
                            //alert(msg);
                            let newData = {...periodos};
                            newData = [{status: 0, descripcion: 'La información no está disponible'}];
                            setPeriodos(newData);
                        }
                    }
                })
                .catch(function (error) {

                    let newData = {...periodos};
                    newData = [{status: 0, descripcion: 'No se pudo consultar la información'}];
                    setPeriodos(newData);

                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }

        getEdoCuenta(props.contrato_metacid);

        return () => {
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contrato_metacid]);

    function pdfFicha(contrato_metacid){
        //ENVIAMOS TRACKING INFO
        ReactGA.event({
            category: 'Boton Portal',
            action: 'Generar Fichas Deposito'
        });
        if(contrato_metacid !== 0 && contrato_metacid !== "0"){
            const token = localStorage.getItem('token');
            let json = JSON.stringify({"contrato_meta_cid":contrato_metacid, "num_periodos":3});
            axios.post(process.env.REACT_APP_API_DM_URL+"/api/descargar_fichaDeposito",{"json":json}, {
                headers: {
                'Authorization' : token,
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response.status === 'error') {
                    alert('Ocurrió un problema al dibujar PDF');
                }else{
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.target = "_system"
                    a.style = "display: none";
                    // a.href = `${process.env.REACT_APP_API_DM_URL}/api/printFichasDeposito/`+contrato_metacid+'/'+3;
                    a.href = `${process.env.REACT_APP_API_DM_URL}/api/printFichasDeposito/`+contrato_metacid+'/'+num_fichas;

                    a.click();
                }
            })
            .catch(function (error) {

                alert("Hubo un problema al consultar la información");
                if (error.response) {
                // Request made and server responded
                console.log("Error-Response: "+error.response.data);
                console.log("Error-Status: "+error.response.status);
                console.log("Error-Headers: "+error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                console.log('Error-Request: '+error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error-Message: '+ error.message);
                }
            });
        }
    }

    const onInputChange = e => {
        setNumFichas(e.target.value);
        desiluminar();
        for(let x=0; x<=e.target.value-1; x++){
            document.getElementsByClassName("cash-row")[x].classList.add("selected-row")
        }
    };

    function desiluminar(){
        let rows = document.getElementsByClassName("cash-row");
        for(let x=0; x<=rows.length-1; x++){
            if(rows[x].classList.contains("selected-row")){
                rows[x].classList.remove("selected-row")
            }
        }
    }

    function createSelect() {
    
        let count = 0;
        return periodos.map((ped, i) => {    
            // Return the element. Also pass key  
            if(ped.status !== 2){
                count++;
            }
            return (
                ped.status !== 2 ?
                <option key={i} value={count}>{count}</option>
                : 
                <React.Fragment key={i}></React.Fragment>
            ) 
        })
    }

    function createEdoVencido() {
    
        let count = 0;
        return periodos.map((ped, i) => {  
            if(ped.status !== 2){
                count++;
            }     
            return (
                ped.status !== 2 ? 
                    count === 1 ?
                    <tr className={`cash-row selected-row`} key={i}>
                        <td>{ped.fecha}</td>
                        <td>{ped.descripcion}</td>
                        <td>{ped.saldo}</td>
                    </tr>
                    :
                    <tr className={`cash-row`} key={i}>
                            <td>{ped.fecha}</td>
                            <td>{ped.descripcion}</td>
                            <td>{ped.saldo}</td>
                    </tr>
                :
                <React.Fragment key={i}>
                </React.Fragment>
            ) 
    })
    }

    // console.log(props.instrucciones)
    return (
        <div className="pago-container">
            <div id="efectivo" className="efectivo-content" style={{display:"block"}}>
                <div className="info-box">
                    <div className="info-cont">
                        <h2><span style={{fontSize: "1rem"}}>° Recuerda:</span></h2>
                        <ul>
                            <li>Tu pago puede demorar en reflejarse entre 30-60 minutos.</li>
                        </ul>
                    </div>
                </div>
                <div style={{border:"0px solid"}} className={`panel-heading sh-${lugar}`}>
                    <img src={`${process.env.PUBLIC_URL}/images/portal/formas_pago/logo-${lugar}.jpg`} alt="imagen" height="45" className="imagen_logo"/>
                </div>
                <div className="table-r" width="100%" style={{fontSize:"20px",textAlign:"center", border: "collapse"}}>
                    <div className={`thead border-${lugar}`}>
                        <div className="tr">
                            <div className={`back-${lugar} t_header alinear_centro`}><strong>{props.instrucciones.instruccion[0]}</strong></div>
                        </div>
                    </div>
                    <div className="tbody">
                        <div className="tr">
                            <div className={`td t_footer border-${lugar}`}> Arrendadora Datamovil  Autos Particulares</div>
                            </div>
                        <div className="tr" style={{height:".5em"}}></div>
                    </div>
                    <div className={`thead border-${lugar}`}>
                        <div className="tr">
                            <div className={`back-${lugar} t_header1 alinear_centro`}><strong>{props.instrucciones.instruccion[1]}</strong></div>
                        </div>
                    </div>

                    <div className="tbody">
                        <div className="tr">
                            <div id="numero_td archivo_banorte" className={`t_footer border-${lugar} alinear_centro`}>{props.datosPago.emisora}</div>
                        </div>
                        <div className="tr" style={{height:".5em"}}></div>
                    </div>
                    <div className={`thead border-${lugar}`}>
                        <div className="tr">
                            <div className={`back-${lugar} t_header1 alinear_centro`}><strong>{props.instrucciones.instruccion[2]}</strong></div>
                        </div>
                    </div>
                    <div className="tbody">
                        <div className="tr">
                            <div id="referentd cia_larga_banorte" className={`t_footer border-${lugar} alinear_centro`}>{props.datosPago.referencia}</div>
                        </div>
                        <div className="tr" style={{height:".5em"}}></div>
                    </div>
                    <div className={`thead border-${lugar}`}>
                        <div className="tr">
                            <div className={`back-${lugar} t_header1 alinear_centro`}><strong>{props.instrucciones.instruccion[3]}</strong></div>
                        </div>
                    </div>
                    <div className="tbody">
                        <div className="tr">
                            <div id="monto_atd pagar_banorte" className={`t_footer border-${lugar} alinear_centro`}>{props.datosPago.monto}</div>
                        </div>
                    </div>
                </div>
                <div style={{border:"0px solid",margin: "1rem 0rem 0 0rem"}} className="panel-heading panel-blue">
                    <h4 className="panel-title">Horarios: </h4>
                </div>
                <div className="table-custom display responsive nowrap table-r" style={{width: "100%", margin: "0"}}>
                    <div className='horario-efectivo-tr'>
                        <span style={{padding:"3px"}}><strong> Banorte </strong></span>
                        <span style={{padding:"3px"}}> 8:30 A.M.  A  4:00 P.M</span>
                    </div>
                    <div className='horario-efectivo-tr'>
                        <span style={{padding:"3px"}}><strong> 7-Eleven </strong></span>
                        <span style={{padding:"3px"}}> 8:00 A.M.  A  8:00 P.M</span>
                    </div>
                    <div className='horario-efectivo-tr'>
                        <span style={{padding:"3px"}}><strong> Farmacias del Ahorro </strong></span>
                        <span style={{padding:"3px"}}> 8:00 A.M.  A  8:00 P.M</span>
                    </div>
                </div>
            </div>
            <div className="ficha-deposito">
                <h1>Ficha de Depósitos</h1>
                <div className="fd-content">
                    <div className="periodos_options">
                        <label>Periodos:</label>
                        {/* <input className="num_fichas" id="num_fichas" type="tel" name="num_fichas" maxLength={3} placeholder={"Numero de fichas"} onChange={(evt) => onInputChange(evt)}></input> */}
                        <div className="selectPeriodos">
                            <select className="num_fichas" id="num_fichas" name="num_fichas" onChange={(evt) => onInputChange(evt)}>
                                {
                                    createSelect()
                                }
                            </select>
                        </div>
                        <button className="btn-blue btnDescargarFicha" onClick={() => {pdfFicha(props.contrato_metacid, num_fichas)}}>DESCARGAR</button>

                    </div>
                    <br></br>
                    <br></br>
                    <div className="estadoC__table estadoC__fichas">
                            <table>
                                <thead id="thead_estado_cuenta">
                                    <tr id="thead_tr_estado_cuenta" className="sh-gray">
                                        <th>Fecha</th>
                                        <th>Periodo</th>
                                        <th>Saldo</th>
                                    </tr>
                                </thead>
                                <tbody id="tbody_estado_cuenta">
                                    {
                                        // periodos.map((ped, i) => {     
                                        //         // Return the element. Also pass key     
                                        //         return (
                                        //             ped.status !== 2 ? 
                                        //             <tr className="cash-row" key={i}>
                                        //                     <td>{ped.fecha}</td>
                                        //                     <td>{ped.descripcion}</td>
                                        //                     <td>{ped.saldo}</td>
                                        //             </tr>
                                        //             :
                                        //             <React.Fragment key={i}>
                                        //             </React.Fragment>
                                        //         ) 
                                        // })
                                        createEdoVencido()
                                    }
                                </tbody>
                            </table>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default InstruccionEfectivo