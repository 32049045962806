import React, { useState, useEffect}from 'react'
import { Grid,Paper, TextField, Button, Typography } from '@material-ui/core'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import "./VerifyMail.css"
import { Link } from 'react-router-dom'
import {IconContext} from 'react-icons/lib'
import { bcAlert } from '../../../boclAlert';
 
const VerifyMail=()=>{
 
    /*STYLES__________________
    __________________________
    ________________________*/
    
    /*--STYLES
    -------Uses @material-ui
    -------Brandon Cantu
    ------------*/
    const paperStyle={padding :30,height:'auto',width:480, maxWidth:"90%",margin:"0 auto", paddingTop: '10px'}
    const btnstyle={margin:'2rem 0'}

    /*VARIABLES AND CONST__________________
    _______________________________________
    _____________________________________*/
    
    /*--Declaration of INPUTS VARIABLES
    ---------codigo (string)
    -------Brandon Cantu
    ------------*/
    const [codigo, setCodigo] = useState("");

    /*--Declaration of USER
    ---------id cint)
    ---------name (string)
    ---------surname (string)
    -------Brandon Cantu
    ------------*/
 
    /*FUNCIONES__________________
    _____________________________
    ___________________________*/
    let history = useHistory(); 

    useEffect(() => { 
      
      const checarExistenciaCodigo = () =>{

        // const email_verify = JSON.parse(localStorage.getItem("userIdentity")).verify;
        const token = localStorage.getItem('token');
  
        // let json = JSON.stringify({"email_verified_at":email_verify});
        let json = JSON.stringify({"variable":null});
  
        axios.post(process.env.REACT_APP_API_DM_URL+"/api/pedir_correo",{"json":json}, {
          headers: {
          'Authorization' : token,
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
          }
        })
        .then(response => {

          // console.log(response); return;
  
          let status = response.data.status;
  
          if(status === "success"){
  
            let correo_enviado = response.data.correo_enviado;
            if(correo_enviado === 0 || correo_enviado === "0"){
              //SE ENVIA CORREO
              enviarCorreo();
            }else{
              //YA SE HABIA ENVIADO UN CÓDIGO
              bcAlert({
                msg : {
                    "title":"Código generado", 
                    "desc":"Revise su correo electrónico para capturar su código."
                },
                type :"warning", 
                styles : ``, 
                options : false
              });
            }
  
          }else{
            bcAlert({
              msg : {
                  "title":"Oops!", 
                  "desc":"Ocurrió un error, inténtelo más tarde"
              },
              type :"error", 
              styles : ``, 
              options : false
            });
          }
        })
        .catch(function (error) {
  
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
      }

      checarExistenciaCodigo()
    }, []);

    useEffect(() => { 
      return () => {
        setCodigo(''); 
      };
    }, []);
 
    const onInputChange = e => {
      setCodigo(e.target.value);
    };

    const enviarCorreo = () =>{

      const email_verify = JSON.parse(localStorage.getItem("userIdentity")).verify;
      const token = localStorage.getItem('token');

      let json = JSON.stringify({"email_verified_at":email_verify});

      axios.post(process.env.REACT_APP_API_DM_URL+"/api/enviar_correo_verifar",{"json":json}, {
        headers: {
        'Authorization' : token,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
        }
    })
      .then(response => {

        let status = response.data.status;

        if(status === "success"){

          bcAlert({
            msg : {
                "title":"Listo!", 
                "desc":"Se ha enviado un correo con tu código de verificación"
            },
            type :"success", 
            styles : ``, 
            options : false
          });

        }else{
          bcAlert({
            msg : {
                "title":"Oops!", 
                "desc":"Hubo un problema al enviar el código de verificación"
            },
            type :"error", 
            styles : ``, 
            options : false
          });
        }
      })
      .catch(function (error) {

        bcAlert({
          msg : {
              "title":"Oops!", 
              "desc":"Hubo un problema, inténtalo más tarde"
          },
          type :"error", 
          styles : ``, 
          options : false
        });

        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
    }

    const asyncLocalStorage = {
      setItem: function (key, value) {
          return Promise.resolve().then(function () {
              localStorage.setItem(key, value);
          });
      },
      getItem: function (key) {
          return Promise.resolve().then(function () {
              return localStorage.getItem(key);
          });
      }
    };

    function GetUserAccount() {
      
      const token = localStorage.getItem('token');

      let tokenObject = { 
        gettoken: token
      }

      let json_send = JSON.stringify(tokenObject);
      let to_send = {"json": json_send};
      axios.post(process.env.REACT_APP_API_DM_URL+"/api/getUserData",to_send, {
        headers: {
        'Authorization' : token,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
        }
      })
      .then(response => {

        let status = response.data.status;
        let data = response.data.data;
        
        if(status === "1" || status === 1){

          // console.log(data.identity)
          let identity = data.identity;
          let userIdentity = {
            name : identity.name,
            surname : identity.surname,
            id : identity.id,
            verify: identity.email_verified_at
          }

          asyncLocalStorage.setItem('userIdentity', JSON.stringify(userIdentity)).then(function () {
              return asyncLocalStorage.getItem('userIdentity');
          }).then(function (value) {
              // console.log('Value has been set to:', value);
              redirectToPortal();
          });
        }else{
          //alert(msg);
        }

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Error-Response: "+error.response.data);
          console.log("Error-Status: "+error.response.status);
          console.log("Error-Headers: "+error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error-Request: '+error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error-Message: '+ error.message);
        }
      });
    }
 
    function redirectToPortal(){
      history.push("/portal");
    }
        
    const verificarCodigo = () =>
    {
 
       if(codigo === '')
       {
         alert('Codigo is empty')
         return false;
       }

      //SET LOADER
        let btnVerificar = document.querySelector("#btnVerificar .MuiButton-label");
      //disapper content
        let ogInner = btnVerificar.innerHTML;
        let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
        btnVerificar.innerHTML = loaderHtml;
 
       const token = localStorage.getItem('token');
       let json = JSON.stringify({"codigo_verificacion":codigo});
       let send = {"json": json};

       axios.post(process.env.REACT_APP_API_DM_URL+"/api/verificar_correo",send, {
        headers: {
        'Authorization' : token,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
        }
      })
       .then(response => {

        btnVerificar.innerHTML = ogInner;
        let status = response.data.status;
        // console.log(response);
        
         if(status === "success"){
 
          bcAlert({
            msg : {
                "title":"Listo!", 
                "desc":"Tu correo ha sido verificado, en un momento se rediccionará al portal Datamovil."
            },
            type :"success", 
            styles : ``, 
            options : false
          });
          setTimeout(() => {
            GetUserAccount();
          }, 1750);

         }else{
            bcAlert({
              msg : {
                  "title":"Oops!", 
                  "desc":"El código introducido no es correcto, inténtalo de nuevo."
              },
              type :"error", 
              styles : ``, 
              options : false
            });
         }
      })
      .catch(function (error) {
        btnVerificar.innerHTML = ogInner;

        bcAlert({
          msg : {
              "title":"Oops!", 
              "desc":"Hubo un problema, inténtalo más tarde."
          },
          type :"error", 
          styles : ``, 
          options : false
        });
        
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
    }
 
    
    return(
      <React.Fragment>
        <IconContext.Provider value={{ color: '#fff'}} >
            <div className="verify_navbar">
                <div className="verify_navbar-container verify_container">
                    <Link to='/' className="verify_navbar-logo">
                        <img src="./images/logo.svg" alt="logo" className="verify_logo-datamovil-bar"/>
                    </Link>
                    <ul className={'verify_nav-menu'}>
                        <li className="verify_nav-item">
                            <Link to="/" className="verify_nav-links">
                                Salir
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </IconContext.Provider>


        <Grid className="section-verifymail">
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                    <h2 style={{marginTop: "1rem"}}>Verifica tu correo</h2>
                </Grid>
                <p style={{margin:".5rem 0"}}>Se envío un correo con un código para verificar tu correo. Revisa tu bandeja de entrada y spam o no deseados. Introduce el código de 4 dígitos en el campo para validarlo y acceder al portal.</p>
                <TextField label='Código'name="codigo" className="login-input" onChange={e => onInputChange(e)} placeholder='Ingresa los 4 dígitos' type='tel' required
                inputProps={{maxLength :4}}/>
              
                <Button type='submit' onClick={verificarCodigo} id="btnVerificar" color='primary' variant="contained" style={btnstyle} fullWidth>Validar</Button>
              
                <Typography > Si no recibiste el correo, puedes enviar otro dando click
                  {/* <span style={{marginLeft:"4px"}} onClick={EnviarCorreo}>Regístrate</span> */}
                  <span id="reenviarCodigo" onClick={enviarCorreo} style={{marginLeft:"4px", color:"var(--orange)"}}>aquí</span>
                </Typography>
            </Paper>
        </Grid>
      </React.Fragment>
    )
}
 
export default VerifyMail