import './App.css';
import Navbar from './components/pages/Navbar';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Home from './components/pages/HomePage/Home';
import MobileApp from './components/pages/MobileApp/MobileApp';
import Nosotros from './components/pages/Nosotros/Nosotros';
import Contacto from './components/pages/Contacto/Contacto';
import Footer from './components/pages/Footer/Footer';
import ScrollTop from './components/generales/ScrollTop';
import Login from "./components/portal/Login/Login";
import Signup from "./components/portal/Login/Signup";
import Portal from "./components/portal/Portal";
import AvisoPrivacidad from './components/pages/AvisoPrivacidad/AvisoPrivacidad';
import VerifyMail from './components/portal/VerifyMail/SendCorreo/VerifyMail';
import ReactGA from 'react-ga';
import AnalyticsRoute from './components/AnalyticsRoute'
import Cotizador from './components/pages/Cotizador/Cotizador';
import CotizadorAutosdatamovil from './components/pages/CotizadorAutosdatamovil/CotizadorAutosdatamovil';
// import ProtectedRoute from './components/ProtectedRoute';

function App() {
  
  const TRACKING_ID = "UA-135858120-3"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  return (
    <Router>

      <Route
        path="/"
        render={({ match: { url } }) => (
          <>
            {/* <Route path="/(?!portal)" component={Navbar}/> */}
            <Route
                path={`/(mobile-app|nosotros|contacto|login|signup|aviso-privacidad|cotizador|)`} 
                component={() => (<Navbar/>)}
            />
            <ScrollTop />
            <Route path={`${url}/`} component={Home} exact />
            <Route path="/mobile-app" component={MobileApp}/>
            <Route path="/nosotros" component={Nosotros}/>
            <Route path="/contacto" component={Contacto}/>
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/verificar" component={VerifyMail} />
            <Route exact path="/aviso-privacidad" component={AvisoPrivacidad} />
            <Route exact path="/cotizador" component={Cotizador} />
            <Route exact path="/cotizadorautosdatamovil" component={CotizadorAutosdatamovil} />
            <Route
                path={`/(mobile-app|nosotros|contacto|login|signup|aviso-privacidad|cotizador|)`} 
                component={() => (<Footer/>)}
            />
            {/* <Footer/> */}
          </>
        )}
      />

      {/* <Route
        path="/portal"
        render={({ match: { url } }) => (
          <>
            <ProtectedRoute path={`${url}/`} component={Portal} exact />
            <Route path="/portal/estado_cuenta" component={EdoCuenta} exact/>
          </>
        )}
      /> */}
      <Route path={"/portal"} component={Portal} />
      <AnalyticsRoute />
    </Router>
  );
}

export default App;
