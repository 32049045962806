import React from 'react';
import './ImageReveal.css'

export default function HeroSection({
    twoSlides,
    bgImgTopBool,
    bgImgBottomBool,
    bgColorTop,
    bgColorBottom,
    bgImgTop,
    bgImgBottom,
    div_height,
    div_width,
    mobile,
    text,
    text_title,
    text_cont
}) {
    let stylesI = {};
    let stylesW = {};
    let mobile_width = "mobile_2";
    if(bgImgTopBool){
        stylesI = {
            backgroundImage: bgImgTop,
          };
    }else{
        stylesI = {
            backgroundColor: bgColorTop
        }
    }

    if(bgImgBottomBool){
        stylesW ={
            backgroundImage: bgImgBottom,
        }
    }else{
        stylesW ={
            backgroundColor: bgColorBottom,
        }
    }

    if(mobile === 1){
        mobile_width = "mobile_1"
    }else if(mobile === 2){
        mobile_width = "mobile_2"
    }else if(mobile === 0){
        mobile_width = "mobile_0"
    }


    return (
        <div className={`ir__wrapper ${mobile_width}`} style={{width: div_width, height: div_height}}>
            <div className="ir__content">
                <div className="w" style={stylesW}>
                    { twoSlides ?
                        (
                        <React.Fragment><div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        <div className="i" style={stylesI}></div>
                        </React.Fragment>)
                        :
                        ''
                    }
                    {text ? 
                        <div className="h">
                            <h1>{text_title}</h1>
                            <p>{text_cont}</p>
                        </div>
                        :
                        ''
                    }
                    
                </div>
            </div>
        </div>
    )
}


