import React from 'react'
import './SelectContrato.css';
// import {FaMapMarkerAlt} from 'react-icons/fa'

export default function SelectContrato({
    listaCont,  
    contrato_actual,
    changeContratoActual,
    emptyMessage  
}) 
    {
        // console.log(listaCont.length)

        return (
            <React.Fragment>
                <div className='selectC_title'>
                    <h2>Tus contratos</h2>
                </div>
                <div className="selectC__cards">
                    {
                        listaCont.length>0 ?
                            listaCont.map((contrato, i) => {  
                                // console.log(contrato_actual)  
                                // console.log(contrato.Contrato_Numero )
                                let marca = contrato.Mo ? contrato.Mo : "";
                                    let nombre = contrato.Nombre_Auto ? contrato.Nombre_Auto : ""; 
                                    let modelo = contrato.Modelo ? contrato.Modelo : "";
                                    let color = contrato.Color ? contrato.Color : "";
                                    let placas = contrato.Placas ? contrato.Placas : ""; 
                                    let auto = marca+" "+nombre+" "+modelo+" "+color+" "+placas;
            
                            if(String(contrato_actual.numero) === String(contrato.Contrato_Numero)){
                                return (
                                    // Return the element. Also pass key 
                                    <div className="selectcard_Contrato selectcard_Contrato_selected" id={`card_lista_contratos_${contrato.Contrato_Numero}`} key={i} onClick={() => changeContratoActual(contrato.Contrato_Numero)}>
                                        <span className='activeBall activeCard' id="activeCard"></span>
                                        <input type={"hidden"} id={"cmeta_"+contrato.Contrato_Numero} data-val={contrato.id_contrato} value={contrato.Contrato_Numero}></input>
                                        <div className="selectcard_Contrato-body">
                                            <div className="selectcard_Contrato__ref">
                                                <p>Referencia</p>
                                                <h3 id="referencia">{contrato.referencia_corta.substr(2, contrato.referencia_corta.lenght)}</h3>
                                            </div>
                                            <div className="selectcard_Contrato__datos">
                                                <h3>{contrato.Nombre_Cliente}</h3>
                                                <h3>{auto}</h3>
                                            </div>
                                        </div>
                                    </div>
                                ) 
                            }else{
                                return (
                                    // Return the element. Also pass key 
                                    <div className="selectcard_Contrato" id={`card_lista_contratos_${contrato.Contrato_Numero}`} key={i} onClick={() => changeContratoActual(contrato.Contrato_Numero)}>
                                        <span className='activeBall' id="activeCard"></span>
                                        <input type={"hidden"} id={"cmeta_"+contrato.Contrato_Numero} data-val={contrato.id_contrato} value={contrato.Contrato_Numero}></input>
                                        <div className="selectcard_Contrato-body">
                                            <div className="selectcard_Contrato__ref">
                                                <p>Referencia</p>
                                                <h3 id="referencia">{contrato.referencia_corta.substr(2, contrato.referencia_corta.lenght)}</h3>
                                            </div>
                                            <div className="selectcard_Contrato__datos">
                                                <h3>{contrato.Nombre_Cliente}</h3>
                                                <h3>{auto}</h3>
                                            </div>
                                        </div>
                                    </div>
                                ) 
                            }  
                        })
                    :
                        <div className="empty_selectcard_Contrato">
                            <h3>{emptyMessage}</h3>
                        </div>
                    }
                    
                </div>
            </React.Fragment>
        )
}