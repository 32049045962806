export const lugaresPago_efectivo = {
    lugares:[
        {"id": 0,"tipo": "efectivo", "code": "seven","nombre": "Seven"},
        {"id": 1,"tipo": "efectivo", "code": "ahorro","nombre": "Farmacias Ahorro"},
        {"id": 2,"tipo": "efectivo", "code": "banorte","nombre": "Banorte"}
    ]
}
export const lugaresPago_transfer = {
    lugares:[
        {"id": 3,"tipo": "transfer", "code": "spei","nombre": "Desde otro banco"},
        {"id": 4,"tipo": "transfer", "code": "banorte_web","nombre": "Banorte Web"}
    ]
}
// export const lugaresPago = {
//     lugares:[
//         {"id": 0,"tipo": "efectivo", "code": "seven","nombre": "Seven"},
//         {"id": 1,"tipo": "efectivo", "code": "ahorro","nombre": "Farmacias Ahorro"},
//         {"id": 2,"tipo": "efectivo", "code": "banorte","nombre": "Banorte"},
//         {"id": 3,"tipo": "transfer", "code": "spei","nombre": "SPEI"},
//         {"id": 4,"tipo": "transfer", "code": "banorte_web","nombre": "Banorte Web"}
//     ]
// }
export const instruccionAhorro = {
    instruccion:["Beneficiario", "Pago de Servicios Banorte, Referencia 1", "Referencia 2", "Monto a Pagar"]
}
export const instruccionEfectivo = {
    instruccion:["Beneficiario", "Pago de Servicios Banorte, Empresa/Facturador", "Referencia", "Monto a Pagar"]
}

export const instruccionBanorteWeb = {
    instruccion:[
        {"id": 0,"input": "0", "descripcion": "Ingresa a Banorte", "code": "0"},
        {"id": 1,"input": "0", "descripcion": "Ve al módulo \"PAGO DE SERVICIOS\"", "code": "0"},
        {"id": 2,"input": "1", "descripcion": "Busca la emisora (recuerda que puedes registrar la cuenta a tu lista):", "code": "emisora"},
        {"id": 3,"input": "1", "descripcion": "En el campo 'Referencia', captura los siguientes 16 dígitos:", "code": "referencia"},
        {"id": 4,"input": "1", "descripcion": "Tu monto a pagar es:", "code": "monto"},
        {"id": 5,"input": "0", "descripcion": "Confirma la transacción, ¡y listo!", "code": "0"},
    ]
}
export const instruccionSpei = {
    instruccion:[
        {"id": 0,"input": "0", "descripcion": "Ingresa a tu banca electrónica", "code": "0"},
        {"id": 1,"input": "0", "descripcion": "Selecciona \"Enviar SPEI\"", "code": "0"},
        {"id": 2,"input": "1", "descripcion": "En banco estino, selecciona: Banorte", "code": "0"},
        {"id": 3,"input": "1", "descripcion": "En el campo 'Cuenta/Clabe', captura:", "code": "clabe"},
        {"id": 4,"input": "1", "descripcion": "En el campo 'Referencia', captura los siguientes 7 dígitos:", "code": "emisora"},
        {"id": 5,"input": "1", "descripcion": "En el campo 'Concepto', captura los siguientes 16 dígitos:", "code": "referencia"},
        {"id": 6,"input": "1", "descripcion": "Tu monto a pagar es:", "code": "monto"},
        {"id": 7,"input": "0", "descripcion": "Confirma la transacción, ¡y listo!", "code": "0"}
    ]
}