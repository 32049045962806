import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import Sidebar from './Sidebar/Sidebar'
import NavbarPortal from './NavbarPortal/NavbarPortal';
import './Portal.css';
import { Router, Route, Switch } from 'react-router-dom';
import EdoCuenta from './EdoCuenta/EdoCuenta';
import HomePortal from './HomePortal/HomePortal';
import ProtectedRoute from '../ProtectedRoute';
import DepFactura from './DepFactura/DepFactura';
import SeguroAuto from './SeguroAuto/SeguroAuto';
import FormasPago from './FormasPago/FormasPago';
import AgregarContrato from './Sidebar/Opciones/Contratos/Contratos';
import Cuenta from './Sidebar/Opciones/Cuenta/Cuenta';
import Contacto from './Contacto/Contacto';
import Clave from './Clave/Clave';
import DatosFiscales from './DatosFiscales/DatosFiscales';
import BeneficionsSeguros from './Sidebar/Opciones/LandingSeguro/Beneficios-Seguro';
//SET BANNNER

 
const Portal=(props)=>{

    const [contratos, setContratos] = useState([]);
    const [emptyMessage, setEmptyMessage] = useState("Cargando...");
    const [contrato_actual, setContratoActual] = useState({
        numero: 0,
        metacid: 0,
        nombre: ''
    });
    const [datosExtraContrato, setDatosExtrasContrato] = useState({
        showFactura: 0,
    });
 
    // const {users} = useParams();  
    let browserHistory = useHistory(); 

    useEffect(() => { 
        return () => {
            setContratos([]); 
            setContratoActual({numero: 0,metacid: 0, nombre: ''})
        };
    }, []);

    useEffect(() => {
        GetContratos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        
        if(!contratos){
            browserHistory.push("/portal/contratos");
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[contratos]);

    const setDatoExtra = (metacid) => {
        const token = localStorage.getItem('token');
        let json = JSON.stringify({
            "contrato_metacid": metacid,
          });

        axios.post(process.env.REACT_APP_API_DM_URL+"/api/datos_extras",{"json":json}, {
            headers: {
            'Authorization' : token,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
            }
        })
        .then(response => {
            let status = response.data.status;
            let data = response.data.data;
            // console.log(data.show_factura); return;
            
            
            if(status === "success" ){

                let de_showFactura = data.show_factura;
                if(Array.isArray(de_showFactura)){
                    de_showFactura = de_showFactura[0];
                    setDatosExtrasContrato({...datosExtraContrato, showFactura:de_showFactura.show_factura})
                }
                
            }else{
                setEmptyMessage("No tienes contratos")
                // browserHistory.push("/portal/contratos");
            }

        })
        .catch(function (error) {
            if (error.response) {
            // Request made and server responded
            console.log("Error-Response: "+error.response.data);
            console.log("Error-Status: "+error.response.status);
            console.log("Error-Headers: "+error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            console.log('Error-Request: '+error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error-Message: '+ error.message);
            }
        });
    }

    const GetContratos = () => {
        const token = localStorage.getItem('token');
        let json = JSON.stringify({
            "token": token,
          });

        axios.post(process.env.REACT_APP_API_DM_URL+"/api/listar_contratos",{"json":json}, {
            headers: {
            'Authorization' : token,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
            }
        })
        .then(response => {
            let status = response.data.status;
            let data = response.data.data;
            
            if(status === "success" ){

                let lista_contratos = data.lista_contratos;

                setContratos(lista_contratos);
                setContratoActual({numero:lista_contratos[0].Contrato_Numero, metacid: lista_contratos[0].id_contrato, nombre: lista_contratos[0].Nombre_Cliente})
                setDatoExtra(lista_contratos[0].id_contrato)         
                //return(console.log(data);

            }else{
                setEmptyMessage("No tienes contratos")
                // browserHistory.push("/portal/contratos");
            }

        })
        .catch(function (error) {
            if (error.response) {
            // Request made and server responded
            console.log("Error-Response: "+error.response.data);
            console.log("Error-Status: "+error.response.status);
            console.log("Error-Headers: "+error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            console.log('Error-Request: '+error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error-Message: '+ error.message);
            }
        });
    }
 
    const ls_users = JSON.parse(localStorage.getItem('userIdentity'));
    let user = "";
    if(ls_users){
      user = "Hola "+ls_users.name+"!";
    }else{
      user = "";
    }

    const changeContrato = (num_contrato) => {
        let newData = {...contrato_actual} //copy the object
        // console.log(document.querySelector("#cmeta_"+num_contrato).value);
        newData = {numero:document.querySelector("#cmeta_"+num_contrato).value, metacid:document.querySelector("#cmeta_"+num_contrato).getAttribute('data-val')};
        setContratoActual(newData);
        setDatoExtra(document.querySelector("#cmeta_"+num_contrato).getAttribute('data-val'));


        //pintar card si no esta pintada
        if(!document.querySelector("#card_lista_contratos_"+num_contrato).classList.contains("selectcard_Contrato_selected")){
            document.querySelector("#card_lista_contratos_"+num_contrato).classList.add("selectcard_Contrato_selected")
            document.querySelector("#card_lista_contratos_"+num_contrato+ " span").classList.add("activeCard")
        }
        //DESPINTAMOS OPCION ANTES SELECCIONADA
        let optionsSelected = document.getElementsByClassName("optionSelected")
        if(optionsSelected.length>0){
            for(let x= 0; x<=optionsSelected.length-1; x++){
                optionsSelected[x].classList.remove("optionSelected")
            }
        }
        //redirigir a home si viene de opciones de sidebar
        var currentLocation = window.location.pathname;
        if(currentLocation.includes("contratos") || currentLocation.includes("cuenta")){
            browserHistory.push("/portal");
        }
    };

    return(
        <Router history= {browserHistory}>
                    <NavbarPortal user={user}/>
                        <div className="portal-wrapper">
                            <Sidebar
                                changeContrato={changeContrato}
                                contratos={contratos}
                                contrato_actual={contrato_actual}
                                emptyMessage={emptyMessage}
                            />
                            <div className="portalCont__ pc_div" style={{backgroundColor: "rgb(232,235,240)"}}>
                                <Route
                                    path="/portal"
                                    render={({ match: { url } }) => (
                                    <Switch>
                                        <ProtectedRoute path={`${url}/`} component={HomePortal} exact data={{
                                                contrato_metacid:contrato_actual.metacid,
                                                datosExtras:datosExtraContrato
                                        }}/>

                                        <ProtectedRoute path={"/portal/estado_cuenta"} component={EdoCuenta} data={{
                                                contrato_metacid:contrato_actual.metacid
                                        }}/>

                                        <ProtectedRoute path={"/portal/dep_factura"} component={DepFactura} 
                                            data={{
                                                contrato_metacid:contrato_actual.metacid,
                                                datosExtras:datosExtraContrato
                                        }}/>
                                        <ProtectedRoute path={"/portal/seguro"} component={SeguroAuto} 
                                            data={{
                                                contrato_metacid:contrato_actual.metacid
                                        }}/>
                                        <ProtectedRoute path={"/portal/formas_pago"} component={FormasPago} 
                                            data={{
                                                contrato_metacid:contrato_actual.metacid,
                                                changeContrato:changeContrato,
                                                contratos:contratos,
                                                contrato_actual:contrato_actual
                                        }}/>
                                        <ProtectedRoute path={"/portal/contacto"} component={Contacto} 
                                            data={{
                                                contrato_metacid:contrato_actual.metacid,
                                                contrato_numero:contrato_actual.numero
                                        }}/>
                                        <ProtectedRoute path={"/portal/clave"} component={Clave} 
                                            data={{
                                                contrato_metacid:contrato_actual.metacid,
                                                contrato_numero:contrato_actual.numero
                                        }}/>
                                        <ProtectedRoute path={"/portal/datos_fiscales"} component={DatosFiscales} 
                                            data={{
                                                contrato_metacid:contrato_actual.metacid
                                        }}/>
                                        {/* PANTALLAS DE OPCIONES SIDEBAR */}
                                        <ProtectedRoute path={"/portal/contratos"} component={AgregarContrato} 
                                            data={{
                                            contratosGlobales:GetContratos,
                                        }}
                                        />
                                        <ProtectedRoute path={"/portal/cuenta"} component={Cuenta} 
                                        />
                                        <ProtectedRoute path={"/portal/benseguro"} component={BeneficionsSeguros} 
                                            data={{contrato_metacid:contrato_actual.metacid, num_contrato: contrato_actual.numero, nombre_cliente: contrato_actual.nombre}}
                                        />
                                    </Switch>
                                    )}
                                />
                            </div>
                        </div>
        </Router>
    )
}
 
export default Portal