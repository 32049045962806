import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {FaAngleLeft} from 'react-icons/fa'
import ClaveCard from './ClaveCard/ClaveCard';
import axios from 'axios';
import './Clave.css';
// import { bcAlert } from '../../boclAlert';

export default function Clave({
    contrato_metacid,
    contrato_numero
}) {

    const [claveStatus, setClaveStatus] = useState("Cargando");
    const [clave, setClave] = useState({
        numero: [],
        fecha: "Cargando...",
        dias_vigentes: "Cargando...",
    });
    const [ultimoDep, setUltimoDep] = useState({
        fecha: "Cargando...",
        monto: "Cargando...",
    });

    useEffect(() => { 
        getClaveDeposito();
        getUltimoDeposito();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_numero]);


    //cleanup function
    useEffect(() => {
        return () => {
            setClave("");
            setClave({
                numero: [],
                fecha: "Cargando...",
                dias_vigentes: "Cargando...",
            });
            setUltimoDep({
                fecha: "Cargando...",
                monto: "Cargando...",
            });
        };
    }, [])

    const getClaveDeposito = () => {
        if(contrato_numero !== 0 && contrato_numero !== "0"){
            const token = localStorage.getItem('token');
            let json = JSON.stringify(
                {
                    "numero_contrato":contrato_numero,
                    "plataforma": "web"
                }
            );
            axios.post(process.env.REACT_APP_API_DM_URL+"/api/clave",{"json":json}, {
                headers: {
                'Authorization' : token,
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
                }
            })
            .then(response => {

                //return(console.log(response));
                let status = response.data.status;
                let result = response.data;

                if (status === 'success') {
                    let datos_clave = result.clave_disponible;
                    let clave_disponible = result.clave_disponible[0];
                    var months = ['Ene','Feb','Mar','Abr','Mayo','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];

                    // return(console.log(result.clave_disponible.dataclave_status));

                    //clave_disponible.clave_numero = "YES";
                    if(result){
                        if(datos_clave.dataclave_status === 3 || datos_clave.dataclave_status === "3"){
                            setClaveStatus("Nueva");
                            setClave({numero: [], fecha: "Información disponible para desplegar.", dias_vigentes: "Información disponible para desplegar."});
                        }else if (clave_disponible.clave_numero === 'Vencida') {
                            setClaveStatus("Vencida");

                            //Traemos Fecha
                            let data_unixtime = new Date(clave_disponible.fecha_expiracion * 1000);
                            let hours = data_unixtime.getHours().toString().padStart(2, '0');
                            let minutes = data_unixtime.getMinutes().toString().padStart(2, '0');
                            let data_fecha = data_unixtime.getDate()+" de "+months[data_unixtime.getMonth()]+" "+hours+":"+minutes;

                            let dias_disp = clave_disponible.Dias_Disponibles_Clave;
                            if(dias_disp<=0){
                                dias_disp="0";
                            }else{
                                dias_disp.toString();
                            }

                            setClave({numero: [], fecha: data_fecha, dias_vigentes: dias_disp});
                        }else{
                            setClaveStatus("Exito");
                            let clave_var = clave_disponible.clave_numero;
                            //clave_var = "246810";
                            let array_clave = clave_var.split('');

                            //Traemos Fecha
                            let data_unixtime = new Date(clave_disponible.fecha_expiracion * 1000);
                            let hours = data_unixtime.getHours().toString().padStart(2, '0');
                            let minutes = data_unixtime.getMinutes().toString().padStart(2, '0');
                            let data_fecha = data_unixtime.getDate()+" de "+months[data_unixtime.getMonth()]+" "+hours+":"+minutes;

                            let dias_disp = clave_disponible.Dias_Disponibles_Clave;
                            if(dias_disp<=0){
                                dias_disp="0";
                            }else{
                                dias_disp.toString();
                            }
                            setClave({numero: array_clave, fecha: data_fecha, dias_vigentes: dias_disp});
                            console.log(clave);

                        }
                    }else{
                        setClaveStatus("NoHay");
                        setClave({numero: [], fecha: "No encontrado", dias_vigentes: "No encontrado"});
                    }
                }else if(status === 'error_app'){
                    setClaveStatus("NoDisponible");
                    setClave({numero: [], fecha: "No hay fecha", dias_vigentes: "Sin días"});
                }else{
                    setClaveStatus("NoHay");
                    setClave({numero: [], fecha: "No hay fecha", dias_vigentes: "Sin días"});
                }

            })
            .catch(function (error) {
                if (error.response) {
                // Request made and server responded
                console.log("Error-Response: "+error.response.data);
                console.log("Error-Status: "+error.response.status);
                console.log("Error-Headers: "+error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                console.log('Error-Request: '+error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error-Message: '+ error.message);
                }
            });
        }else{
            return false;
        }
    }

    const getUltimoDeposito = () => {
        if(contrato_numero!== 0 && contrato_numero!== "0"){
            const token = localStorage.getItem('token');
            let json = JSON.stringify(
                {
                    "contrato_meta_cid":contrato_metacid,
                }
            );
            axios.post(process.env.REACT_APP_API_DM_URL+"/api/ultimo_deposito_contrato",{"json":json}, {
                headers: {
                'Authorization' : token,
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
                }
            })
            .then(response => {

                let status = response.data.status;
                let result = response.data;

                if (status === 'success') {
                    let datos = result.cuentas_bancarias;
                    //return(console.log(datos.monto));
                    let newData = {...ultimoDep};
                    newData = {monto: datos.monto, fecha: datos.fecha};
                    setUltimoDep(newData);
                    //console.log(ultimoDep);
                }else{
                    // setClaveStatus("NoHay");
                }

            })
            .catch(function (error) {
                if (error.response) {
                // Request made and server responded
                console.log("Error-Response: "+error.response.data);
                console.log("Error-Status: "+error.response.status);
                console.log("Error-Headers: "+error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                console.log('Error-Request: '+error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error-Message: '+ error.message);
                }
            });
        }
    }

    const entregarClave = () => {
        if(contrato_numero !== 0 && contrato_numero !== "0"){
            const token = localStorage.getItem('token');
            let json = JSON.stringify(
                {
                    "numero_contrato":contrato_numero,
                    "plataforma": "web"
                }
            );
            axios.post(process.env.REACT_APP_API_DM_URL+"/api/entrega_clave_nueva",{"json":json}, {
                headers: {
                'Authorization' : token,
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
                }
            })
            .then(response => {

                //return(console.log(response));
                let status = response.data.status;
                let result = response.data;

                if (status === 'success') {
                    let clave_disponible = result.clave_disponible[0];
                    var months = ['Ene','Feb','Mar','Abr','Mayo','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];

                    //return(console.log(clave_disponible));

                    //clave_disponible.clave_numero = "YES";
                    if(clave_disponible){
                        if (clave_disponible.clave_numero === 'Vencida') {
                            setClaveStatus("Vencida");

                            //Traemos Fecha
                            let data_unixtime = new Date(clave_disponible.fecha_expiracion * 1000);
                            let hours = data_unixtime.getHours().toString().padStart(2, '0');
                            let minutes = data_unixtime.getMinutes().toString().padStart(2, '0');
                            let data_fecha = data_unixtime.getDate()+" de "+months[data_unixtime.getMonth()]+" "+hours+":"+minutes;

                            let dias_disp = clave_disponible.Dias_Disponibles_Clave;
                            if(dias_disp<=0){
                                dias_disp="0";
                            }else{
                                dias_disp.toString();
                            }

                            setClave({numero: [], fecha: data_fecha, dias_vigentes: dias_disp});
                        }else{
                            setClaveStatus("Exito");
                            let clave_var = clave_disponible.clave_numero;
                            //clave_var = "246810";
                            let array_clave = clave_var.split('');

                            //Traemos Fecha
                            let data_unixtime = new Date(clave_disponible.fecha_expiracion * 1000);
                            let hours = data_unixtime.getHours().toString().padStart(2, '0');
                            let minutes = data_unixtime.getMinutes().toString().padStart(2, '0');
                            let data_fecha = data_unixtime.getDate()+" de "+months[data_unixtime.getMonth()]+" "+hours+":"+minutes;

                            let dias_disp = clave_disponible.Dias_Disponibles_Clave;
                            if(dias_disp<=0){
                                dias_disp="0";
                            }else{
                                dias_disp.toString();
                            }
                            setClave({numero: array_clave, fecha: data_fecha, dias_vigentes: dias_disp});
                            //console.log(clave);

                        }
                    }else{
                        setClaveStatus("NoHay");
                        setClave({numero: [], fecha: "No encontrado", dias_vigentes: "No encontrado"});
                    }
                }else if(status === 'error_app'){
                    setClaveStatus("NoDisponible");
                    setClave({numero: [], fecha: "No hay fecha", dias_vigentes: "Sin días"});
                }else{
                    setClaveStatus("NoHay");
                    setClave({numero: [], fecha: "No hay fecha", dias_vigentes: "Sin días"});
                }

            })
            .catch(function (error) {
                if (error.response) {
                // Request made and server responded
                console.log("Error-Response: "+error.response.data);
                console.log("Error-Status: "+error.response.status);
                console.log("Error-Headers: "+error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                console.log('Error-Request: '+error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error-Message: '+ error.message);
                }
            });
        }else{
            return false;
        }
    }

    // console.log(contrato_metacid);

    return (
        <React.Fragment>
            <div className={'option-section'} style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                <div className="option-container">
                    <div className="option-title">
                        <Link to="/portal" className="opt-goBack" >
                            <FaAngleLeft className="go-back-icon"/>
                        </Link>
                        <h1>Clave Datamovil</h1>
                    </div>
                    <div className="option-content">
                        <div className='resumenC__'>
                            <div className="resumenC__datos resumenC__generales">
                                <h2 style={{color: 'white', textShadow: "1px 1px 4px rgba(0,0,0,0.39)"}}>Resumen de Datos</h2>
                            </div>
                            <ClaveCard
                                ultimoDep={ultimoDep}
                                claveStatus={claveStatus}
                                clave={clave}
                                entregarClave={entregarClave}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

