import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {FaAngleLeft} from 'react-icons/fa'
import axios from 'axios';
import './DepFactura.css';
import { bcAlert } from '../../boclAlert';
import ReactGA from 'react-ga';


export default function DepFactura({
    contrato_metacid,
    datosExtras
}) {

    const [definitivos, setDefinitivos] = useState([]);
    const [provisionales, setProvisionales] = useState([]);

    const [defFlag, setDefFlag] = useState({
        status: false,
        descripcion: ""
    });
    const [proFlag, setProFlag] = useState({
        status: false,
        descripcion: ""
    });

    useEffect(() => { 
        let cleanUp = true;

        const getDepProvisionales = () => {
            if(contrato_metacid !== 0 && contrato_metacid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_metacid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/depositos_provisionales",{"json":json}, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(cleanUp){
                        let status = response.data.status;
                        let data = response.data.Depositos_Provisionales;
                        
                        if(status === "success" ){
        
                            let r_provisionales = data;
        
                            if(r_provisionales.length > 0){
        
                                for (let i = 0; i < r_provisionales.length; i++) {
                                    // r_provisionales[i].dm_pago_fecha_registro = r_provisionales[i].dm_pago_fecha_registro.split("-");;
                                    let dia = r_provisionales[i].fecha_deposito.substring(0, 2),
                                    mes = r_provisionales[i].fecha_deposito.substring(3, 6),
                                    year = r_provisionales[i].fecha_deposito.substring(9, 12);
                                    r_provisionales[i].fecha_deposito = [];
                                    r_provisionales[i].fecha_deposito["day"] = dia
                                    r_provisionales[i].fecha_deposito["month"] = mes.toUpperCase();
                                    r_provisionales[i].fecha_deposito["year"] = year
                                    // mes = mes.toUpperCase();
                                    // documento_id = datos_depositos_definitivos[i].factura_documento_id;
                                }
        
                                let newFlag = {...proFlag};
                                newFlag = {status: true, descripcion: "Consulta exitosa"};
                                setProFlag(newFlag);
        
                                let newData = {...provisionales};
                                newData = r_provisionales;
                                setProvisionales(newData);
                            }else{
                                let newFlag = {...proFlag};
                                newFlag = {status: false, descripcion: "No se encontraron datos"};
                                setProFlag(newFlag);
                            }
                            
        
                        }else{
                            let newFlag = {...proFlag};
                            newFlag = {status: false, descripcion: "No hay datos disponibles"};
                            setProFlag(newFlag);
                        }
                    }
                })
                .catch(function (error) {
    
                    let newFlag = {...proFlag};
                    newFlag = {status: false, descripcion: "Hubo un error consultar la información"};
                    setProFlag(newFlag);
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }
    
        const getDepDefinitivos = () => {
            if(contrato_metacid !== 0 && contrato_metacid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_metacid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/depositos_definitivos",{"json":json}, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(cleanUp){
                            let status = response.data.status;
                            let data = response.data.Depositos_Definitivos.map(x => {
                            const fecha_captura = new Date(x.dm_pago_fecha_captura);
                            const start_date = new Date(response.data.startDate);
                            const created_at = new Date(x.created_at)
                            return { ...x, fecha_captura: fecha_captura, start_date: start_date, created_at: created_at }
                        });

                        if(status === "success" ){
        
                            let r_definitivos = data;
        
                            if(r_definitivos.length > 0){
                                
                                for (let i = 0; i < r_definitivos.length; i++) {
                                    // r_definitivos[i].dm_pago_fecha_registro = r_definitivos[i].dm_pago_fecha_registro.split("-");;
                                    let dia = r_definitivos[i].dm_pago_fecha_captura.substring(0, 2),
                                    mes = r_definitivos[i].dm_pago_fecha_captura.substring(3, 6),
                                    year = r_definitivos[i].dm_pago_fecha_captura.substring(9, 12);
                                    r_definitivos[i].dm_pago_fecha_captura = [];
                                    r_definitivos[i].dm_pago_fecha_captura["day"] = dia
                                    r_definitivos[i].dm_pago_fecha_captura["month"] = mes.toUpperCase();
                                    r_definitivos[i].dm_pago_fecha_captura["year"] = year
                                    // mes = mes.toUpperCase();
                                    // documento_id = datos_depositos_definitivos[i].factura_documento_id;
                                }     
        
                                let newFlag = {...defFlag};
                                newFlag = {status: true, descripcion: "Consulta exitosa"};
                                setDefFlag(newFlag);
        
                                let newData = {...definitivos };
                                newData = r_definitivos;
                                setDefinitivos(newData);
                            }else{
                                let newFlag = {...defFlag};
                                newFlag = {status: false, descripcion: "No se encontraron datos"};
                                setDefFlag(newFlag);
                            }
                            
        
                        }else{
                            let newFlag = {...defFlag};
                            newFlag = {status: false, descripcion: "No hay datos disponibles"};
                            setDefFlag(newFlag);
                        }
                    }
                })
                .catch(function (error) {
    
                    let newFlag = {...defFlag};
                    newFlag = {status: false, descripcion: "Hubo un error al consultar la información"};
                    setDefFlag(newFlag);
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }

        getDepProvisionales(contrato_metacid);
        getDepDefinitivos(contrato_metacid);

        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_metacid]);    

    const xml = (contrato_metacid, documento_id) => {
        //ENVIAMOS TRACKING INFO
        ReactGA.event({
            category: 'Boton Portal',
            action: 'Descarga XML'
        });
        if(contrato_metacid !== 0 && contrato_metacid !== "0"){
            const token = localStorage.getItem('token');
            let json = JSON.stringify({"contrato_meta_cid":contrato_metacid, "documento_id":documento_id});
            axios.post(process.env.REACT_APP_API_DM_URL+"/api/facturaXML",{"json":json}, {
                headers: {
                'Authorization' : token,
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response.data.code === 200) {
                    response.data.data.length > 0 ?
                        response.data.data.map(x => {
                            let a = document.createElement("a");
                            a.target = "_blank"
                            document.body.appendChild(a);
                            a.style = "display: none";
                            let xmlDoc = x.Xml;
                            let folio_id = x.FolioDoc.replace(/\s/g, '');
                            let blob = new File([xmlDoc], "Factura_Datamovil_"+folio_id+".xml",  {type: "text/xml"});
                            let url = window.URL.createObjectURL(blob);
                            a.href = url;
                            a.download = blob.name;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        }) :
                        bcAlert({
                            msg : {
                                "title":"Oops!", 
                                "desc":"No se encontraron facturas."
                            },
                            type :"error", 
                            styles : ``, 
                            options : false
                        });
                }else{
                    // alert('Ocurrió un problema con el XML');
                    bcAlert({
                        msg : {
                            "title":"Oops!", 
                            "desc":"Ocurrió un problema con el XML"
                        },
                        type :"error", 
                        styles : ``, 
                        options : false
                    });
                }
            })
            .catch(function (error) {

                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"Ocurrió un problema en la conexión"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
                if (error.response) {
                // Request made and server responded
                console.log("Error-Response: "+error.response.data);
                console.log("Error-Status: "+error.response.status);
                console.log("Error-Headers: "+error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                console.log('Error-Request: '+error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error-Message: '+ error.message);
                }
            });
        }
    }

    function pdf(contrato_metacid, documento_id){

        //ENVIAMOS TRACKING INFO
        ReactGA.event({
            category: 'Boton Portal',
            action: 'Descarga PDF'
        });
        if(contrato_metacid !== 0 && contrato_metacid !== "0"){
        
            const token = localStorage.getItem('token');
            let json = JSON.stringify({"contrato_meta_cid":contrato_metacid, "documento_id":documento_id});
            axios.post(process.env.REACT_APP_API_DM_URL+"/api/facturaPDF",{"json":json}, {
                headers: {
                'Authorization' : token,
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if(response?.data.code === 200) {
                    response.data.data.length > 0 ?
                        makePdf(response.data.data) : 
                        bcAlert({
                            msg : {
                                "title":"Oops!", 
                                "desc":"No se encontraron facturas."
                            },
                            type :"error", 
                            styles : ``, 
                            options : false
                        });
                } 
            })
            .catch(function (error) {

                // alert("Hubo un problema al consultar la información");
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"Hubo un problema al consultar la información"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
                if (error.response) {
                // Request made and server responded
                console.log("Error-Response: "+error.response.data);
                console.log("Error-Status: "+error.response.status);
                console.log("Error-Headers: "+error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                console.log('Error-Request: '+error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error-Message: '+ error.message);
                }
            });
        }
    }

    function pdfComplemento(contrato_metacid, documento_id){
        //ENVIAMOS TRACKING INFO
        ReactGA.event({
            category: 'Boton Portal',
            action: 'Descarga PDF'
        });
        if(contrato_metacid !== 0 && contrato_metacid !== "0"){
        
            const token = localStorage.getItem('token');
            let json = JSON.stringify({"contrato_meta_cid":contrato_metacid, "documento_id":documento_id});
            axios.post(process.env.REACT_APP_API_DM_URL+"/api/facturaComplemento",{"json":json}, {
                headers: {
                    'Authorization' : token,
                    'Accept': 'application/pdf', // Aceptar archivos PDF en la respuesta
                    'Content-Type': 'application/json'
                },
                responseType: 'blob' // Indicar que la respuesta es un blob (archivo binario)
            })
            .then(response => {
                // Crear un objeto URL para el blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                // Crear un enlace para descargar el archivo
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'complemento.pdf');
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(function (error) {

                // alert("Hubo un problema al consultar la información");
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"Hubo un problema al consultar la información"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
                if (error.response) {
                // Request made and server responded
                console.log("Error-Response: "+error.response.data);
                console.log("Error-Status: "+error.response.status);
                console.log("Error-Headers: "+error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                console.log('Error-Request: '+error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error-Message: '+ error.message);
                }
            });
        }
    }

    // function makePdf(res, doc_id){
    //     const token = localStorage.getItem('token');
    //     axios.post(process.env.REACT_APP_API_DM_URL+"/api/descargar_pdf",{"json":res}, {
    //         headers: {
    //         'Authorization' : token,
    //         'Accept': 'application/json, text/plain, */*',
    //         'Content-Type': 'application/json'
    //         }
    //     })
    //     .then(response => {
    //         if (response.status !== 'error') {
    //             axios.get(process.env.REACT_APP_API_DM_URL+"/api/imprimir_pdf/"+contrato_metacid+'/'+doc_id, {
    //                 headers: {
    //                 'Authorization' : token,
    //                 'Accept': 'application/json, text/plain, */*',
    //                 'Content-Type': 'application/json'
    //                 }
    //             })
    //             .then(response => {
    //                 if (response.status === 'error') {
    //                     // alert('Ocurrió un problema al dibujar PDF');
    //                     bcAlert({
    //                         msg : {
    //                             "title":"Oops!", 
    //                             "desc":"Ocurrió un problema al dibujar PDF"
    //                         },
    //                         type :"error", 
    //                         styles : ``, 
    //                         options : false
    //                     });
    //                 }else{
    //                     let a = document.createElement("a");
    //                     document.body.appendChild(a);
    //                     a.target = "_system"
    //                     a.style = "display: none";
    //                     a.href = `${process.env.REACT_APP_API_DM_URL}/api/imprimir_pdf/`+contrato_metacid+'/'+doc_id;
    //                     a.click();
    //                 }
    //             })
    //         }
    //     })
    //     .catch(function (error) {
    //         // alert("Hubo un problema al descargar el pdf");
    //         bcAlert({
    //             msg : {
    //                 "title":"Oops!", 
    //                 "desc":"Hubo un problema al descargar el pdf"
    //             },
    //             type :"error", 
    //             styles : ``, 
    //             options : false
    //         });
    //         if (error.response) {
    //         // Request made and server responded
    //         console.log("Error-Response: "+error.response.data);
    //         console.log("Error-Status: "+error.response.status);
    //         console.log("Error-Headers: "+error.response.headers);
    //         } else if (error.request) {
    //         // The request was made but no response was received
    //         console.log('Error-Request: '+error.request);
    //         } else {
    //         // Something happened in setting up the request that triggered an Error
    //         console.log('Error-Message: '+ error.message);
    //         }
    //     });

    // }

    const makePdf = async (facturas) => {

        const token = localStorage.getItem('token');
    
        for (let index = 0; index < facturas.length; index++) {
            const folio = facturas[index].folio[0];
            try {
                const response = await axios.get(process.env.REACT_APP_API_DM_URL+"/api/imprimir_pdf/"+contrato_metacid+'/'+folio, {
                    headers: {
                        'Authorization': token,
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    responseType: 'blob' // Solicitar el tipo de respuesta como un blob
                });
                
                if (response.status === 200) {
                    // Crear una URL para el blob y crear un enlace visible
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.target = "_blank"; // Abrir en una nueva pestaña
                    a.download = `factura_${folio}.pdf`; // Nombre de archivo para descargar
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url); // Liberar el objeto URL
                    document.body.removeChild(a); // Eliminar el enlace del DOM después de descargar
                }
            } catch (error) {
                console.error("Error downloading PDF:", error);
            }
        }
    };
    
    
    

    return (
        <React.Fragment>
            <div className={'option-section'} style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                <div className="option-container">
                    <div className="option-title">
                        <Link to="/portal" className="opt-goBack" >
                            <FaAngleLeft className="go-back-icon"/>
                        </Link>
                        {
                            datosExtras.showFactura === 1 ?
                            <h1>Depósitos y Facturación</h1>
                            :
                            <h1>Depósitos</h1>
                        }
                        
                    </div>
                    <div className="option-content">
                        <div className='titles'>
                            <p className='sh-darkblue text-fac'>
                                <img className="dm-icon dep__file-icon-text" src={`${process.env.PUBLIC_URL}/images/dm-icons/dm-pdf.svg`} alt="pdf-icon"/>
                                Factura
                            </p>
                            <p className='sh-darkgreen text-cp'>
                                <img className="dm-icon dep__file-icon-c-text" src={`${process.env.PUBLIC_URL}/images/dm-icons/dm-pay.svg`} alt="pdf-icon"/>
                                Complemento de Pago
                            </p>
                        </div>
                        <div className="dep-all-container">
                            {/* PROVISIONALES___________________________ */}
                            <div className="dep-type dep-provisionales">
                            <div className="option-subtitle">
                                    <h1>Depósitos De Hoy</h1>
                                </div>
                                <div className="depositos-container">
                                    {
                                        proFlag.status ?
                                        provisionales.map((pro, i) => {     
                                                // Return the element. Also pass key     
                                                return (
                                                    <div className="dep__card dep__card-today" key={i}>
                                                        <div className="dep__card-data dep__card-fecha">
                                                            <h1>{pro.fecha_deposito["day"]}</h1>
                                                            <p>{pro.fecha_deposito["month"]} {pro.fecha_deposito["year"]}</p>
                                                        </div>
                                                        <div className="dep__card-data dep__card-monto">
                                                            <p>$ {pro.abono_deposito}</p>
                                                        </div>
                                                        {/* <div onClick={() => {xml(contrato_metacid, pro.factura_documento_id)}} className="dep__card-data dep__card-file dep__card-xml sh-skyblue" id="download-xml">
                                                            <img className="dm-icon dep__file-icon" src={`${process.env.PUBLIC_URL}/images/dm-icons/dm-xml.svg`} alt="xml-icon"/>
                                                        </div>
                                                        <div onClick={() => {pdf(contrato_metacid, pro.factura_documento_id)}} className="dep__card-data dep__card-file dep__card-pdf sh-darkblue" id="download-pdf">
                                                            <img className="dm-icon dep__file-icon" src={`${process.env.PUBLIC_URL}/images/dm-icons/dm-pdf.svg`} alt="pdf-icon"/>
                                                        </div> */}
                                                    </div>
                                                ) 
                                        })
                                        :
                                        <div className="dep__card dep__card-last10">
                                            <div className="dep__card-empty">
                                                <p>{proFlag.descripcion}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* DEFINITIVOS___________________________ */}
                            <div className="dep-type dep-definitivos">
                                <div className="option-subtitle">
                                    <h1>Depósitos Anteriores</h1>
                                </div>
                                <div className="depositos-container">
                                    {
                                        defFlag.status ?
                                        definitivos.map((def, i) => {     
                                                // Return the element. Also pass key     
                                                return (
                                                    <div className="dep__card dep__card-last10" key={i}>
                                                        <div className="dep__card-data dep__card-fecha">
                                                            <h1>{def.dm_pago_fecha_captura["day"]}</h1>
                                                            <p>{def.dm_pago_fecha_captura["month"]} {def.dm_pago_fecha_captura["year"]}</p>
                                                        </div>
                                                        <div className={`dep__card-data ${!def.complemento ? 'dep__card-monto-sc' : 'dep__card-monto'}`}>
                                                            <p>$ {def.dm_pago_monto}</p>
                                                        </div>
                                                        
                                                            {
                                                                def.show_factura !== 1 && def.show_factura !== "1" ?
                                                                <React.Fragment>
                                                                    <div className='empty_dep_Button '></div>
                                                                </React.Fragment>
                                                                : (def.fecha_captura >= def.start_date && def.fecha_captura <= def.created_at) || (def.regimen_fiscal == null && def.fecha_captura >= def.start_date) ?
                                                                <React.Fragment>
                                                                    <div className="dep__card-data dep__card-file dep__card-pdf-text sh-darkblue" id="download-pdf">
                                                                        <p className="dep__text" >Facturado Publico en General</p>
                                                                    </div>
                                                                </React.Fragment>
                                                            :
                                                                    def.factura_documento_id !== null || def.factura_documento_id !== "null" ?
                                                                    <React.Fragment>
                                                                        <div 
                                                                            onClick={() => {xml(contrato_metacid, def.factura_documento_id)}} 
                                                                            className={`dep__card-data dep__card-file ${!def.complemento ? 'dep__card-xml-sc' : 'dep__card-xml'} sh-skyblue`} 
                                                                            id="download-xml">
                                                                            <img className="dm-icon dep__file-icon" src={`${process.env.PUBLIC_URL}/images/dm-icons/dm-xml.svg`} alt="xml-icon"/>
                                                                        </div>
                                                                        <div 
                                                                            onClick={() => {pdf(contrato_metacid, def.factura_documento_id)}} 
                                                                            className={`dep__card-data dep__card-file ${!def.complemento ? 'dep__card-pdf-sc' : 'dep__card-pdf'}  sh-darkblue`} 
                                                                            id="download-pdf">
                                                                            <img className="dm-icon dep__file-icon" src={`${process.env.PUBLIC_URL}/images/dm-icons/dm-pdf.svg`} alt="pdf-icon"/>
                                                                        </div> 
                                                                        {def.complemento &&
                                                                            <div 
                                                                                onClick={() => {pdfComplemento(contrato_metacid, def.factura_documento_id)}} 
                                                                                className="dep__card-data dep__card-file dep__card-pdf-c sh-darkgreen" 
                                                                                id="download-complement">
                                                                                <img className="dm-icon dep__file-icon-c" src={`${process.env.PUBLIC_URL}/images/dm-icons/dm-pay.svg`} alt="pdf-icon"/>
                                                                            </div>
                                                                        }
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <div className="dep__card-data dep__card-file dep__card-xml sh-skyblue" id="download-xml">
                                                                            <p className="dm-icon dep__file-icon" >N/D</p>
                                                                        </div>
                                                                        <div className="dep__card-data dep__card-file dep__card-pdf sh-darkblue" id="download-pdf">
                                                                            <p className="dm-icon dep__file-icon" >N/D</p>
                                                                        </div>
                                                                        <div className="dep__card-data dep__card-file dep__card-pdf-c sh-darkgreen" id="download-complement">
                                                                            <p className="dm-icon dep__file-icon-c" >N/D</p>
                                                                        </div>
                                                                    </React.Fragment> 
                                                            }
                                                        
                                                        
                                                    </div>
                                                ) 
                                        })
                                        :
                                        <div className="dep__card dep__card-last10">
                                            <div className="dep__card-empty">
                                                <p>{defFlag.descripcion}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
