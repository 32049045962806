  
export const SliderData = [
    {
      image:
        '/images/home/hero/banner1_2.webp',
      vp: 'isp_top',
      hp: 'isp_left',
      df: 'isf_h',
      up_image: 'p1.webp',
      button: {href: "https://autosdatamovil.com/automoviles/", label: "Encuentra tu auto", bottom: "bottom05", horizontalPosition: "left", color:"blue"}
    },
    {
      image:
        '/images/home/hero/banner2_2.webp',
      vp: 'isp_top',
      hp: 'isp_left',
      df: 'isf_h',
      up_image: 'p2_2.webp',
      button: {href: "https://autosdatamovil.com/automoviles/", label: "Encuentra tu auto", bottom: "bottom10", horizontalPosition: "left", color:"blue"}
    },
    {
      image:
        '/images/home/hero/banner3_2.webp',
      vp: 'isp_top',
      hp: 'isp_left',
      df: 'isf_h',
      up_image: 'p3_2.webp',
      button: {href: "https://autosdatamovil.com/automoviles/", label: "Encuentra tu auto", bottom: "bottom30", horizontalPosition: "left", color:"blue"}
    }
  ];