import React from 'react';
// import axios from 'axios';
import './ContratoCard.css';
import {TiDelete} from 'react-icons/ti'

const ContratoCard=(props)=>{
    return (
        <div className="card_Contrato" id={`card_lista_contratos_${props.contrato_metacid}`}>
            <button className="deleteC" onClick={() => props.eliminarContrato(props.contrato_metacid)}>
            </button>
            <TiDelete className='deleteC_svg'/>
            <div className="card_Contrato-body">
                <div className="card_Contrato-content">
                    <div className="selectC selectC__ref">
                        <p>Referencia</p>
                        <h3 id="referencia">{props.referencia}</h3>
                    </div>
                    <div className="selectC selectC__datos">
                        <h3>{props.cliente}</h3>
                        <h3>{props.auto}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContratoCard