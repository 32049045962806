import React, { useEffect } from 'react'
import { useHistory  } from 'react-router-dom'
import axios from 'axios';
import './BeneficiosSeguro.css';

const BenSeguro = (props) => {
  const history = useHistory();
  useEffect(() => {
    if (props.contrato_metacid === 0) {
      history.push('/portal')
    }
  }, [])

  const openPage = async url => {
    const token = localStorage.getItem('token');
    let json = JSON.stringify({
        "contrato_metacid": props.contrato_metacid,
        "referencia_corta": props.num_contrato,
        "nombre_cliente": props.nombre_cliente,
      });

     await axios.post(process.env.REACT_APP_API_DM_URL+"/api/registrar_usuario_beneficios",{"json":json}, {
      headers: {
        'Authorization' : token,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      }).then(response => {
        if(response.data.code === 200) {
          window.open(url, '_blank', 'noopener,noreferrer');
        }
      })
      .catch( (error) => {
        console.log(error)
      });
  }

  return (
    <>
    <div className='divTexto'>
      <p className='parrafo'>
            Por ser cliente de <span>ARRENDADORA DATAMOVIL</span> te extendemos el beneficio de cotizar,
            comparar y contratar <span>SEGUROS</span> en línea, con los mejores precios y aseguradoras del mercado.</p>
      <button className='btnIngresar' onClick={() => openPage('https://www.odessaseguros.com/datamovil.html')}>Ingresa Aquí</button>
    </div>
    <div className='divTexto'>
      <p className='aviso'><span>AVISO: </span>Este servicio es adicional al seguro que tienes actualmente en tu crédito.</p>
    </div>
    </>
  )
}

export default BenSeguro