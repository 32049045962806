import React, {useEffect} from 'react';
import HeroSection from '../../generales/HeroSection/HeroSection'
import ContactSection from './ContactSection/ContactSection';
import {homeObjOne} from './Data_contacto'
import GoogleApiWrapper from '../../generales/MapSection/MapSection';


function Contacto() {

    useEffect(() => {
        
        document.querySelector("#ctc1 svg").classList.add("transitionIn_floatRightLeft");
        document.querySelector("#ctc1 svg #layer2").style.cssText = `
            -webkit-transform-origin: 50% 70%;
            -moz-transform-origin: 50% 70%;
            -o-transform-origin: 50% 70%;
            transform-origin: 50% 70%;
            width: 256px;
            height: 256px;`
        // setTimeout(function(){ 
            document.querySelector("#ctc1 svg #layer2").classList.add("animation_shorttempo");
        // }, 1000);
    }, []);

    return (
        <React.Fragment>
         <HeroSection {...homeObjOne} />
         <ContactSection />
         <GoogleApiWrapper />
        </React.Fragment>
    )
}

export default Contacto