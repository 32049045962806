import React, {useEffect} from 'react';
import '../../../portal/Login/LogSign.css'
import Calculadora from '../../Cotizador/Componentes/Calculadora/Calculadora';
// import {homeCotizador} from '../Data_home'
import './CotizadorHero.css'

export default function CotizadorHero() {

    const bgImage = "images/car_bg.png"

    useEffect(() => { 
        // document.querySelector("#cotizador_img svg #layer4").classList.add("animation_jelly");
        // document.querySelector("#cotizador_img svg #layer4").classList.add("animation_verticalsqueesh");
        // document.querySelector("#cotizador_img svg #layer1").classList.add("animation_shorttempo");
        // document.querySelector("#cotizador_img svg #layer3").classList.add("animation_verticalsqueesh");
        // document.querySelector("#cotizador_img svg #layer2").classList.add("animation_slowhorizontalflow");
    }, []);

    return (
        <React.Fragment>
            <div id="cotizadorHeroBg" style={{ backgroundImage: 'url(' + bgImage + ')', backgroundColor: 'rgb(241,241,241)'}}>   
                <div id={"cotizadorHero"} className={'home__hero-section textLeft'}>
                    <div className="home__hero-container">
                        <div className="row home__hero-row"
                        style={{display: 'flex', flexDirection: 'row-reverse'}}>
                            <div className="col textLeft">
                                <div className="home__hero-img-wrapper">
                                        {/* <div id={homeCotizador.alt} className={`home__hero-img `} style={{width: homeCotizador.imgSize}} dangerouslySetInnerHTML={{__html: homeCotizador.img}}></div> */}
                                        {/* <div id={homeCotizador.alt} className={`home__hero-img `} style={{ backgroundImage: 'url(' + bgImage + ')' }}></div>    */}                            </div>
                            </div> 
                            <div className="col col-calculadoraCredito">
                                <Calculadora />
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
