import React from 'react';
// import { Button } from '../../../generales/Button';
import './Requisitos.css';
import { FaUser } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { FaUserShield } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
// import { Link } from 'react-router-dom';

function Requisitos() {
    return (
        <IconContext.Provider value={{ color: '#fff', size: 64 }}>
        <div className='requisitos__section'>
          <div className='requisitos__wrapper'>
            <h1 className='requisitos__heading'>Requisitos Para Crédito</h1>
            <div className='requisitos__container'>
              <div className='requisitos__container-card'>
                <div className='requisitos__container-cardInfo'>
                  <div className='icon'>
                    <FaUser />
                  </div>
                  <h3>Persona Física</h3>
                  {/* <h4>$8.99</h4>
                  <p>per month</p> */}
                  <ul className='requisitos__container-features'>
                    <li>Solicitud firmada por principal y cónyuge (en caso de tenerlo).</li>
                    <li>Copia de identificación con fotografía vigente del solicitante y cónyuge en caso de tenerlo (credencial de elector o pasaporte).</li>
                    <li>Copia de comprobante de domicilio reciente: recibo de pago predial, agua, luz o gas.</li>
                    <li>Copia de comprobante de ingresos, recibo de nómina y/o estado de cuenta bancario de los últimos 3 meses.</li>
                  </ul>
                  {/* <Button buttonSize='btn--wide' buttonColor='primary'>
                    Choose Plan
                  </Button> */}
                </div>
              </div>
              <div className='requisitos__container-card'>
                <div className='requisitos__container-cardInfo'>
                  <div className='icon'>
                    <FaUsers />
                  </div>
                  <h3>Persona Moral</h3>
                  {/* <h4>$29.99</h4>
                  <p>per month</p> */}
                  <ul className='requisitos__container-features'>
                    <li>Solicitud firmada por representante legal.</li>
                    <li>Copia de acta constitutiva y poderes recientes.</li>
                    <li>Copia de alta de Hacienda y copia de cédula fiscal.</li>
                    <li>Copia de de los 3 últimos estados de cuenta bancarios.</li>
                    <li>Copia de comprobante de domicilio de la persona moral: recibo de pago predial, agua, luz o gas.</li>
                    <li>Copia de identificación con fotografía vigente del representante legal (credencial de elector o pasaporte).</li>
                    <li>Copia de comprobante de domicilio reciente del representante legal: recibo de pago predial, agua, luz o gas.</li>
                  </ul>
                  {/* <Button buttonSize='btn--wide' buttonColor='primary'>
                    Choose Plan
                  </Button> */}
                </div>
              </div>
              <div className='requisitos__container-card'>
                <div className='requisitos__container-cardInfo'>
                  <div className='icon'>
                    <FaUserShield />
                  </div>
                  <h3>Aval</h3>
                  {/* <h4>$99.99</h4>
                  <p>per month</p> */}
                  <ul className='requisitos__container-features'>
                    <li>Solicitud firmada por aval.</li>
                    <li>Copia de identificación con fotografía vigente del aval (credencial de elector o pasaporte).</li>
                    <li>Copia de comprobante de domicilio reciente: recibo de pago predial, agua, luz o gas.</li>
                  </ul>
                  {/* <Button buttonSize='btn--wide' buttonColor='primary'>
                    Choose Plan
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IconContext.Provider>
    );
  }
  export default Requisitos;