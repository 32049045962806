import React, {useEffect} from 'react';
import './Footer.css';
// import { Button } from '../../generales/Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube
} from 'react-icons/fa';
import ReactGA from 'react-ga';

function Footer() {

  useEffect(() => {
        
    let android = document.getElementById("app_play");
    android.addEventListener("click", sendTrackingInfo);
    // android.myParam = 'Link Celulares';

    let ios = document.getElementById("app_ios");
    ios.addEventListener("click", sendTrackingInfo);
    // ios.myParam = 'Link Celulares';

    let huawei = document.getElementById("app_huawei");
    huawei.addEventListener("click", sendTrackingInfo);
    // huawei.myParam = 'Link Celulares';

    function sendTrackingInfo(){
        // alert(actionName.currentTarget.myParam);
        ReactGA.event({
            category: 'Link Landing',
            action: 'Link Download Stores (Footer)'
        });
    }
    
  }, []);

  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Sobre Nosotros</h2>
            <Link to='/nosotros'>Quiénes somos</Link>
            <a target="_blank" rel="noopener noreferrer" href={"https://www.google.com/maps/place/Arrendadora+Datamovil/@25.6645653,-100.3836163,15z/data=!4m2!3m1!1s0x0:0x25a4efee0d033a4?sa=X&ved=2ahUKEwjFi53Z8tL0AhWfmmoFHc1LB9wQ_BJ6BAhjEAU"}>Ubicación</a>
            <Link to='/aviso-privacidad'>Aviso de privacidad</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Descarga nuestra app</h2>
            <a href={"https://play.google.com/store/apps/details?id=com.datamovil.datamovil&hl=es_MX&gl=US"} className="" target="blank"><img src="./images/down_playstore.png" alt="playstore" id="app_play" className="download-app-img"/></a>
            <a href={"https://apps.apple.com/mx/app/datamovil-go/id1441590066"} className="" target="blank"><img src="./images/down_appstore.png" alt="appstore" id="app_ios" className="download-app-img"/></a>
            <a href={"https://appgallery.huawei.com/#/app/C103010887"} className="" target="blank"><img src="./images/down_appgallery.png" alt="appgallery" id="app_huawei" className="download-app-img"/></a>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items footer-link-contact'>
              <h2>Contáctanos</h2>
              <p>atencionclientes@arrendadoradatamovil.com</p>
              <p>Tel Mty: (81) 8675-4333</p>
              <p>Tel Ags: (449) 624 4280</p>
            </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <img src="./images/logo.svg" alt="logo" className="logo-datamovil-footer"/>
            </Link>
          </div>
          <small className='website-rights'><a href='https://empleados.arrendadoradatamovil.com:4001' target="blank">Login</a> | Datamovil ©<img src="./images/dm-icons/dm-copyright.png" alt="" title="i♥🐿️" style={{opacity:"0",position: "absolute",right:"33px",top:"2px",width:"13px"}}></img> 2020</small>
          <div className='social-icons'>
            <a href={"https://www.facebook.com/autosdatamovil"} aria-label="facebook" className="social-icon-link" target="blank">
              <FaFacebook />
            </a>
            <a href={"https://www.instagram.com/autosdatamovil/"} aria-label="instagram" className="social-icon-link" target="blank">
              <FaInstagram />
            </a>
            <a href={"https://www.youtube.com/channel/UC91SsvESjEbXTSOcnxPQZWQ"} aria-label="youtube" className="social-icon-link" target="blank">
              <FaYoutube />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;