export const card = {
    CEDIS: {
        titulo: 'CEDIS',
        subtitulo: 'Av. Félix U Gómez #101-A, Col Obrera, C.P. 64010, Monterrey,  N.L.',
        parrafo: '(81)8342.4715',
        img: 'images/home/sucursales/CEDIS.webp',
        link: 'https://www.google.com/maps/dir//Calle+Aramberri+101A+Centro+64000+Monterrey,+N.L./@25.6746098,-100.2978324,17z/data=!4m5!4m4!1m0!1m2!1m1!1s0x8662bfd640293a0d:0xcd1f113c4e6d08c6',
        track: true
    },
    Angeles: {
        titulo: 'Los Ángeles',
        subtitulo: 'Ave. Los Angeles 3450, Colonia Benito Juárez, 64785 Monterrey, N.L.',
        parrafo: '(81)8377.0703',
        img: 'images/home/sucursales/LosAngeles.webp',
        link: 'https://www.google.com/maps/dir//Autos+Datamovil+Av.+Los+Angeles+3450+Industrial+64785+Monterrey,+N.L./@25.7077663,-100.270659,12z/data=!4m5!4m4!1m0!1m2!1m1!1s0x86629438ab7a5bdf:0x8ee6e6f84e969d16',
        track: true
    },
    Aguascalientes: {
        titulo: 'Aguascalientes',
        // subtitulo: 'Av. Aguascalientes Oriente 2604, Fracc. Ojocaliente 20196, Aguascalientes Ags.',
        subtitulo: 'Monte Everest 108, Bosques del Prado Nte., 20127 Aguascalientes, Ags.',
        parrafo: '(44)9624.4280',
        // img: 'images/home/sucursales/Aguascalientes1.webp',
        img: 'images/home/sucursales/MicrosoftTeams-image.png',
        // link: 'https://www.google.com/maps/place/Autos+Datamovil+Aguascalientes/@21.8747438,-102.2568587,17z/data=!3m1!4b1!4m5!3m4!1s0x8429efa6937e7c43:0x19669ce1e473c61d!8m2!3d21.8747249!4d-102.2553341?hl=en',
        link: 'https://www.google.com/maps/place/Monte+Everest+108,+Bosques+del+Prado+Nte.,+20127+Aguascalientes,+Ags./@21.9175273,-102.314703,17z/data=!3m1!4b1!4m5!3m4!1s0x8429ef1d36ed414d:0x1d974a9c94d87917!8m2!3d21.9175223!4d-102.3125143?hl=en',
        track: true
    },
    Gonzalitos: {
        titulo: 'Gonzalitos',
        subtitulo: 'Dr. José Eleuterio González, Mitras Nte., 64320 Monterrey, N.L.',
        parrafo: '(81)8371.9000',
        img: 'images/home/sucursales/Gonzalitos.webp',
        link:'https://www.google.com/maps/dir//Autos+Datamovil+Av.+Dr.+Jos%C3%A9+Eleuterio+Gonz%C3%A1lez+2850+Mitras+Nte.+64320+Monterrey,+N.L.,+M%C3%A9xico/@25.7110421,-100.3505666,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x866296787dea6a03:0x6445ea4c9245eeb1',
        track: true
    },
    Guadalupe: {
        titulo: 'Guadalupe',
        subtitulo: 'Av. Pdte. Benito Juárez Ote. 1514, Exposición, 67150 Guadalupe, N.L.',
        parrafo: '(81)8337.8330',
        img: 'images/home/sucursales/Guadalupe.webp',
        link: 'https://www.google.com/maps/dir//Av.+Benito+Ju%C3%A1rez+1514+Exposici%C3%B3n+67150+Guadalupe,+N.L./@25.6783781,-100.2381531,12z/data=!4m5!4m4!1m0!1m2!1m1!1s0x8662c01f8e1090f1:0x6ea850e6d385f9a2',
        track: true
    },
    SantoDomingo: {
        titulo: 'Santo Domingo',
        subtitulo: 'Ave. Santo Domingo 101, Jardines de San Nicolás, 66414, San Nicolás, N.L.',
        parrafo: '(81)1930.4191',
        img: 'images/home/sucursales/StoDomingo.webp',
        link: 'https://www.google.com/maps/dir//Autos+Datamovil+Av.+Santo+Domingo+101+Jardines+de+San+Nicol%C3%A1s+66414+San+Nicol%C3%A1s+de+los+Garza,+N.L./@25.7548733,-100.2792777,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x866294bc367d0beb:0x34c38cd5a2355dbc',
        track: true
    },
    Venustiano: {
        titulo: 'Venustiano Carranza',
        subtitulo: 'Venustiano Carranza Sur 479, Centro, 64000 Monterrey, N.L.',
        parrafo: '(81)8040.5735',
        img: 'images/home/sucursales/Venustiano_Carranza.webp',
        link: 'https://www.google.com/maps/dir//Venustiano+Carranza+Sur+479+Maria+Luisa,+Centro+64000+Monterrey,+N.L.+M%C3%A9xico/@25.674168,-100.3324664,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x866295f5e435f7fb:0x284052d61d42bfa9',
        track: true
    },
}