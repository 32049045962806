import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import {FaAngleLeft} from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import SelectFormaPago from './SelectFormaPago/SelectFormaPago';
import './FormasPago.css';
import {lugaresPago_transfer, lugaresPago_efectivo} from './DataFormasPago'
import SelectTipoPago from './SelectTipoPago/SelectTipoPago';

const FormasPago=(props)=>{
    const [fPagoTipo_actual, setFPagoTipoActual] = useState("1");
    const [fPagoLugar_actual, setFPagoLugarActual] = useState("seven");

    const [fPagoLugarEfectivo_actual, setFPagoLugarEfeActual] = useState("seven");
    const [fPagoLugarTransfer_actual, setFPagoLugarTraActual] = useState("spei");

    const [lugaresDePago, setLugaresDePago] = useState(lugaresPago_efectivo);

    const [msjFideicomiso, setMsjFideicomiso] = useState("");

    const [datosPago, setDatosPago] = useState({
        clabe: "Cargando...",
        referencia: "Cargando...",
        monto: "Cargando...",
        emisora: "Cargando...",
    });

    useEffect(() => {
        let cleanUp = true;

        const getDatosPago = () => {
            if(props.contrato_metacid !== 0 || props.contrato_metacid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":props.contrato_metacid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/ficha_deposito_movil",{"json":json }, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                }) 
                .then(response => { 
                    if(cleanUp){
                        // console.log(response);return;
                        let status = response.data.status;
                        let data = response.data;
                        
                        // return(console.log(data))
                        
                        if(status === "success" ){

                            if(response.data.fideicomiso === 1) {
                                localStorage.setItem("msjFideicomiso", response.data.mensaje_fideicomiso)
                            } else {
                                localStorage.removeItem("msjFideicomiso")
                            }
        
                            let datosBanco = data.cuentas_bancarias[0];

                            let d_monto = data.monto_pagar ? data.monto_pagar : "Dato No Encontrado";
                            let d_clabe = datosBanco.dm_cuenta_bancaria_clabe ? datosBanco.dm_cuenta_bancaria_clabe : "Dato No Encontrado";
                            let d_emisora = datosBanco.dm_cuenta_bancaria_numero_archivo ? datosBanco.dm_cuenta_bancaria_numero_archivo : "Dato No Encontrado";
                            let d_referencia = datosBanco.referencia_completa ? datosBanco.referencia_completa : "Dato No Encontrado";

                            let newData = {...datosPago};
                            newData = {clabe: d_clabe, referencia: d_referencia, monto: "$"+d_monto, emisora: d_emisora};
                            setDatosPago(newData);
                            // console.log(datosPago);
                            
        
                        }else{
                            let newData = {...datosPago};
                            newData = {clabe: "Dato No Disponible", referencia: "Dato No Disponible", monto: "Dato No Disponible", emisora: "Dato No Disponible"};
                            setDatosPago(newData);
                        }
                    }
                })
                .catch(function (error) {
    
                    let newData = {...datosPago};
                    newData = {clabe: "Dato No Consultado", referencia: "Dato No Consultado", monto: "Dato No Consultado", emisora: "Dato No Consultado"};
                    setDatosPago(newData);
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }
        
        getDatosPago();

        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contrato_metacid])

    /*FUNCIONES__________________
    _____________________________
    ___________________________*/

    //Sidebar show/hide handler

    const changeFormaPago = e => {
        let newData = {...fPagoLugar_actual} 
        newData = e.target.value;
        setFPagoLugarActual(newData);
    };

    const changeTipoPago = e => {
        let newTipo = fPagoTipo_actual; 
        newTipo = e.target.value;
        setFPagoTipoActual(newTipo);

        // cambio de estado de lugares de pago
        // Se asigna los lugares de pago segun el tipo
        let newLugar = lugaresDePago 
        if(newTipo==="1"){
            newLugar = lugaresPago_efectivo;

            let newActualTran = {...fPagoLugarTransfer_actual} 
            newActualTran = document.getElementById("select_lugarPago").value;
            setFPagoLugarTraActual(newActualTran);

            // Cambio de estado de lugar actual
            // Se asigna el lugar actual que esta seleccionado
            let newData = {...fPagoLugar_actual} 
            newData = fPagoLugarEfectivo_actual
            setFPagoLugarActual(newData);
        }else{
            newLugar = lugaresPago_transfer;

            let newActualEfe = {...fPagoLugarEfectivo_actual} 
            newActualEfe = document.getElementById("select_lugarPago").value;
            setFPagoLugarEfeActual(newActualEfe);

            // Cambio de estado de lugar actual
            // Se asigna el lugar actual que esta seleccionado
            let newData = {...fPagoLugar_actual} 
            newData = fPagoLugarTransfer_actual;
            setFPagoLugarActual(newData);
        }
        setLugaresDePago(newLugar);
        // console.log(lugaresDePago)
    };

    // console.log(fPagoLugar_actual)
    return (
        <React.Fragment>
            <div className={'option-section'} style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                <div className="option-container">
                    <div className="option-title">
                        <Link to="/portal" className="opt-goBack" >
                            <FaAngleLeft className="go-back-icon"/>
                        </Link>
                        <h1>Formas De Pago</h1>
                    </div>
                    <div className="option-content">
                        <IconContext.Provider value={{ color: '#fff'}} >
                            <div className="fpago_container">
                                <div className="fpago_options">
                                    <SelectTipoPago 
                                        changeTipoPago={changeTipoPago}
                                        fPagoTipo_actual={fPagoTipo_actual}
                                    />
                                </div>
                                <SelectFormaPago 
                                    // listaLugares={test === 1 ? lugaresPago_efectivo : lugaresPago_transfer} 
                                    listaLugares={lugaresDePago}
                                    fLugarActual={fPagoLugar_actual}
                                    changeFormaPago={changeFormaPago}
                                    tipoActual={fPagoTipo_actual}
                                    contrato_metacid={props.contrato_metacid}
                                    datosPago = {datosPago}
                                />
                            </div>
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
            <div className='fideicomiso'>
                <h1 className='mensaje_fideicomiso'>{localStorage.getItem("msjFideicomiso")}</h1>
            </div>
        </React.Fragment>
    )
}

export default FormasPago