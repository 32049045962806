import React from 'react';
import './Oficinas.css'

class Oficinas extends React.Component {

  render() {
    return(
      <div className="contacto_oficinas">
          <div className="oficina">
                <h1>Monterrey</h1>
                {/* direccion */}
                <p>Plaza San Pedro Locales F18, G1 Y G2 Av. Humberto Lobo #520,</p>
                {/* colonia */}
                <p>Col. del Valle, San Pedro Garza Garcia,</p>
                {/* codigo-postal */}
                <p>C.P. 66220</p>
                {/* telefono */}
                <p>Tel: (81) 8675-4333</p>
          </div>
          <div className="oficina">
                <h1>Aguascalientes</h1>
                {/* direccion */}
                <p>Av. Aguascalientes Oriente 2604,</p>
                {/* colonia */}
                <p>Fracc. Ojocaliente, Aguascalientes Ags.</p>
                {/* codigo-postal */}
                <p>C.P. 20196</p>
                {/* telefono */}
                <p>Tel: (81) (449) 624 4280</p>
          </div>
      </div>
    );
  }

}

export default Oficinas;