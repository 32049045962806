import React from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, data, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem("token");
  let userIdentity = localStorage.getItem("userIdentity");
  let verify = 0;
  if(userIdentity){
    verify = JSON.parse(localStorage.getItem("userIdentity")).verify;
  }

  // console.log("this", verify);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? 
          verify === 0 ?
          <Redirect to="/verificar" />
          :
          <Component {...props} {...data}/> 
        : 
          <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;