import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {FaAngleLeft} from 'react-icons/fa'
import axios from 'axios';
import './EdoCuenta.css';
import { bcAlert } from '../../boclAlert';

export default function EdoCuenta({
    contrato_metacid
}) {

    const [periodos, setPeriodos] = useState([]);

    useEffect(() => { 
        let cleanUp = true;

        const getEdoCuenta = () => {
            if(contrato_metacid !== 0 && contrato_metacid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_metacid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/estado_cuenta",{"json":json}, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(cleanUp){
                        console.log(response);
                        let status = response.data.status;
                        let data = response.data.result;
        
                        //return(console.log(data.datos_cliente))
                        
                        if(status === "success" ){
        
                            let r_periodos = data.estado_cuenta;
                            let newData = {...periodos};
                            newData = r_periodos;
                            setPeriodos(newData);
                            // console.log(r_periodos)
        
                        }else{
                            bcAlert({
                                msg : {
                                    "title":"Oops!", 
                                    "desc":"No se pudo encontró información"
                                },
                                type :"error", 
                                styles : ``, 
                                options : false
                            });
                            let newData = {...periodos}; 
                            newData = [{status: 0, descripcion: 'La información no está disponible'}];
                            console.log(newData);
                            setPeriodos(newData);
                        }
                    }
                })
                .catch(function (error) {
    
                    bcAlert({
                        msg : {
                            "title":"Oops!", 
                            "desc":"No se pudo consultar la información"
                        },
                        type :"error", 
                        styles : ``, 
                        options : false
                    });

                    let newData = {...periodos};
                    newData = [{status: 0, descripcion: 'No se pudo consultar la información'}];
                    setPeriodos(newData);
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }

        getEdoCuenta(contrato_metacid);

        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_metacid]);

    // console.log(contrato_metacid);

    return (
        <React.Fragment>
            <div className={'option-section'} style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                <div className="option-container">
                    <div className="option-title">
                        <Link to="/portal" className="opt-goBack" >
                            <FaAngleLeft className="go-back-icon"/>
                        </Link>
                        <h1>Estado de cuenta</h1>
                    </div>
                    <div className="option-content">
                        <div className="estadoC__simbologia">
                            <div className="semaforo">
                                <div className="sem-type">
                                    <div className="sem-circle-cont">
                                        <div className="sem-circle sem-yellow"></div>
                                    </div>
                                    <p>Por vencer</p>
                                </div>
                                <div className="sem-type">
                                    <div className="sem-circle-cont">
                                        <div className="sem-circle sem-red"></div>
                                    </div>
                                    <p>Vencido</p>
                                </div>
                                <div className="sem-type">
                                    <div className="sem-circle-cont">
                                        <div className="sem-circle sem-green"></div>
                                    </div>
                                    <p>Pagado</p>
                                </div>
                            </div>
                            <div className="low-message">
                                <p>*Actualizado al día bancario anterior</p>
                            </div>
                        </div>
                        <div className="estadoC__table">
                            <table>
                                <thead id="thead_estado_cuenta">
                                    <tr id="thead_tr_estado_cuenta" className="sh-blue">
                                        <th>Vencimiento Pago</th>
                                        <th>Periodo</th>
                                        <th>Cargo</th>
                                        <th>Abono</th>
                                        <th>Saldo</th>
                                    </tr>
                                </thead>
                                <tbody id="tbody_estado_cuenta">
                                    {
                                        periodos.map((ped, i) => {     
                                                // Return the element. Also pass key     
                                                return (
                                                    ped.status === 2 ? 
                                                    <tr className="green-col" key={i}>
                                                        <td>{ped.fecha}</td>
                                                        <td>{ped.descripcion}</td>
                                                        <td>{ped.cargo}</td>
                                                        <td>{ped.abono}</td>
                                                        <td>{ped.saldo}</td>
                                                    </tr>
                                                    :
                                                    ped.status === 3 ? 
                                                        <tr className="red-col" key={i}>
                                                            <td>{ped.fecha}</td>
                                                            <td>{ped.descripcion}</td>
                                                            <td>{ped.cargo}</td>
                                                            <td>{ped.abono}</td>
                                                            <td>{ped.saldo}</td>
                                                        </tr>
                                                        :
                                                        ped.status === 1 ? 
                                                            <tr className="yellow-col" key={i}>
                                                                <td>{ped.fecha}</td>
                                                                <td>{ped.descripcion}</td>
                                                                <td>{ped.cargo}</td>
                                                                <td>{ped.abono}</td>
                                                                <td>{ped.saldo}</td>
                                                            </tr>
                                                            :
                                                            <tr key={i}>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{ped.descripcion}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                    // <li key={i}>{answer}</li>
                                                ) 
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='fideicomiso'>
                <h1 className='mensaje_fideicomiso'>{localStorage.getItem("msjFideicomiso")}</h1>
            </div>
        </React.Fragment>
    )
}

