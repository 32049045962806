import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {FaAngleLeft} from 'react-icons/fa'
import axios from 'axios';
import {bcAlert} from '../../boclAlert';
import Mail from './Mail/Mail';
import Telefono from './Telefono/Telefono';
import './Contacto.css';
import ReactGA from 'react-ga';

export default function Contacto({
    contrato_metacid,
    contrato_numero
}) {

    /*--Declaration of INPUTS VARIABLES
    ---------correo (string)
    ---------password (string)
    ---------user (object)
    -------Brandon Cantu
    ------------*/
    const [newMensaje, setNewMensaje] = useState({
        telefono: "",
        email:"",
        asunto:"",
        dpto: "",
        mensaje:""
    });

    const [unidades, setUnidades] = useState([]);

    const {telefono="", email="", asunto="", dpto="", mensaje=""} = newMensaje;

    //GET UNIDADES DE SERVICIO
    useEffect(() => {
        let cleanUp = true;

        const getUnidadesServicio = () => {
            if(contrato_metacid !== 0 && contrato_metacid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_meta_cid":contrato_metacid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/lista_unidades_servicios",{"json":json}, {
                    headers: {
                    'Authorization' : token
                    }
                })
                .then(response => {
                    if(cleanUp){
                        // console.log(response);return;
                        let status = response.data.status;
                        let data = response.data;
        
                        // return(console.log(data))
                        
                        if(status === "success" ){
        
                            let unidades_servicio = data.unidades_servicio;
        
                            if(unidades_servicio.length > 0){
        
                                let newData = unidades;
                                newData = unidades_servicio;
                                setUnidades(newData);
                            }else{
                                bcAlert({
                                    msg : {
                                        "title":"Opps!", 
                                        "desc":"No se encontraron datos"
                                    },
                                    type :"error", 
                                    styles : ``, 
                                    options : false
                                });
                            }
                        }else{
                            bcAlert({
                                msg : {
                                    "title":"Opps!", 
                                    "desc":"No hay datos disponibles"
                                },
                                type :"error", 
                                styles : ``, 
                                options : false
                            });
                        }
                    }
                })
                .catch(function (error) {
    
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }

        getUnidadesServicio(contrato_metacid);

        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_metacid]);

    const [cliente, setCliente] = useState("");
    const [auto, setAuto] = useState("");
    const [referencia, setReferencia] = useState("");
    const [clave, setClave] = useState({
        numero: "",
        fecha: "Sin Fecha",
        dias_vigentes: "0",
    });
    const [ultimoDep, setUltimoDep] = useState({
        fecha: "",
        monto: "",
    });

    //GET ULTIMO DEPOSITO
    useEffect(() => { 
        let cleanUp = true;

        const getUltimoDeposito = () => {
            if(contrato_metacid!== 0 && contrato_metacid!== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify(
                    {
                        "contrato_meta_cid":contrato_metacid,
                    }
                );
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/ultimo_deposito_contrato",{"json":json}, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(cleanUp){
                        let status = response.data.status;
                        let result = response.data;
        
                        if (status === 'success') {
                            let datos = result.cuentas_bancarias;
                            //return(console.log(datos.monto));
                            let newData = {...ultimoDep};
                            newData = {monto: datos.monto, fecha: datos.fecha};
                            setUltimoDep(newData);
                            //console.log(ultimoDep);
                        }else{
                            // setClaveStatus("NoHay");
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }
        }
        getUltimoDeposito();

        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_metacid]);
  
    //GET RESUMEN
    useEffect(() => { 
        let cleanUp = true;

        const getResumen = () => {
            if(contrato_metacid !== 0 && contrato_metacid !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify({"contrato_metacid":contrato_metacid});
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/datos_cliente",{"json":json}, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(cleanUp){
                        let status = response.data.status;
                        let data = response.data.data;
        
                        // return(console.log(data.datos_cliente))
        
                        if(status === "success" ){
                            let data_array = data.datos_cliente[0];
        
                            //Se asigna no,bre del cliente
                            let nombre_cliente = data_array.Nombre_Cliente ? data_array.Nombre_Cliente : "Nombre Desconocido";
                            let auto = data_array.Marca_Auto ? data_array.Marca_Auto+" " : "Sin ";
                                auto = auto + (data_array.Nombre_Auto ? data_array.Nombre_Auto+" " : "Auto ");
                                auto = auto + (data_array.Modelo ? data_array.Modelo+" " : " ");
                                auto = auto + (data_array.Color ? data_array.Color+" " : " ");
                                auto = auto + (data_array.Placas ? data_array.Placas+" " : " ");
                            setCliente(nombre_cliente);
                            setAuto(auto);
                            setReferencia(data_array.referencia_corta.substr(data_array.referencia_corta.length-6, 6));
                        }else{
                            let ref = "Desconocida"
                            let nombre_cliente = "Nombre Desconocido";
                            let auto = "Sin Auto " ;
                            setCliente(nombre_cliente);
                            setAuto(auto);
                            setReferencia(ref);
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }else{
                return false;
            }
        }

        getResumen();

        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [contrato_metacid]);

    //GET CLAVE DATAMOVIL
    useEffect(() => { 
        let cleanUp = true;

        const getClaveDeposito = () => {
            if(contrato_numero !== 0 && contrato_numero !== "0"){
                const token = localStorage.getItem('token');
                let json = JSON.stringify(
                    {
                        "numero_contrato":contrato_numero,
                        "plataforma": "web"
                    }
                );
                axios.post(process.env.REACT_APP_API_DM_URL+"/api/clave",{"json":json}, {
                    headers: {
                    'Authorization' : token,
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(cleanUp){
                        //return(console.log(response));
                        let status = response.data.status;
                        let result = response.data;
        
                        if (status === 'success') {
                            let clave_disponible = result.clave_disponible[0];
                            var months = ['Ene','Feb','Mar','Abr','Mayo','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];
                            //clave_disponible.clave_numero = "YES";
                            if(clave_disponible){
                                if (clave_disponible.clave_numero === 'Vencida') {
                                    //Traemos Fecha
                                    let data_unixtime = new Date(clave_disponible.fecha_expiracion * 1000);
                                    let hours = data_unixtime.getHours().toString().padStart(2, '0');
                                    let minutes = data_unixtime.getMinutes().toString().padStart(2, '0');
                                    let data_fecha = data_unixtime.getDate()+" de "+months[data_unixtime.getMonth()]+" "+hours+":"+minutes;
        
                                    let dias_disp = clave_disponible.Dias_Disponibles_Clave;
                                    if(dias_disp<=0){
                                        dias_disp="0";
                                    }else{
                                        dias_disp.toString();
                                    }
        
                                    setClave({numero: "", fecha: data_fecha, dias_vigentes: dias_disp});
                                }else{
                                    let clave_var = clave_disponible.clave_numero;
        
                                    //Traemos Fecha
                                    let data_unixtime = new Date(clave_disponible.fecha_expiracion * 1000);
                                    let hours = data_unixtime.getHours().toString().padStart(2, '0');
                                    let minutes = data_unixtime.getMinutes().toString().padStart(2, '0');
                                    let data_fecha = data_unixtime.getDate()+" de "+months[data_unixtime.getMonth()]+" "+hours+":"+minutes;
        
                                    let dias_disp = clave_disponible.Dias_Disponibles_Clave;
                                    if(dias_disp<=0){
                                        dias_disp="0";
                                    }else{
                                        dias_disp.toString();
                                    }
                                    setClave({numero: clave_var, fecha: data_fecha, dias_vigentes: dias_disp});
        
                                }
                            }else{
                                setClave({numero: "", fecha: "", dias_vigentes: ""});
                            }
                        }else if(status === 'error_app'){
                            setClave({numero: "", fecha: "No hay fecha", dias_vigentes: "Sin días"});
                        }else{
                            setClave({numero: "", fecha: "No hay fecha", dias_vigentes: "Sin días"});
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                    // Request made and server responded
                    console.log("Error-Response: "+error.response.data);
                    console.log("Error-Status: "+error.response.status);
                    console.log("Error-Headers: "+error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error-Request: '+error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error-Message: '+ error.message);
                    }
                });
            }else{
                return false;
            }
        }

        getClaveDeposito();

        return () => {
            // cancel the subscription
            cleanUp = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contrato_numero]);


    const onInputChange = e => {
        setNewMensaje({ ...newMensaje, [e.target.name]: e.target.value });
    };

    const sendMensaje = () =>{
        if(telefono === '' || telefono.length<10){
            //alert('Debes ingresar un teléfono')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"Debes ingresar un teléfono de 10 dígitos"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(email === '' || email.length<=0){
            // alert('Debes ingresar un email')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"Debes ingresar un email"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(asunto === '0' || asunto === '' || asunto.length<=0){
            // alert('Debes ingresar un asunto')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"Debes ingresar un asunto"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(dpto === '0' || dpto === '' || dpto.length<=0){
            // alert('Debes ingresar un departamento')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"Debes ingresar un departamento"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }
        else if(mensaje === '' || mensaje.length<=0){
            //alert('Debes ingresar un mensaje')
            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"Debes ingresar un mensaje"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }

        ReactGA.event({
            category: 'Boton Portal',
            action: 'Mensaje Contacto Portal'
        });
        //DESABILITAMOS EL BOTON
        let btn = document.getElementById("enviarCorreoContacto");
        btn.disabled = true;
        btn.classList.add("btn-blue-disabled")
        //SET LOADER
        //disapper content
        let ogInner = btn.innerHTML;
        let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
        btn.innerHTML = loaderHtml;


        let json = JSON.stringify({"telefono":telefono, "email":email, "select_Asunto":asunto, "select_Departamento":dpto, "correo_coment":mensaje, 
        "contrato_metacid":contrato_metacid, "clave":clave.numero, "nombre_cliente":cliente, "nombre_auto":auto, "marca_auto":"", "modelo_auto":"", 
        "placas_auto":"", "color_auto":"", "referencia_corta":referencia, "ultimo_deposito_monto":ultimoDep.monto, "ultimo_deposito_fecha":ultimoDep.fecha});
        console.log(json)
        const token = localStorage.getItem('token');
        axios.post(process.env.REACT_APP_API_DM_URL+"/api/enviar_correo",{"json":json}, {
            headers: {
            'Authorization' : token,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
            }
        })
        .then(response => {
            let result = response.data;
            // console.log(result)
            if (result.status === 'success') {
                bcAlert({
                    msg : {
                        "title":"Mensaje enviado!", 
                        "desc":"Uno de nuestros operadores se comunicará contigo"
                    },
                    type :"success", 
                    styles : ``, 
                    options : false
                });
                document.getElementById("telefono").value = "";
                document.getElementById("email").value = "";
                document.getElementById("mensaje").value = "";
                document.getElementById("selectAsunto").value = "0";
                document.getElementById("selectDpto").value = "0";

                setNewMensaje({telefono: "",email:"",asunto:"",dpto: "",mensaje:"" });

                btn.disabled = false;
                btn.classList.remove("btn-blue-disabled")
                btn.innerHTML = ogInner;
        
                }else if(result.status === 'error_sin_correos'){
                    bcAlert({
                        msg : {
                            "title":"Opps!", 
                            "desc":"No hay correos de sucursales en tu region"
                        },
                        type :"error", 
                        styles : ``, 
                        options : false
                    });
                }else{
                    bcAlert({
                        msg : {
                            "title":"Opps!", 
                            "desc":"Hubo un problema al enviar el correo"
                        },
                        type :"error", 
                        styles : ``, 
                        options : false
                    });
                }
        })
        .catch(function (error) {

            bcAlert({
                msg : {
                    "title":"Opps!", 
                    "desc":"Hubo un problema al consultar la información"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            btn.innerHTML = ogInner;

            if (error.response) {
            // Request made and server responded
            console.log("Error-Response: "+error.response.data);
            console.log("Error-Status: "+error.response.status);
            console.log("Error-Headers: "+error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            console.log('Error-Request: '+error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error-Message: '+ error.message);
            }
        });
    }

    return (
        <React.Fragment>
            <div className={'option-section'} style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                <div className="option-container">
                    <div className="option-title">
                        <Link to="/portal" className="opt-goBack" >
                            <FaAngleLeft className="go-back-icon"/>
                        </Link>
                        <h1>Contáctanos</h1>
                    </div>
                    <div className="option-content contact-content">
                        <Mail
                            unidades_servicio={unidades}
                            onInputChange={onInputChange}
                            sendMensaje={sendMensaje}
                        />
                        <Telefono
                            unidades_servicio={unidades}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

