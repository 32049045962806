import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import {IconContext} from 'react-icons/lib'
import {AiFillPlusCircle} from 'react-icons/ai'
// import {MdLock} from 'react-icons/md'
import {RiSettings3Fill} from 'react-icons/ri'
import {ImExit} from 'react-icons/im'
import {RiAncientGateFill} from 'react-icons/ri'
import {RiHandCoinFill} from 'react-icons/ri'
import {RiArrowDownSLine} from 'react-icons/ri'
import {RiArrowUpSLine} from 'react-icons/ri'
import {RiShieldKeyholeFill} from 'react-icons/ri'
// import ResumenContrato from './ResumenContrato/ResumenContrato';
import {VscTriangleLeft, VscTriangleRight} from 'react-icons/vsc'
import SelectContrato from './SelectContrato/SelectContrato';
import BeneficiosSeguro from './Opciones/LandingSeguro/Beneficios-Seguro'
import { useHistory } from "react-router-dom";
import './Sidebar.css';

const Sidebar=(props)=>{
    const [click, setClick] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [subItems, setSubItems] = useState(false)

    // console.log(props.contrato_actual);
    /*FUNCIONES__________________
    _____________________________
    ___________________________*/
    let history = useHistory(); 

    //Sidebar show/hide handler
    const handleClick = (e) => {
        setClick(!click);
        // console.log(document.querySelector('.sidebar__'))
        if(document.querySelector('.sidebar__')){
            if(click){
                document.querySelector('.sidebar__').classList.add('sb_inactive');
                document.querySelector('.sidebar__').classList.remove('sb_active');
                document.querySelector('.portalCont__').classList.remove('pc_div');
                document.querySelector('.portalCont__').classList.add('pc_full');
            }else{
                document.querySelector('.sidebar__').classList.remove('sb_inactive')
                document.querySelector('.sidebar__').classList.add('sb_active');
                document.querySelector('.portalCont__').classList.add('pc_div');
                document.querySelector('.portalCont__').classList.remove('pc_full');
            }
        }
    }

    useEffect(() => {
        window.addEventListener('resize', function() {
            let width = window.innerWidth;
                if(width<=610){
                    setIsMobile(true)
                }else{
                    setIsMobile(false)
                }
                // console.log(width);
        }, true);

        if(window.innerWidth<=610){
            setIsMobile(true)
            setTimeout(function(){ 
                handleClick();
            }, 3000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            // cancel the subscription
            setClick(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeContrato = props.changeContrato;

    const asyncLocalStorage = {
        removeItem: function (key, value) {
            return Promise.resolve().then(function () {
                localStorage.removeItem(key, value);
            });
        },
        getItem: function (key) {
            return Promise.resolve().then(function () {
                return localStorage.getItem(key);
            });
        }
    };
    
    const logout = () => 
    {
        localStorage.removeItem("userIdentity")
        localStorage.removeItem("token")
        // history.push("/");
        // location.href = process.env.REACT_APP_BASE_URL;
        asyncLocalStorage.removeItem('token', "").then(function () {
            return asyncLocalStorage.getItem('token');
        }).then(function (value) {
            // console.log('Value has been set to:', value);
            history.push("/");

        });
    }

    const paintSelectedOption = (option) => {
        let optionDiv = document.getElementById(option);
        // console.log(option)

        //DESPINTAMOS OPCION ANTES SELECCIONADA
        let optionsSelected = document.getElementsByClassName("optionSelected")
        if(optionsSelected.length>0){
            for(let x= 0; x<=optionsSelected.length-1; x++){
                optionsSelected[x].classList.remove("optionSelected")
            }
        }
        //DESPINTAMOS EL CONTRATO SELECCIONADO
        let contratoSelected = document.getElementsByClassName("selectcard_Contrato_selected")
        if(contratoSelected.length>0){
            for(let x= 0; x<=contratoSelected.length-1; x++){
                contratoSelected[x].classList.remove("selectcard_Contrato_selected")
            }
        }
        let contratoSelectedBall = document.getElementsByClassName("activeBall")
        if(contratoSelectedBall.length>0){
            for(let x= 0; x<=contratoSelectedBall.length-1; x++){
                contratoSelectedBall[x].classList.remove("activeCard")
            }
        }

        if(optionDiv){
            optionDiv.classList.add("optionSelected")
        }
    }


    const mostrarSubItems = () => {
        if(!subItems) {
             setSubItems(true)
        } else {
            setSubItems(false)
        }         
    }

    return (
        <React.Fragment>
        <IconContext.Provider value={{ color: '#fff'}} >
            <div className="sidebar__">
                <div className="sidebar__container">
                    {/* <div className="sidebar__menu-icon" onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </div> */}
                    <SelectContrato 
                        listaCont={props.contratos} 
                        contrato_actual={props.contrato_actual}
                        changeContratoActual={changeContrato}
                        key={props.contrato_actual.numero}
                        emptyMessage={props.emptyMessage}
                    />
                    {/* <ResumenContrato 
                        contrato_actual={props.contrato_actual}
                        key={props.contrato_actual}
                        /> */}
                    <div className="sidebar__separator"></div>
                    <ul className={click ? 'sidebar__nav-menu active' : 'sidebar__nav-menu'} onClick={isMobile ? handleClick : void(0)}>
                        <li id="optionAgregar" className="sidebar__nav-item">
                            <Link to="/portal/contratos" onClick={() => paintSelectedOption("optionAgregar")}>
                                <AiFillPlusCircle className="sidebar__nav-icons"/>
                                <div className="sidebar__nav-links">
                                    Agregar contrato
                                </div>
                            </Link>
                        </li>
                        {/* <li id="optionPassword" className="sidebar__nav-item" onClick={isMobile ? handleClick : void(0)}>
                            <Link to="/portal/cuenta" onClick={() => paintSelectedOption("optionPassword")}>
                                <MdLock className="sidebar__nav-icons"/>
                                <div className="sidebar__nav-links">
                                    Cambiar Contraseña
                                </div>
                            </Link>
                        </li> */}
                        <li id="optionCuenta" className="sidebar__nav-item" onClick={isMobile ? handleClick : void(0)}>
                            <Link to="/portal/cuenta" onClick={() => paintSelectedOption("optionCuenta")}>
                                <RiSettings3Fill className="sidebar__nav-icons"/>
                                <div className="sidebar__nav-links">
                                   Mi cuenta
                                </div>
                            </Link>
                        </li>

                        {props.contratos.length > 0 ? (
                        <>
                            <li id="veneficios" className="sidebar__nav-item" onClick={isMobile ? handleClick : void(0)}>
                                <Link to="#" onClick={mostrarSubItems}>
                                    <RiHandCoinFill className="sidebar__nav-icons"/>
                                    <div className="sidebar__nav-links">
                                        Beneficios {subItems ? <RiArrowDownSLine className="sidebar__sub-icon"/> : <RiArrowUpSLine className="sidebar__sub-icon"/>}
                                    </div>
                                </Link>
                            </li>
                            {subItems ? (
                                <li id="veneficios" className="sidebar__nav-sub-item" onClick={isMobile ? handleClick : void(0)}>
                                    <Link to="/portal/benseguro"> 
                                        <RiShieldKeyholeFill className="sidebar__nav-icons"/>
                                        <div className="sidebar__nav-links">
                                            Seguros
                                        </div>
                                    </Link>
                                </li>
                            ) : null}</>
                        ) : null}
                        <li className="sidebar__nav-item">
                            <Link to="/" >
                                <ImExit className="sidebar__nav-icons"/>
                                <div className="sidebar__nav-links" onClick={logout}>
                                    Cerrar sesión
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="resumenC__tagSlide" onClick={handleClick}>
                        {click ? <VscTriangleLeft className="resumenC__tagSlide-tri"/> :<VscTriangleRight className="resumenC__tagSlide-tri"/>}
                </div>
            </div>
            </IconContext.Provider>
        </React.Fragment>
    )
}

export default Sidebar